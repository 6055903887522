/*
 * @Author: 邱狮杰
 * @Date: 2021-11-23 10:07:19
 * @LastEditTime: 2022-11-08 11:22:40
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/components/bigPcture/tag.tsx
 */
import { PictureOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { CSSProperties, FC } from 'react'
import './index.scss'
interface Props {
  text: string
  style?: CSSProperties
}

export const TagLength: FC<Props> = ({ text, style }) => {
  return (
    <Tag style={{ ...style, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='tagLength' icon={<PictureOutlined style={{ fontSize: 18 }} />}>
      {text}
    </Tag>
  )
}
