/*
 * @Author: 邱狮杰
 * @Date: 2022-09-27 11:57:41
 * @LastEditTime: 2022-10-21 17:38:39
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/customer/create.ts
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { provide } from 'inversify-binding-decorators';
import { customerConstant } from './customer';
let CustomerCreate = class CustomerCreate {
    init() {
        console.log('init');
    }
};
CustomerCreate = __decorate([
    provide(customerConstant.createCreate)
], CustomerCreate);
export { CustomerCreate };
