/*
 * @Author: 邱狮杰
 * @Date: 2022-11-04 11:09:40
 * @LastEditTime: 2022-11-07 09:32:43
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/hoc/yarnTestingOperationButtonHoc.tsx
 */

import { yarnTestingOperationButton, yarnTestingOperationButtonStateTypes } from '@reducers/modules/yarnTestingOperationButton'
import { bindActionCreators } from '@zealforchange/conciseredux'
import { createReducerProcessingHoc } from '@zealforchange/redux-encapsulation'

import { ComponentType, ReactElement } from 'react'
import { Action, Dispatch } from 'redux'

export interface withYarnTestingOperationButtonStateTypesInjectProps {
    curEle?: ReactElement
    withYarnTestingOperationButtonStateDispatch?: ReturnType<typeof yarnTestingOperationButton.getCallBackAll>
}

function mapStateToProps(state: { yarnTestingOperationButton: yarnTestingOperationButtonStateTypes }) {
    return {
        curEle: state.yarnTestingOperationButton.curEle,
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>) {
    return {
        withYarnTestingOperationButtonStateDispatch: bindActionCreators(yarnTestingOperationButton.getCallBackAll(), dispatch)
    }
}

export const withYarnTestingOperationButtonHoc = <T extends withYarnTestingOperationButtonStateTypesInjectProps>(C: ComponentType<T>) => {
    return createReducerProcessingHoc('withYarnTestingOperationButtonHoc', C, mapStateToProps, mapDispatchToProps)
}
