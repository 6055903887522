/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 15:22:16
 * @LastEditTime: 2022-11-12 16:32:04
 * @FilePath: /preselectedweddingdress/src/layout/header/components/switchCustomers/menuItemHooks.tsx
 * @Description  : menuItem
 */
import { PlusOutlined } from '@ant-design/icons'
import { mergeFnWithPromiseFn } from '@mxnet/types/dts'
import { userListResponse } from '@type/api/module/common'
import { useClickAway } from 'ahooks'
import { Menu } from 'antd'
import { initialState } from 'high-order-react'
import { Component, FC, useEffect, useRef } from 'react'
import './index.scss'

// import { headerCustomer } from '@constants/headerCustomer'

export interface menuItemProps {
  itemList: userListResponse[]
  onProxyClick: (item: userListResponse, index: number) => void
  onProxyAddClick: () => void
}

export interface menuItemState { }

@initialState<menuItemState>({})
class Menus extends Component<menuItemProps, menuItemState> {
  render() {
    const { itemList, onProxyClick, onProxyAddClick } = this.props
    return (
      <Menu onMouseOver={() => {
        console.log('what?')
      }} theme='dark' style={{ padding: '0' }}>
        {itemList.map((item, itemIndex) => {
          return (
            <Menu.Item onClick={onProxyClick.bind(this, item, itemIndex)} key={itemIndex} className='menuItem'>
              <div
                key={itemIndex}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <span>{item && item.clientName}</span>
                <span>{item && item.clientPhone}</span>
              </div>
            </Menu.Item>
          )
        })}
        <div key={'as'} className='add' onClick={onProxyAddClick.bind(this)}>
          <>
            <PlusOutlined />
            新增
          </>
        </div>
      </Menu>
    )
  }
}


export interface MenusWrapperProps {
  itemList: userListResponse[]
  onProxyClick: (item: userListResponse, index: number) => void
  onProxyAddClick: () => void
  onClose?: mergeFnWithPromiseFn
}

const MenusWrapper: FC<MenusWrapperProps> = ({ onClose, onProxyAddClick, onProxyClick, itemList }) => {
  const menuList = useRef(null)

  // useEffect(() => {
  //   console.log(menuList.current, 'menuList', 'ahwas')
  // }, [])

  return <>

    <Menu ref={menuList} onMouseLeave={() => {
      onClose?.()
    }} theme='dark' style={{ padding: '0' }}>
      {itemList.map((item, itemIndex) => {
        return (
          <Menu.Item onClick={onProxyClick.bind(this, item, itemIndex)} key={itemIndex} className='menuItem'>
            <div
              key={itemIndex}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <span>{item && item.clientName}</span>
              <span>{item && item.clientPhone}</span>
            </div>
          </Menu.Item>
        )
      })}
      <div key={'as'} className='add' onClick={onProxyAddClick.bind(this)}>
        <>
          <PlusOutlined />
          新增
        </>
      </div>
    </Menu>
  </>
}


export default MenusWrapper
