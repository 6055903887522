/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 17:18:17
 * @LastEditTime : 2021-08-13 11:46:04
 * @FilePath     : /preselectedyarn/src/actions/headerButton.ts
 * @Description  :
 */
import { ReactElement } from 'react'
import { Action } from 'redux'
import { defaultAction } from '@utils/action'
import { HEADER_RIGHT_BUTTON_OPERATION_EDIT, HEADER_RIGHT_BUTTON_OPERATION_HIDDEN, REPLACE_COMPONENT_STATUS, CURRENT_DISPLAY, HeaderAction } from '@constants/headerButton'

export function editHeaderComponent(cpm: ReactElement): Action & HeaderAction {
  return defaultAction(HEADER_RIGHT_BUTTON_OPERATION_EDIT, { component: cpm })
}
export function hiddenHeaderComponent(cpm: ReactElement): Action & HeaderAction {
  return defaultAction<HeaderAction>(HEADER_RIGHT_BUTTON_OPERATION_HIDDEN, { component: cpm }) as Action & HeaderAction
}

export function replaceComponentStatus(params: HeaderAction) {
  return defaultAction<HeaderAction>(REPLACE_COMPONENT_STATUS, params)
}

export function currentDisplay(key: HeaderAction['curShowComponent']) {
  return defaultAction(CURRENT_DISPLAY, { curShowComponent: key })
}
