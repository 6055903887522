import { useRef, MutableRefObject } from 'react'
export type tagsType = { parentId: number, id: number }
// tags
export function useIdDeduplication(id?: tagsType[]): [MutableRefObject<tagsType[]>, (id: tagsType) => void] {
  const idList = useRef<tagsType[]>(id || [])

  function addId(id: tagsType) {
    const newList = idList.current
    // 已存在的id就删除
    const cur = newList.findIndex(item => { return item.id === id.id && item.parentId === id.parentId })
    if (cur !== -1) {
      newList.splice(cur, 1)
      idList.current = [...newList]
      return
    }
    idList.current = [...newList, id]
  }

  return [idList, addId]
}


// gradeIds
export function useIdDeduplicationWithGradeIds(id?: number[]): [MutableRefObject<number[]>, (id: number) => void] {
  const idList = useRef<number[]>(id || [])

  function addId(id: number) {
    const newList = new Set(idList.current)
    // 已存在的id就删除
    if (newList.has(id)) {
      newList.delete(id)
      idList.current = [...newList]
      return
    }
    newList.add(id)
    idList.current = [...newList]
  }

  return [idList, addId]
}



