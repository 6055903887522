/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 11:17:35
 * @LastEditTime : 2021-08-13 15:54:16
 * @FilePath     : /preselectedyarn/src/config/interceptors/axios.ts
 * @Description  :
 */

import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import { CONSOLE_REQUEST_ENABLE, CONSOLE_RESPONSE_ENABLE } from '..'

export function requestSuccessFunc(requestObj: AxiosRequestConfig) {
  CONSOLE_REQUEST_ENABLE && console.info('requestInterceptorFunc', `url: ${requestObj.url}`, requestObj)
  // 自定义请求拦截逻辑，可以处理权限，请求发送监控等
  return requestObj
}

export function requestFailFunc(requestError: unknown) {
  // 自定义发送请求失败逻辑，断网，请求发送监控等
  return Promise.reject(requestError)
}

export async function responseSuccessFunc(responseObj: AxiosResponse) {
  CONSOLE_RESPONSE_ENABLE && console.info('responseInterceptorFunc', `url: ${responseObj.request.responseURL}`, responseObj)
  // 自定义响应成功逻辑，全局拦截接口，根据不同业务做不同处理，响应成功监控等
  const resData = responseObj.data
  const { code } = resData

  switch (code) {
    case undefined:
      return resData
    // 如果业务成功，直接进成功回调
    case 0:
      return resData.data
    case -2:
      // token异常
      // Taro.navigateTo({ url: loginUrl })
      // wxLogin()
      return resData.data || resData
    case -3:
      return null
    case -1:
      // 接口异常
      return null
    case 401:
      process.env.NODE_ENV === 'development' ? (window.location.href = 'http://localhost:3000/#/login') : (window.location.href = 'https://pre.bridal.work/#/login')
      // process.env.NODE_ENV === 'development' ? (window.location.href = 'http://192.168.199.25:3000/#/login') : (window.location.href = 'https://pre.bridal.work/#/login')
      return null
    case 403:
      // token 过期
      return null
    default:
      // 业务中还会有一些特殊 code 逻辑，我们可以在这里做统一处理，也可以下方它们到业务层
      // !responseObj.config.noShowDefaultError &&
      // 	Taro.showToast({
      // 		title: resData.message,
      // 		icon: 'none',
      // 		duration: 2000,
      // 	})
      // global.vbus.$emit('business.response.incorrect', resData.message)
      // return Promise.reject(resData)
      return Promise.resolve(responseObj.data)
  }
}

const errorMsgMap = {
  429: {
    msg: '请求太频繁，请休息一会',
  },
  422: {
    msg: '参数错误，请检查参数',
  },
  404: {
    msg: '您访问的接口不存在',
  },
  403: {
    msg: '没有此操作的执行权限',
  },
  401: {
    msg: '登录过期，请重新登录',
    fn: async () => {
      // Taro.setStorageSync(StoreConfigNameCollect.token, '')
      // Taro.navigateTo({ url: loginUrl })
      // wxLogin()
    },
  },
}

export function responseFailFunc(responseError: any) {
  // 响应失败，可根据 responseError.message 和 responseError.response.status 来做监控处理
  const errorResponse = responseError?.response
  // const { status } = errorResponse
  // @ts-ignore
  const nowError = errorMsgMap[errorResponse?.status] || false
  const msg = nowError ? nowError.msg : '网络繁忙请稍后再试'
  !responseError.config.noShowDefaultError && message.info(msg, 2.5)
  if (nowError && nowError.fn) nowError.fn()
  return Promise.reject(responseError)
}
