/*
 * @Author: 邱狮杰
 * @Date: 2022-11-03 17:53:29
 * @LastEditTime: 2022-11-09 11:57:11
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/hooks/useHeaderGoods.ts
 */

import { makeup } from '@hoc/makeupArtistRelated';
import { store } from '@reducers/index';
import { HeaderGoodsReducer, State } from '@reducers/modules/headerGoods';
import { bindActionCreators } from '@zealforchange/conciseredux';
import { useDispatch, useSelector } from 'react-redux';


export function useHeaderGoods() {

    const dispatchWithHeaderGoods = bindActionCreators(
        HeaderGoodsReducer.getCallBackAll(), useDispatch())

    const curStateWithHeaderGoodsForRedux = () => store.getState().HeaderGoodsReducer

    const curStateWithHeaderGoods = useSelector((state: { HeaderGoodsReducer: State }) => {
        return state.HeaderGoodsReducer
    })

    function getActiveGoodsWithWedding() {
        const curGoods = curStateWithHeaderGoodsForRedux().goodsList.find(n => n.active)
        if (!curGoods?.id) return undefined
        if (curGoods?.id as unknown as string === makeup.classificationOfDressers) return undefined
        return curGoods
    }

    return { dispatchWithHeaderGoods, curStateWithHeaderGoods, curStateWithHeaderGoodsForRedux, getActiveGoodsWithWedding }

}