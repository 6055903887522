import { SynchronizationAwaitError } from 'synchronizationawaiterror'
export function readImage(URL: string): Promise<unknown> {
  return new Promise((resolve, reject) => {
    let result = false
    const image = new Image()
    image.src = URL
    const timeout = setTimeout(() => {
      result = true
      clearTimeout(timeout)
      image.remove()
      reject(URL)
    }, parseInt(process.env.REACT_APP_IMG_TIMEOUT as string))
    image.onload = function (res) {
      clearTimeout(timeout)
      if (result) {
        return
      }
      // err_ssl_version_or_cipher_mismatch
      resolve(res)
    }
    image.onerror = function (res) {
      clearTimeout(timeout)
      if (result) {
        return
      }
      reject(res)
    }
  })
}
/**
 * @description 尝试加载图片
 * @param URL
 * @param failURL
 * @param successCb
 * @param judge 是否不需要判断, 不需要判断则直接返回failURL
 * @param failCb
 * @returns
 */
export async function tryReadIsImage(URL: string, failURL: string, judge?: boolean | null, successCb?: Function, failCb?: Function): Promise<[string, boolean | undefined]> {
  if (judge) {
    return [failURL, true]
  }
  const [err] = await SynchronizationAwaitError(readImage(URL))
  if (err) {
    failCb?.()
    return [failURL, true]
  }
  successCb?.()
  return [URL, false]
}
