/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:03:25
 * @LastEditTime: 2022-11-23 23:19:03
 * @Description:
 * @FilePath: /service/src/config/index.ts
 */
import { Service } from '@mxnet/service';
import { JoinPlugin } from './plugins/joinPlugin';
class DefaultInterceptor {
    requestFailInterceptor(err) {
        return;
    }
    responseFailInterceptor(err) {
        return;
    }
    responseSuccessInterceptor(response) {
        return response.data;
    }
    requestSuccessInterceptor(config) {
    }
}
const http = new Service({
    // baseURL: 'http://192.168.199.123:8083',
    baseURL: 'https://bridal.work/api',
    headers: {},
})
    .injectionInterceptorPlugin([new JoinPlugin()])
    .defaultInterceptor(new DefaultInterceptor())
    // .addAppletAdapter()
    // .addAppletAdapter()
    .getAxios();
export { http };
