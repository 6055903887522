/**
 * @description 所有初始化函数
 */
import { decorators } from 'youwilllike'
import { message } from 'antd'
import { bindThis } from 'high-order-react'
import { ComponentType, PureComponent } from 'react'
import { requestCommonTypes } from '@type/api/common'
import { isFunction } from '@utils/fn'
import api from '@service/index'
import { welcomeInitTypes } from '@type/api/module/welcome'
import { userListResponse } from '@type/api/module/common'
import { formInitResponse, formTreeInitResponse } from '@service/api/modules/common'
import { whetherToBbbreviate } from '@components/superImage/utils'
import { errorCode, executionError } from '@utils/tips'
import type { goodsCategoryRequestParams, goodsCategoryResponse } from '@service/api/modules/classification'
import type {
  goodsStyleRequest,
  goodsStyleResponse,
  preselectionYetResponse,
  imgListRequest,
  imageListResponse,
  LikeAndDeleteYetRequest,
  perselectionYetRequest,
  perselectionYetResponse,
  DeleteIds,
  tryonyetRequest,
  tryonYetResponse,
  LikeListYetRequest
} from '@service/api/modules/preselectedYarnList'
import { SynchronizationAwaitError } from 'synchronizationawaiterror'

export interface injectProps {
  welcomeInitialization?: (successCb?: (val: requestCommonTypes<welcomeInitTypes>) => void, failCb?: () => void) => Promise<requestCommonTypes<welcomeInitTypes> | null | never>
  userListInitialization?: (successCb?: (val: requestCommonTypes<userListResponse[]>) => void, failCb?: () => void) => Promise<requestCommonTypes<userListResponse[]>>
  goodsCategoryInitialization?: (
    params?: goodsCategoryRequestParams,
    successCb?: (val: requestCommonTypes<goodsCategoryResponse>) => void,
    failCb?: () => void
  ) => Promise<requestCommonTypes<goodsCategoryResponse[] | null>>
  goodsInitialization?: (params?: goodsStyleRequest, successCb?: (val: requestCommonTypes<goodsStyleResponse>) => void, failCb?: () => void) => Promise<requestCommonTypes<goodsStyleResponse>>
  preselectionYetInitialization?: (caseId: number, pageInfo?: { pageSize: number; pageNum: number }, successCb?: (val: unknown) => void, failCb?: () => void) => Promise<requestCommonTypes<preselectionYetResponse>>
  tryonYetInitialization?: (successCb?: (val: unknown) => void, failCb?: () => void) => Promise<unknown>
  editYarnList?: (caseId: number, goodsIds: string, successCb?: (res: any) => void, failCb?: () => void) => Promise<requestCommonTypes<any>>
  initializeFormInformation?: (successCb?: (val: requestCommonTypes<formInitResponse>) => void, failCb?: () => void) => Promise<requestCommonTypes<formInitResponse>>
  customerChannel?: (successCb?: (val: requestCommonTypes<formTreeInitResponse>) => void, failCb?: () => void) => Promise<requestCommonTypes<formTreeInitResponse>>
  getImageList?: (params: imgListRequest, success?: () => void, failCb?: () => void) => Promise<requestCommonTypes<imageListResponse>>
  deleteYet?: (params: LikeAndDeleteYetRequest, success?: () => void, failCb?: () => void) => Promise<requestCommonTypes<void>>
  likeYet?: (params: LikeAndDeleteYetRequest, success?: () => void, failCb?: () => void) => Promise<requestCommonTypes<void>>
  getlikeYetList?: (params: LikeListYetRequest, success?: () => void, failCb?: () => void) => any
  deleteList?: (params: DeleteIds, success?: () => void, failCb?: () => void) => Promise<{ code: number; msg: string }>
  selectedTab?: (params: perselectionYetRequest, success?: () => void, failCb?: () => void) => Promise<requestCommonTypes<perselectionYetResponse>>
  tryonYet?: (params: tryonyetRequest, success?: () => void, fail?: () => void) => Promise<requestCommonTypes<tryonYetResponse>>
}

export const withInitialCollection = <T extends injectProps>(C: ComponentType<T>) => {
  class hoc extends PureComponent<T, unknown> {
    static displayName = 'withInitialCollection'
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async getlikeYetList(params: LikeListYetRequest, success?: () => void, fail?: () => void){
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|likeYetList'](params))
      executionError(err, '获取已选列表失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   fail?.()
      //   throw new Error('初始化已试列表失败，请重试')
      // }
      success?.()
      return res 
    }
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async tryonYet(params: tryonyetRequest, success?: () => void, fail?: () => void): Promise<requestCommonTypes<tryonYetResponse>> {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|tryonyet'](params))
      executionError(err, '初始化已试列表失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   fail?.()
      //   throw new Error('初始化已试列表失败，请重试')
      // }
      success?.()
      return res as requestCommonTypes<tryonYetResponse>
    }
    /**
     * @description 已选分页
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async selectedTab(params: perselectionYetRequest, success?: () => void, failCb?: () => void) {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|perselectionYet'](params))
      executionError(err, '加载失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('加载失败，请重试')
      // }
      isFunction(success) && success?.()
      return res
    }
    /**
     * @description 批量删除
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async deleteList(params: DeleteIds, success?: () => void, failCb?: () => void) {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|deleteList'](params))
      executionError(err, '删除操作失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('删除操作失败，请重试')
      // }
      isFunction(success) && success?.()
      return res
    }
    /**
     * @description 删除喜欢款式
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async deleteYet(params: LikeAndDeleteYetRequest, success?: () => void, fail?: () => void) {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|deleteYet'](params))
      executionError(err, '删除操作失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(fail) && fail?.()
      //   throw new Error('删除操作失败，请重试')
      // }
      isFunction(success) && success?.()
      return res
    }
    /**
     * @description 新增喜欢款式
     * @param params
     * @param successCb
     * @param failCb
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async likeYet(params: LikeAndDeleteYetRequest, successCb?: () => void, failCb?: () => void) {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|likeyet'](params))
      executionError(err, '操作失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('操作失败，请重试')
      // }
      isFunction(successCb) && successCb?.()
      return res
    }
    /**
     * @description 初始化用户列表
     * @param { () => void } successCb
     * @param { () => void } failCb
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async userListInitialization(successCb?: (val: requestCommonTypes<userListResponse[]>) => void, failCb?: () => void): Promise<requestCommonTypes<userListResponse[]>> {
      const [err, res] = await SynchronizationAwaitError<requestCommonTypes<userListResponse[]>, requestCommonTypes<userListResponse[]>>(api['common|getUser']())
      executionError(err, '初始化用户列表失败')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化用户列表失败')
      // }
      isFunction(successCb) && res && successCb?.(res)
      return res as requestCommonTypes<userListResponse[]>
    }

    /**
     * @description 初始化首页数据
     * @param { (val: requestCommonTypes<welcomeInitTypes>) => void } successCb 成功回调
     * @param { () => void } failCb 失败回调
     * @returns { Promise<requestCommonTypes<welcomeInitTypes> | null | never> }
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async welcomeInitialization(successCb?: (val: requestCommonTypes<welcomeInitTypes>) => void, failCb?: () => void): Promise<requestCommonTypes<welcomeInitTypes> | null | never> {
      const [err, res] = await SynchronizationAwaitError<requestCommonTypes<welcomeInitTypes>, requestCommonTypes<welcomeInitTypes>>(api['welcome|welcomeInit']())
      executionError(err, '初始化失败请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化失败请重试')
      // }
      console.log({init:res})
      /**
       * @description 验证标图
       */
      if(res&&res.result){
        localStorage.setItem('originalImg',res.result.originalImg)
      }
      whetherToBbbreviate({ src: res?.result.logo as string, localhost: res?.result.localhost, h: 0, w: 0 })
      isFunction(successCb) && res && successCb?.(res)
      return res
    }

    /**
     * @description 获取大图信息
     * @param params
     * @param successCb
     * @param failCb
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async getImageList(params: imgListRequest, successCb?: () => void, failCb?: () => void): Promise<requestCommonTypes<imageListResponse>> {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|imgList'](params || {}))
      executionError(err, '初始化分类失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化分类失败，请重试')
      // }
      isFunction(successCb) && res && successCb?.()
      return res as requestCommonTypes<imageListResponse>
    }

    /**
     * @description 初始化婚纱分类
     * @param successCb
     * @param failCb
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async goodsCategoryInitialization(
      params?: goodsCategoryRequestParams,
      successCb?: (val: requestCommonTypes<goodsCategoryResponse[]>) => void,
      failCb?: () => void
    ): Promise<requestCommonTypes<goodsCategoryResponse[]>> {
      const [err, res] = await SynchronizationAwaitError<requestCommonTypes<goodsCategoryResponse[]>, requestCommonTypes<goodsCategoryResponse[]>>(api['classification|goodsCategory'](params || {}))
      executionError(err, '初始化分类失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化分类失败，请重试')
      // }
      isFunction(successCb) && res && successCb?.(res)
      return res as requestCommonTypes<goodsCategoryResponse[]>
    }

    /**
     * @description 修改预选纱 废弃
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async editYarnList(caseId: number, goodsIds: string, successCb?: (res: any) => void, failCb?: () => void): Promise<requestCommonTypes<any>> {
      const [err, res] = await SynchronizationAwaitError(
        api['preselectedYarnList|preselectionYetPut']({
          caseId,
          goodsIds,
        })
      )
      executionError(err, '修改预选纱失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('修改预选纱失败，请重试')
      // }
      isFunction(successCb) && res && successCb?.(res as requestCommonTypes<goodsStyleResponse>)
      return res as requestCommonTypes<any>
    }

    /**
     * @description 初始化商品款式
     * @param successCb
     * @param failCb
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async goodsInitialization(params?: goodsStyleRequest, successCb?: (val: requestCommonTypes<goodsStyleResponse>) => void, failCb?: () => void): Promise<requestCommonTypes<goodsStyleResponse>> {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|goodsStyle'](params || {}))
      executionError(err, '初始化商品款式出错')
      res && errorCode(res)
      
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化商品款式出错')
      // }
      isFunction(successCb) && res && successCb?.(res as requestCommonTypes<goodsStyleResponse>)
      return res as requestCommonTypes<goodsStyleResponse>
    }

    /**
     * @description 初始化渠道列表
     * @param successCb
     * @param failCb
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async customerChannel(successCb?: (res: requestCommonTypes<formTreeInitResponse>) => void, failCb?: () => void): Promise<requestCommonTypes<formTreeInitResponse>> {
      const [err, res] = await SynchronizationAwaitError(api['common|formTreeInit']())
      executionError(err, '初始化渠道列表失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化渠道列表失败，请重试')
      // }
      isFunction(successCb) && res && successCb?.(res as requestCommonTypes<formTreeInitResponse>)
      return res as requestCommonTypes<formTreeInitResponse>
    }

    /**
     * @description 初始化已选纱
     * @param successCb
     * @param failCb
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async preselectionYetInitialization(
      caseId: number,
      pageInfo?: { pageSize: number; pageNum: number },
      orderBy?: 'desc' | 'asc',
      successCb?: (val: unknown) => void,
      failCb?: () => void
    ): Promise<requestCommonTypes<preselectionYetResponse>> {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|preselectionYet']({ caseId, ...pageInfo, orderBy }))
      executionError(err, '初始化商品款式出错')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化商品款式出错')
      // }
      isFunction(successCb) && res && successCb?.(res)
      return res as requestCommonTypes<preselectionYetResponse>
    }

    /**
     * @description 初始化已试纱
     * @param successCb
     * @param failCb
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async tryonYetInitialization(successCb?: (val: unknown) => void, failCb?: () => void): Promise<unknown> {
      const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|tryonYet']())
      executionError(err, '初始化已试纱出错,请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化已试纱出错,请重试')
      // }
      isFunction(successCb) && res && successCb?.(res)
      return res
    }

    /**
     * @description 初始化表单预选项
     * @returns
     */
    @bindThis
    @decorators.catchErrorPromise<Error>(e => {
      message.warn(e.message)
    })
    async initializeFormInformation(successCb?: (res: requestCommonTypes<formInitResponse>) => void, failCb?: () => void): Promise<requestCommonTypes<formInitResponse>> {
      const [err, res] = await SynchronizationAwaitError(api['common|formInit']())
      executionError(err, '初始化表单预选项失败，请重试')
      res && errorCode(res)
      // if (err) {
      //   isFunction(failCb) && failCb?.()
      //   throw new Error('初始化表单预选项失败，请重试')
      // }
      isFunction(successCb) && res && successCb?.(res as requestCommonTypes<formInitResponse>)
      return res as requestCommonTypes<formInitResponse>
    }

    render() {
      const { ...resetProps } = this.props
      return (
        <C
          tryonYetInitialization={this.tryonYetInitialization}
          preselectionYetInitialization={this.preselectionYetInitialization}
          goodsInitialization={this.goodsInitialization}
          goodsCategoryInitialization={this.goodsCategoryInitialization}
          userListInitialization={this.userListInitialization}
          welcomeInitialization={this.welcomeInitialization}
          editYarnList={this.editYarnList}
          initializeFormInformation={this.initializeFormInformation}
          customerChannel={this.customerChannel}
          getImageList={this.getImageList}
          deleteYet={this.deleteYet}
          likeYet={this.likeYet}
          getlikeYetList={this.getlikeYetList}
          deleteList={this.deleteList}
          selectedTab={this.selectedTab}
          tryonYet={this.tryonYet}
          {...resetProps}
        />
      )
    }
  }

  return hoc
}
