/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useState, useMemo } from 'react'
import { SynchronizationAwaitError } from 'synchronizationawaiterror'
import { requestCommonTypes } from '@type/api/common'
import { message } from 'antd'
import api from '@service/index'
import { search } from 'youwilllike'
import { searchTypes } from '../searchContent'
import { StyleListContext } from '../components/index'
import { byCategoryIdResponse } from '@service/api/modules/preselectedYarnList'
import { Search } from '@reducers/modules/search'
import { useSearchDispatch } from '@hooks/searchDispatch'
import { useGoodsStyleDispatch } from '@hooks/goodsStyleDispatch'
import { tagsType } from '@hooks/useIdDeduplication'
/**
 * @description 返回相关款式标签
 * @returns
 */
export function useQueryStyle(
  searchContext: search.SearchHandler<searchTypes>,
  ListSetAction: Dispatch<SetStateAction<StyleListContext.List>>
): [() => void, StyleListContext.List[], Dispatch<SetStateAction<StyleListContext.List[] | []>>, number, () => void] {
  const [styleList] = useGoodsStyleDispatch()
  // const [tag, setTag] = useState<StyleListContext.List | {}>({})
  const [tagList, setTagList] = useState<[] | StyleListContext.List[]>([])
  const [_, searchDispatch] = useSearchDispatch()
  const activeNum = useMemo(() => {
    let n = 0
    tagList.forEach(item =>
      item.list.forEach(listItem => {
        if (listItem.active === true) n += 1
      })
    )
    return n
  }, [tagList])
  async function queryHandler(): Promise<void> {
    const context = searchContext.returnParams<searchTypes>()
    // ListSetAction(real => {
    //   return { ...real, list: List }
    // })
    const activeItem = styleList.HeaderGoodsReducer.goodsList.filter(listItem => listItem.active)
    if (!activeItem?.[0]?.id) return
    if (!searchContext.hasField('categoryId')) {
      searchContext.addNewField('categoryId', activeItem?.[0]?.id || 0)
    } else {
      // if (context.categoryId === item.id) {
      //   searchContext.deleteField('categoryId')
      //   searchContext.deleteField('tags')
      //   setTagList([])
      //   searchDispatch.setOption(context)
      //   return
      // }
      searchContext.rewriteField('categoryId', activeItem?.[0]?.id)
    }
    searchDispatch.setOption(context)
    const [err, tagList] = await SynchronizationAwaitError<requestCommonTypes<byCategoryIdResponse>, requestCommonTypes<byCategoryIdResponse>>(api['preselectedYarnList|byCategoryId']({ categoryId: activeItem?.[0]?.id }))
    if (err) {
      message.destroy()
      message.warn('查询标签列表失败，请重试')
      setTagList([])
      return
    }
    if (!tagList?.result.length) {
      message.destroy()
      // message.warn('该分类下暂无标签')
      setTagList([])
      searchDispatch.setTagList([])
      return
    }
    const result: StyleListContext.List[] = []
    tagList.result.forEach(item => {
      const tags = (Search.getCurState() as { tags: tagsType[] }).tags
      const resultFindParent: tagsType | undefined = tags.find(findParent => findParent.parentId === item.id);
      // result.push({
      //   id: item.id,
      //   title: item.title,
      //   list: item.tags.map(tagItem => {
      //     return { id: tagItem.id, active: (Search.getCurState() as { tags: number[] }).tags?.includes(tagItem.id) ? true : false, name: tagItem.tagTitle, parentId: item.id }
      //   }),
      // })
      result.push({
        id: item.id,
        title: item.title,
        list: item.tags.map(tagItem => {
          return { id: tagItem.id, active: activeHandler(resultFindParent, tagItem.id, tags), name: tagItem.tagTitle, parentId: item.id }
        }),
      })
    })
    setTagList(result)
    searchDispatch.setTagList(result)
  }

  function activeHandler(resultFindParent: undefined | tagsType, itemId: number, tagsList: tagsType[]) {
    if (!resultFindParent) return false
    const cur = tagsList.find(findItem => {
      return findItem.id === itemId
    })
    if (!cur) return false
    return true
  }

  function resetTag() {
    const result = tagList.map(item => {
      return {
        id: item.id,
        title: item.title,
        list: item.list.map(item => ({ ...item, active: false })),
      }
    })
    setTagList(result)
    searchDispatch.setTagList(result)
  }

  return [queryHandler, tagList, setTagList, activeNum, resetTag]
}
