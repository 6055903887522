import { defaultAction } from '@utils/action'
import { EDIT_LOCALHOSTIMG, EDIT_LOGO, EDIT_LOCALHOST } from '@constants/common'
import { State } from '@reducers/modules/common'
import { welcomeInitTypes } from '@type/api/module/welcome'

export function editLogo(logoURL: string) {
  return defaultAction<State>(EDIT_LOGO, { logo: logoURL })
}

export function editLocalhost(localhost: string | null) {
  return defaultAction<State>(EDIT_LOCALHOST, { localhost })
}
export function editLocalhostImg(localhostImg: boolean | null) {
  return defaultAction<State>(EDIT_LOCALHOSTIMG, { localhostImg })
}
export function editWelcomeInfo(res: welcomeInitTypes) {
  return defaultAction<State>(EDIT_LOCALHOSTIMG, { welcomeinfo: res })
}
