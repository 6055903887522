import { ComponentType } from 'react'
import * as actions from '@actions/common'
import { Action, Dispatch, bindActionCreators } from 'redux'
import { CommonTypes } from '@constants/common'
import { State } from '@reducers/modules/common'
import { createReducerProcessingHoc } from '@zealforchange/redux-encapsulation'

export interface injectProps {
  commonState?: State
  withCommonDispatchActions?: typeof actions
}
function mapStateToProps(state: { Common: State }) {
  return {
    commonState: state.Common,
  }
}
function mapDispatchToProps(dispatch: Dispatch<Action & CommonTypes>) {
  return {
    withCommonDispatchActions: bindActionCreators(actions, dispatch),
  }
}
export const withCommonDispatch = <T extends injectProps>(C: ComponentType<T>) => {
  return createReducerProcessingHoc('withCommonDispatch', C, mapStateToProps, mapDispatchToProps)
}
