/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 11:38:57
 * @LastEditTime: 2021-08-16 15:57:50
 * @FilePath     : /preselectedyarn/src/service/api/modules/common.ts
 * @Description  :
 */

import { ApiUnitConfig } from '@type/axios'
export interface phoneNumberLookupRequest {
  caseId: number
  clientName: string
  clientPhone: string
  clientWechat: string
}
export type phoneNumberLookupResponse = {
  addWechat: null | string
  amount: number
  appointmentCount: number
  budget: number
  callbackId: null | number
  callbackNum: null | number
  callbackStatus: null | number
  callbackType: null | number
  caseAddress: string
  caseDate: string
  caseEdate: null | string
  caseType: number
  channelId: null | number
  channelPersonId: null | number
  channelTitle: null | string
  channelTypeId: null | number
  channelValue: null | string
  clientDtos: { id: null | number; name: string; isMain: number; phone: string; role: null | string; wechat: null | string }[]
  clientIntention: number
  clientName: string
  clientPhone: string
  clientRole: null | string
  clientWechat: null | string
  companyId: number
  createAt: string
  createType: number
  deleted: number
  goodsPackageId: null | number
  hotelId: null | number
  id: number
  lastCallAt: null | string
  nextCallAt: null | number
  orderFormId: null | number
  orderType: null | number
  packagePrice: null | string
  packageTitle: null | string
  perselectionId: null | number
  pledgeStatus: number
  rebatesStatus: null | number
  recomendType: null | number
  remarks: string
  responsibleStaff: number
  shopId: number
  status: number
  tenantId: null | number
  updateAt: string
  updateStaff: number
  visitCount: number
}[]
export interface formInitResponse {
  companyStaffs: {
    code: string
    companyId: number
    createAt: string
    deleted: number
    enable: number
    id: number
    identityCard: null | number
    name: string
    openId: string
    phone: string
    portraitUrl: null | string
    shopId: number
    status: number
    tenantId: null | number
    updateAt: string
    updateUser: number
    userId: number
    wechat: string
  }[]
  goodsCategories: {
    companyId: number
    createAt: string
    deleted: number
    displayOrder: number
    icon: string
    id: number
    pid: number
    route: null | string
    shopId: number
    tenantId: null | number
    title: string
    updateAt: string
    updateSatff: number
  }[]
  goodsGrades: {
    color: string
    companyId: number
    createAt: string
    deleted: number
    displayOrder: number
    id: number
    remarks: string
    shopId: number
    tenantId: null | number
    title: string
    updateAt: string
    updateStaff: number
  }[]
  goodsListDtos: {
    atStore: null | string
    brandId: number
    categoryId: number
    companyId: number
    createAt: string
    customizedPeriod: null | string
    enable: number
    gradeId: number
    id: number
    imageMain: string
    inventoryCount: number
    isCustomized: number
    manufacturerId: number
    nameCn: string
    nameEn: string
    pricePurchase: number
    priceRent: number
    priceSale: number
    sacnCode: null | string
    serial: string
    shopId: number
    status: number
    tryon_id: null | number
    updateAt: string
    updateStaff: number
  }[]
  goodsPackages: {
    companyId: number
    createAt: string
    deleted: number
    description: string
    displayOrder: number
    id: number
    price: number
    shopId: number
    status: number
    tenantId: null | number
    title: string
    updateAt: string
    updateStaff: number
  }[]
  payPatterns: {
    companyId: number
    createAt: string
    deleted: number
    id: number
    payTitle: string
    remarks: string
    shopId: number
    updateAt: string
    updateStaff: number
  }[]
}

export type formTreeInitResponse = {
  channelDtos: {
    id: number
    phone: string
    title: string
    value: string
    wechat: string
    channelPersonDtos: {
      id: number
      phone: string
      title: string
      value: string
      wechat: string
    }[]
  }[]
  id: number
  title: string
}[]
export default [
  {
    method: 'GET',
    name: 'getUser',
    path: '/system/store/tablet/business/today/case',
    params: {},
  },
  { method: 'GET', path: '/system/store/client/business/perselection/form/init', name: 'formInit', params: {}, desc: '初始化表单请求' },
  { method: 'GET', path: '/system/store/client/business/case/channel/form/init', name: 'formTreeInit', params: {}, desc: '渠道商/渠道人树形列表' },
  { method: 'GET', path: '/system/store/case/form/list', name: 'phoneNumberLookup', params: { caseId: '', clientName: '', clientPhone: '', clientWechat: '' }, desc: '根据手机号码查找客户' },
  {
    method: 'POST',
    path: '/system/store/tablet/business/visit',
    name: 'visit',
    params: {
      appointmentId: 0,
      caseAddress: '',
      caseDate: '',
      caseId: 0,
      caseType: 0,
      clientId: 0,
      clientIntention: 0,
      clientName: '',
      clientPhone: '',
      hotelName: '',
      remarks: '',
      responsibleStaff: '',
      value: '',
      visitAt: '',
    },
    desc: '新增到店',
  },
  {
    path: '/auth/login',
    method: 'POST',
    name: 'login',
    params: {
      username: '',
      password: '',
      phone: '',
    },
  },
  {
    path: '/system/store/client/business/company/title',
    method: 'GET',
    name: 'getTitle',
    params: {
      phone: '',
    },
  },
  {
    path: '/system/company/preAuthorization',
    method: 'GET',
    name: 'preAuthorization',
  },
] as ApiUnitConfig[]
