/*
 * @Author: 邱狮杰
 * @Date: 2021-08-15 10:56:31
 * @LastEditTime: 2022-11-07 17:09:32
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/constants/headerGoods.ts
 */
import { goodsListItemTypes } from '@reducers/modules/headerGoods'

export interface HeaderGoods {
  goodsList?: goodsListItemTypes[]
  actionIndex?: number | string
  showGoods?: boolean
}

export const CHOSE_GOODS = 'choseGoods' // 选中goods
export const INIT_GOODS = 'initGoods' // 初始化
export const HIDE_GOODS = 'hide_goods' // 隐藏goods
