import { SynchronizationAwaitError } from 'synchronizationawaiterror'
import { decorators } from 'youwilllike'
import { goodsSearchRequest, goodsStyleResponse } from '@service/api/modules/preselectedYarnList'
import api from '@service/index'
import { requestCommonTypes } from '@type/api/common'
import { styleListItem } from '@reducers/modules/yarnSelectionListStatus'
import { withImgTagListFn } from './tag'
import { tipsWarn } from '@utils/tips'
import { tagWithBright } from '@page/preselectedYarnList/utils'

export class Search {
  @decorators.catchErrorPromise<Error>(e => {
    tipsWarn(e.message)
  })
  async send(params: goodsSearchRequest): Promise<requestCommonTypes<goodsStyleResponse>> {
    const [err, res] = await SynchronizationAwaitError(api['preselectedYarnList|goodsSearch'](params))
    if (err) throw new Error('过滤失败，请重试')
    return res as requestCommonTypes<goodsStyleResponse>
  }
  formatToWeddingList(data: goodsStyleResponse) {
    return (
      data.rows.map(item => {
        return {
          id: item.id,
          src: item.imageMain,
          like: false,
          serial: item.serial,
          categoryId: item.categoryId,
          withImgTagList: withImgTagListFn(item.likeCount, item.tryonCount, item.orderCount) || [],
          tagList: tagWithBright([
            ...item?.tags?.map(tagItem => {
              return { name: tagItem.title }
            }),
            { name: item.gradeTitle },
          ]) || [{ name: item.gradeTitle }],
          showMask: true,
          checkbox: false,
          nameCn: item.nameCn || item.serial,
        } as styleListItem
      }) || []
    )
  }
}
