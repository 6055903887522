import { useConnect } from '@zealforchange/redux-encapsulation'
import { useMemo } from 'react'
// import { connect } from 'react-redux'
import { State } from '@reducers/modules/search'
import * as actions from '@actions/search'
// Search
export function useSearchDispatch() {
  const search = useConnect<{ Search: State }, { Search: State }, typeof actions>((state: { Search: State }) => {
    return { Search: state.Search }
  }, actions) as [{ Search: State }, typeof actions]

  const searchContext = useMemo(() => {
    return search
  }, [search])
  return searchContext
}
