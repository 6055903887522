import { userListResponse } from '@type/api/module/common'

export interface switchClient {
  curCustomer: userListResponse
}
export const SWITCH_CLIENT = 'switch_client' // 选择客户的时候触发
export interface switchGoods { }
export const SWITCH_GOODS = 'switch_goods' // 切换款式时触发
export interface swithReset { }
export const RESET_CLIENT = 'reset_Client' // 重置客户 => 在婚纱列表时
export const RESET_CLIENT_IN_CLASS = 'RESET_CLIENT_IN_CLASS ' // 重置客户 => 在分类列表时
export const JUST_RENDER_CLASS_PAGE = 'JUST_RENDER_CLASS_PAGE ' // 只更新分类页面
export const NEW_TEMPLATE_POP_UP = 'newTemplatePopUp' // 弹出新增到店模版
export const RESET_CLASS_IN_LIST = 'resetClassInList' // 在列表页面清空header 分类
