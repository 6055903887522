/*
 * @Author: 邱狮杰
 * @Date: 2021-11-05 10:53:54
 * @LastEditTime: 2022-11-09 14:34:45
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/page/preselectedYarnList/components/search/components/styleList.tsx
 */
import { Space, Tag } from 'antd'
import { FC } from 'react'
import '../index.scss'

const { CheckableTag } = Tag

export type ListItem = {
  name: string
  active: boolean
  id: number
  parentId?: number // 范围不存在parentId 数据结构的问题
}

export type List = {
  // 只在回调中存在id
  id?: number
  title: string
  list: ListItem[]
}

interface Props {
  onClick?: (item: ListItem, list: ListItem[], title: string, parentId: number | undefined) => void
  list?: {
    title: string
    list: ListItem[]
    id?: number
  }
  multiple: boolean
}
/**
 * @description use 
 * @param param0 
 * @returns 
 */
export const StyleList: FC<Props> = ({ onClick, multiple, list }) => {
  function singleChoice(item: ListItem, title: string, parentId: number | undefined) {
    if (multiple) return
    const newRealList = list?.list.map(realItem => {
      if (parentId) {
        if (item.parentId === parentId && realItem.id === item.id) return { ...realItem, active: !realItem.active }
      }
      if (realItem.id === item.id) return { ...realItem, active: !realItem.active }
      return { ...realItem, active: false }
    })
    onClick?.(item, newRealList as unknown as ListItem[], title, parentId)
  }
  function multipleChoice(item: ListItem, title: string, parentId: number | undefined) {
    if (!multiple) return
    const newRealList = list?.list.map(realItem => {
      if (parentId) {
        if (item.parentId === parentId && realItem.id === item.id) return { ...realItem, active: !realItem.active }
      }
      if (realItem.id === item.id) {
        return { ...realItem, active: !realItem.active }
      }
      return { ...realItem }
    })
    onClick?.(item, newRealList as unknown as ListItem[], title, parentId)
  }
  function clickHandler(item: ListItem, title: string, parentId: number | undefined) {
    singleChoice(item, title, parentId)
    multipleChoice(item, title, parentId)
  }

  if (!list?.list?.length) return <></>

  return (
    <div className='search-modal_rows'>
      <span className='search-modal_rows-title'>{list?.title}:</span>
      <Space size={4} wrap>
        {list?.list?.map((goodsItem, goodsIndex) => {
          return (
            <CheckableTag onClick={clickHandler.bind(null, goodsItem, list.title, list.id)} checked={goodsItem.active} key={goodsIndex}>
              {goodsItem.name}
            </CheckableTag>
          )
        })}
      </Space>
    </div>
  )
}
