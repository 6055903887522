var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:44:12
 * @LastEditTime: 2022-09-30 14:30:06
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/makeUp/update.ts
 */
import { provide } from 'inversify-binding-decorators';
import { http } from '../../config';
import { makeUpConstant } from './makeUp';
let MakeUpUpdate = class MakeUpUpdate {
    // 免试妆
    removeMakeup(id) {
        return __awaiter(this, void 0, void 0, function* () {
            // @ts-ignore
            return yield http({
                method: 'POST',
                url: '/system/store/client/business/dresserScheduleConfirm',
                data: {
                    dresserScheduleId: id,
                },
            });
        });
    }
    //试妆成功
    tryMakeUpSuccess(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'POST',
                url: '/system/store/client/business/tryMakeUpSuccess',
                data: params,
            });
        });
    }
    //试妆失败
    tryMakeUpFail(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'POST',
                url: '/system/store/client/business/tryMakeUpFail',
                data: params,
            });
        });
    }
    //取消指派
    dresserMakeupCancel(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'POST',
                url: '/system/store/client/business/dresserScheduleCancel',
                data: params,
            });
        });
    }
    //指派化妆师
    dresserScheduleAdd(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'PUT',
                url: '/system/store/client/business/order/info/dresserSchedule',
                data: params,
            });
        });
    }
    addRecordBreak(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'POST',
                url: '/system/store/client/business/relation/record/break',
                data: params,
            });
        });
    }
    dresserScheduleCancel(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'DELETE',
                url: '/system/store/client/business/dresserSchedule?id=' + id,
            });
        });
    }
};
MakeUpUpdate = __decorate([
    provide(makeUpConstant.createUpdate)
], MakeUpUpdate);
export { MakeUpUpdate };
