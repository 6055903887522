/* eslint-disable react/jsx-pascal-case */
/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-30 16:28:48
 * @LastEditTime: 2022-11-11 10:21:56
 * @FilePath: /preselectedweddingdress/src/components/animatedSwitch/animatedSwitch.tsx
 * @Description  :
 */

import welcomeBkgLogo from '@assets/img/welcome/welcomeLogo.png'
import { localstoreUtils, StoreConfigNameCollect } from '@config/index'
import { injectProps as withCommonDispatchInjectProps, withCommonDispatch } from '@hoc/commonDispatchHoc'
import { injectProps, withInitialCollection } from '@hoc/commonInit'
import { useCommonDispatch } from '@hooks/commonDispatch'
// import // Classification,
// Welcome,
// PreselectedYarnList,
// Transit,
// Login,
// '@page/index'
import { useVerificationToken } from '@hooks/verificationToken'
import React, { lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './index.scss'
const Welcome = lazy(() => import('../../page/welcome/index'))
const Classification = lazy(() => import('../../page/classification/index'))
const PreselectedYarnList = lazy(() => import('../../page/yarnTestingInterface/index'))
const Transit = lazy(() => import('../../page/transit/index'))
const Login = lazy(() => import('../../page/login/index'))
const Test = lazy(() => import('../../page/test/index'))

interface animationMap {
  PUSH: 'forward'
  POP: 'back'
}

const ANIMATION_MAP = {
  PUSH: 'forward',
  POP: 'back',
}
type Props = {} & RouteComponentProps & injectProps & withCommonDispatchInjectProps

export const Routes = withCommonDispatch(
  withInitialCollection(
    withRouter(({ location, history, welcomeInitialization, withCommonDispatchActions, commonState }: Props) => {
      const [common, commonDispatch] = useCommonDispatch()
      useVerificationToken(
        () => {
          history.push('/welcome')
        },
        () => {
          localstoreUtils.setItem(StoreConfigNameCollect.loginSuccess, JSON.stringify(false))
          history.push('/login')
        }
      )
      const excludePath = ['/login']
      const execInitWelcome = excludePath.includes(history.location.pathname)
      useEffect(() => {
        // 不是login page return
        if (!execInitWelcome) {
          common.Common.localhostImg === null && commonDispatch.editLocalhostImg(false)
          return
        }
        commonDispatch.editLocalhostImg(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [history.location.pathname, execInitWelcome, commonDispatch])
      useEffect(() => {
        ; (async () => {
          if (commonState?.logo) return
          await welcomeInitialization?.(response => {
            withCommonDispatchActions?.editLogo(response.result.logo || welcomeBkgLogo)
            withCommonDispatchActions?.editLocalhost(response.result.localhost || null)
            withCommonDispatchActions?.editWelcomeInfo(response.result)
          })
        })()
      }, [welcomeInitialization, withCommonDispatchActions, commonState])
      return (
        <TransitionGroup
          className={'router-wrapper'}
          style={{ background: '#181818' }}
          childFactory={child => {
            return React.cloneElement(child, {
              classNames: ANIMATION_MAP[history.action as keyof animationMap],
            })
          }}>
          <CSSTransition timeout={500} key={location.pathname}>
            <Suspense
              fallback={
                <div className='SuspenseLoading'>
                  加载中...请稍后
                  {/* <Loading.default /> */}
                </div>
              }>
              <Switch location={location}>
                <Route path='/welcome' exact component={Welcome} />
                <Route path='/classification' exact component={Classification} />
                <Route path='/preselectedYarnList' exact component={PreselectedYarnList} />
                <Route path='/transit' exact component={Transit} />
                <Route path='/login' exact component={Login} />
                <Route path='/test' exact component={Test} />
                <Redirect exact to='/transit' />
              </Switch>
            </Suspense>
          </CSSTransition>
        </TransitionGroup>
      )
    })
  )
)
