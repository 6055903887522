/*
 * @Author: 邱狮杰
 * @Date: 2022-11-02 11:58:20
 * @LastEditTime: 2022-11-12 15:50:47
 * @Description:
 * @FilePath: /preselectedweddingdress/src/reducers/modules/yarnTest.ts
 */
import { getDresserListResponse } from "@hoc/makeupArtistRelated";
import { localstoreUtils, StoreConfigNameCollect } from '@config/index'

import {
  goodsSearchRequest,
  goodsStyleResponseRowsItem,
  tryonYetResponse,
} from "@service/api/modules/preselectedYarnList";
import { getTagAccordingToClassificationID } from "@superBridal/service";
import {
  CreateReducer,
  getAllValsWithActionCollectionHelper,
} from "@zealforchange/conciseredux";
import { Swiper } from "swiper";

export type yarnTestAction = {
  UPDATEPAGINGPARAMETERS: "updatePagingParameters";
  // 更新分类图片
  UPDATEYARNTESTPHOTOS: "updateYarnTestPhotos";
  // 是否打开预览图
  UPDATEPREVIEW: "updatePreview";
  // 更新loading
  UPDATELOADING: "updateLoading";
  // 更新过滤参数
  UPDATEFILTERPARAMETER: "updateFilterParameter";
  // 更新预览类型
  UPDATEVIEWTYPE: "updateViewType";
  // 更新最外层 swiper
  UPDATEWRAPPERSWIPER: "updateWrapperSwiper";
  // 更新预览 swiper
  UPDATEPREVIEWSWIPER: "updatePreviewSwiper";
  // 更新图片喜欢状态
  UPDATEPHTOTSLIKE: "updatePhotosLike";
  //更新列表展示数量
  UPDATELISTLAYOUT: "updatelistlayout";
  // 更新图片喜欢状态
  UPDATEMAKEUPARTISTLIST: "updateMakeupArtistList";
  // 更新已选列表
  UPDATESELECTEDLIST: "updateSelectedList";
  // 更新已试列表
  UPDATETRYONLIST: "updateTryonList";
  // 是否是空列表   显示空
  UPDATENULL: "updateNull";
  // 更新tag列表
  UPDATETAG: "updateTag";
  // 更新选中tag
  UPDATETAGACTIVE: "updateTagActive";
  // 重置activeTag
  UPDATEACTIVETAGRESET: "updateTagActiveReset";
  // 更新档期时间
  UPDATESCHDULE: "updateSchdule";
};

export type yarnTestActionTypes =
  getAllValsWithActionCollectionHelper<yarnTestAction>;

export type yarnTestStateTypes = {
  openPreview: boolean;
  yarnTestPhotos: goodsStyleResponseRowsItem[];
  loading: boolean;
  filterParmas: Partial<goodsSearchRequest>;
  listLayout: number;
  // 已选 | 已试 | 预览 | 为选| 化妆师
  viewType: "selected" | "tryed" | "preview" | "unsealed" | "makeup";
  wrapperSwiper: Swiper | null;
  makeupArtistList: getDresserListResponse["rows"];
  // 已选列表
  selectList: goodsStyleResponseRowsItem[];
  tryList: tryonYetResponse["rows"];
  previewSwiper: Swiper | null;
  updateNull: boolean;
  tag: getTagAccordingToClassificationID.response<{
    active: boolean;
  }>;
  schedule: {
    start: Date | null | undefined;
    end: Date | null | undefined;
  };
};

export type yarnTestActionPayloadTypes = {
  openPreview?: boolean;
  yarnTestPhotos?: goodsStyleResponseRowsItem[];
  updateLoading?: boolean;
  listLayout?: number;
  filterParmas?: Partial<goodsSearchRequest>;
  wrapperSwiper?: Swiper | null;
  updatePhotosLikeGoodsId?: number;
  makeupArtistList?: getDresserListResponse["rows"];
  selectList?: goodsStyleResponseRowsItem[];
  viewType?: yarnTestStateTypes["viewType"];
  tryList?: tryonYetResponse["rows"];
  previewSwiper?: Swiper | null;
  updateNull?: boolean;
  tag?: getTagAccordingToClassificationID.response<{ active: boolean }>;
  tagActive?: {
    fatherId?: number;
    id: number;
  };
  schedule?: {
    start: Date | null | undefined;
    end: Date | null | undefined;
  };
};

export const yarnTest = new CreateReducer<
  yarnTestStateTypes,
  yarnTestActionPayloadTypes,
  yarnTestActionTypes
>({
  openPreview: false,
  yarnTestPhotos: [],
  loading: false,
  filterParmas: {
    // shield: 1
  },
 
  listLayout: localstoreUtils.getItem(StoreConfigNameCollect.listLayout)?Number(localstoreUtils.getItem(StoreConfigNameCollect.listLayout)):3,
  viewType: "unsealed",
  wrapperSwiper: null,
  makeupArtistList: [],
  selectList: [],
  tag: [],
  tryList: [],
  previewSwiper: null,
  updateNull: false,
  schedule: {
    start: null,
    end: null,
  },
})
  .addAction("updatelistlayout", (state, action) => {
    return { ...state, listLayout: action.listLayout?? state.listLayout };
  })
  .addAction("updateMakeupArtistList", (state, action) => {
    return {
      ...state,
      makeupArtistList: action.makeupArtistList ?? state.makeupArtistList,
    };
  })
  .addAction("updatePagingParameters", (state, action) => {
    return { ...state };
  })
  .addAction("updateYarnTestPhotos", (state, action) => {
    return {
      ...state,
      yarnTestPhotos: action.yarnTestPhotos || state.yarnTestPhotos,
    };
  })
  .addAction("updatePreview", (state, action) => {
    return { ...state, openPreview: action?.openPreview ?? false };
  })
  .addAction("updateLoading", (state, action) => {
    return { ...state, loading: action.updateLoading ?? state.loading };
  })
  .addAction("updateFilterParameter", (state, action) => {
    return {
      ...state,
      filterParmas: action.filterParmas ?? state.filterParmas,
    };
  })
  .addAction("updateWrapperSwiper", (state, action) => {
    return {
      ...state,
      wrapperSwiper: action.wrapperSwiper ?? state.wrapperSwiper,
    };
  })
  .addAction("updatePhotosLike", (state, action) => {
    const newDate = state.yarnTestPhotos.map((n) => {
      return {
        ...n,
        like: n.id === action.updatePhotosLikeGoodsId ? !n.like : n.like,
      };
    });
    return { ...state, yarnTestPhotos: newDate };
  })
  .addAction("updateSelectedList", (state, action) => {
    return { ...state, selectList: action.selectList ?? state.selectList };
  })
  .addAction("updateViewType", (state, action) => {
    return { ...state, viewType: action.viewType ?? state.viewType };
  })
  .addAction("updateTryonList", (state, action) => {
    return { ...state, tryList: action.tryList ?? state.tryList };
  })
  .addAction("updatePreviewSwiper", (state, action) => {
    return {
      ...state,
      previewSwiper: action.previewSwiper ?? state.previewSwiper,
    };
  })
  .addAction("updateNull", (state, action) => {
    return { ...state, updateNull: action.updateNull ?? state.updateNull };
  })
  .addAction("updateTag", (state, action) => {
    return { ...state, tag: action.tag ?? state.tag };
  })
  .addAction("updateTagActive", (state, action) => {
    const stateTag = state.tag.map((n) => {
      if (n.id === action.tagActive?.fatherId) {
        const newTag = n.tags.map((l) => {
          if (l.id === action.tagActive?.id) {
            return { ...l, active: !l.active };
          }
          return { ...l };
        });
        return { ...n, tags: newTag };
      }
      return {
        ...n,
      };
    });
    return { ...state, tag: stateTag };
  })
  .addAction("updateTagActiveReset", (state) => {
    const tag = state.tag.map((n) => {
      const tag = n.tags.map((i) => ({ ...i, active: false }));
      return { ...n, tags: tag };
    });
    return { ...state, tag: tag };
  })
  .addAction("updateSchdule", (state, action) => {
    return { ...state, schedule: action.schedule ?? state.schedule };
  })
  .setReducerKey("yarnTest");
