/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 17:15:36
 * @LastEditTime : 2021-08-05 10:34:13
 * @FilePath     : /preselectedyarn/src/constants/headerButton.ts
 * @Description  :
 */
import { ReactElement } from 'react'

export interface HeaderAction {
  component?: ReactElement
  replace?: 'unSelected' | 'selected' | 'deletetion' | 'loginOut' | 'tried'
  curShowComponent?: 'unSelected' | 'selected' | 'deletetion' | 'loginOut' | 'tried'
}
export const HEADER_RIGHT_BUTTON_OPERATION_EDIT = 'headerRightButtonOperationEdit' // 修改头部左侧按钮组件
export const HEADER_RIGHT_BUTTON_OPERATION_HIDDEN = 'headerRightButtonOperationHidden' // 隐藏头部左侧按钮组件
export const REPLACE_COMPONENT_STATUS = 'ReplaceComponentStatus' // 替换某个组件
export const CURRENT_DISPLAY = 'currentDisplay' // 当前显示的组件
