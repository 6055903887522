import { FC, ReactElement } from 'react'

interface Props {
  analyzingConditions: boolean | undefined // 判断条件
  trueCpm: ReactElement
  falseCpm: ReactElement
}
const If: FC<Props> = ({ trueCpm, falseCpm, analyzingConditions }) => {
  return analyzingConditions ? trueCpm : falseCpm
}
export default If
