/*
 * @Author: 邱狮杰
 * @Date: 2022-11-02 11:58:20
 * @LastEditTime: 2022-11-12 16:01:48
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/hooks/useYarnTest.ts
 */
import { yarnTest, yarnTestStateTypes } from '@reducers/modules/yarnTest';
import { bindActionCreators } from '@zealforchange/conciseredux';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

export function useYarnTest() {

    const dispatchWithYarnTest = bindActionCreators(
        yarnTest.getCallBackAll(), useDispatch())

    const curStateWithYarnTestForRedux = () => yarnTest.getCurState()

    const curStateWithYarnTest = useSelector((state: { yarnTest: yarnTestStateTypes }) => {
        return state.yarnTest
    })
    function getTagActiveWithSubmitData(): {
        filterId: number;
        tags: number[];
    }[] {
        return curStateWithYarnTestForRedux().tag.map(n => {
            const activeTags = n.tags.filter(i => i.active).map(n => n.id)
            if (!activeTags.length) {
                return false
            }
            return {
                filterId: n.id,
                tags: activeTags
            }
        }).filter(Boolean) as
            {
                filterId: number;
                tags: number[];
            }[]
    }

    function getFilterParmater() {
        const h = {
            edate: curStateWithYarnTestForRedux().schedule.end ? dayjs(curStateWithYarnTestForRedux().schedule.end).format("YYYY-MM-DD") : undefined,
            sdate: curStateWithYarnTestForRedux().schedule.start ? dayjs(curStateWithYarnTestForRedux().schedule.start).format('YYYY-MM-DD') : undefined

        }

        const params = {
            ...curStateWithYarnTestForRedux().filterParmas, ...h
        }

        return {
            ...params,
            tags: params.tags?.length ? params.tags : undefined, shield: params.sdate && params.edate ? 1 : undefined
        }

    }

    return { dispatchWithYarnTest, curStateWithYarnTest, curStateWithYarnTestForRedux, getTagActiveWithSubmitData, getFilterParmater }


}