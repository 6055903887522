import { CHOSE_GOODS, INIT_GOODS, HeaderGoods } from '@constants/headerGoods'
import { defaultAction } from '@utils/action'
import { Action } from '@utils/createReducer'

// 初始化
export function initGoods(goodsList: HeaderGoods['goodsList']): Action & HeaderGoods {
  return defaultAction<HeaderGoods>(INIT_GOODS, { goodsList })
}

export function choseGoods(goodsChoseIndex: number | string): Action & HeaderGoods {
  return defaultAction<HeaderGoods>(CHOSE_GOODS, { actionIndex: goodsChoseIndex })
}
