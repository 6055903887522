var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:43:53
 * @LastEditTime: 2022-09-30 11:44:03
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/pay/create.ts
 */
import { provide } from 'inversify-binding-decorators';
import { PayConstant } from './pay';
// @injectable()
let PayCreate = class PayCreate {
    init() {
        console.log('init');
    }
};
PayCreate = __decorate([
    provide(PayConstant.createCreate)
], PayCreate);
export { PayCreate };
