/*
 * @Author: 邱狮杰
 * @Date: 2021-09-17 11:20:29
 * @LastEditTime: 2023-02-16 09:59:25
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/utils/fileExtension.ts
 */
/**
 * @description 获取文件后缀
 * @param file
 * @returns
 */
export function fileExtension(file: string): string {
  if (!file) return file
  let fileName = file.lastIndexOf('.') //取到文件名开始到最后一个点的长度
  let fileNameLength = file.length //取到文件名长度
  let fileFormat = file.substring(fileName + 1, fileNameLength) //截
  return fileFormat
}
/**
 * @description 重写后缀
 * @param extension
 * @param h
 * @param w
 * @returns
 */
export function thumbnailSize(extension: string, h: number, w: number): string {
  if (!extension) return extension
  return `${extension}__${h}x${w}.${extension}`
}
/**
 * @description 重写成ali对象服务的缩略图后缀
 * @param extension
 * @param w
 * @returns
 */
export function aliThumbnailSize(w: number, useH?: boolean): string {
  
  if (!w) return ''
  return `x-oss-process=image/resize,${useH ? 'h' : 'w'}_${w}`
}
/**
 * @description 替换后缀
 * @param fileName
 * @param replaceSuffixContent
 * @param suffix
 * @returns
 */
export function replaceSuffix(fileName: string, replaceSuffixContent: string, suffix: string) {
  if (fileName?.match(/data:image/g)?.length === 1) {
    return fileName
  }
  if (!fileName) return fileName
  return fileName.replace(replaceSuffixContent, suffix).replace('https', 'http')
}
/**
 * @description 增加query
 * @param fileName
 * @param query
 * @returns
 */
export function addQuery(fileName: string, query: string) {
  if (fileName?.match(/data:image/g)?.length === 1) {
    return fileName
  }
  return `${fileName}?${query}`
}

// function restoreAliSrc(path: string) {
//   // http://gtimg.bridal.work/2021/10/08/9a61a5f8dca543ffbd5e5537fede54ab.jpg?x-oss-process=image/resize,l_620
//   return path.replace(/?x-oss-process=image/resize,l_620/g, '')
// }
