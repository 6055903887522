/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-03 11:00:22
 * @LastEditTime: 2022-11-03 14:39:54
 * @FilePath: /preselectedweddingdress/src/reducers/modules/headerCustomer.ts
 * @Description  :
 */

import { CLEAR_CUSTOMERS, headerCustomer, INIT_CUSTOMERS, NEW_CUSTOMERS, RESET_CUSTOMERS, SWITCH_CUSTOMERS } from '@constants/headerCustomer'
import { userListResponse } from '@type/api/module/common'
import { CreateReducer } from '@zealforchange/conciseredux'
import { Action } from 'redux'

export interface State {
  userInfoList: userListResponse[]
  curUser: userListResponse
}

export type DispatchTypes = typeof INIT_CUSTOMERS | typeof NEW_CUSTOMERS | typeof SWITCH_CUSTOMERS | typeof RESET_CUSTOMERS | typeof CLEAR_CUSTOMERS

const HeaderCustomer = new CreateReducer<State, Action & headerCustomer, DispatchTypes>({
  userInfoList: [],
  curUser: {
    caseDate: null,
    caseEdate: null,
    caseSdate: null,
    caseId: null,
    clientName: '切换客户',
    clientPhone: '',
    visitId: null,
    appointmentId: null,
  },
})
  .addAction(INIT_CUSTOMERS, (state, action) => {
    state.userInfoList = action.init || []
    return { ...state }
  })
  .addAction(NEW_CUSTOMERS, (state, action) => {
    action?.userInfo && state.userInfoList.push(action.userInfo)
    return state
  })
  .addAction(SWITCH_CUSTOMERS, (state, action) => {
    const newCurUser = state.userInfoList.filter(userItem => {
      if (userItem.clientName === action?.switchClientUser) return userItem
      return false
    })
    console.log('what?')
    return { ...state, curUser: newCurUser[0] }
  })
  .addAction(RESET_CUSTOMERS, state => {
    state.curUser = {
      caseEdate: null,
      caseSdate: null,
      caseId: null,
      clientName: '切换客户',
      clientPhone: '',
      visitId: null,
      appointmentId: null,
      caseDate: null,
    }
    return { ...state }
  })
  .addAction(CLEAR_CUSTOMERS, () => {
    return {
      userInfoList: [],
      curUser: {
        caseDate: null,
        caseSdate: null,
        caseEdate: null,
        caseId: null,
        clientName: '切换客户',
        clientPhone: '',
        visitId: null,
        appointmentId: null,
      },
    }
  })
  .setReducerKey('HeaderCustomer')

// function HeaderCustomer(state: State = {
//     userInfoList: [{ userPhone: 1398312420, userName: 'qsj' }],
//     curUser: {
//         userName: "切换用户",
//         userPhone: 0
//     }
// }, action: Action & headerCustomer
// ) {
//     switch (action.type) {
//         case NEW_CUSTOMERS:
//             // @ts-ignore
//             action?.userInfo && state.userInfoList.push(action.userInfo)
//             return state
//         case SWITCH_CUSTOMERS:
//             // @ts-ignore
//             state.curUser = state.userInfoList.find(userItem => {
//                 if (userItem.userName === action?.userInfo?.userName) return userItem
//                 return state.userInfoList[0]
//             })
//             return state
//         default:
//             return state
//     }
// }

export { HeaderCustomer }
