/*
 * @Author: 邱狮杰
 * @Date: 2022-11-03 11:18:33
 * @LastEditTime: 2022-11-07 17:03:40
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/reducers/modules/operationBtn.tsx
 */
import { DeleteOutlined, FormOutlined, HeartOutlined, LikeOutlined } from '@ant-design/icons'
import { mergeFnWithPromiseFn } from '@mxnet/types/dts'
import { Button } from 'antd'
import { CSSProperties } from 'react'

const btnStyle: CSSProperties = {
    color: '#f1f1f1',
    borderColor: '#f1f1f1',
}

export const loginOut = (click?: mergeFnWithPromiseFn) => {
    return <Button type='primary' className='selectHocButton' danger onClick={click}>
        退出登录
    </Button>
}

export const editComponent = (keepPicking?: mergeFnWithPromiseFn, clickEditHandler?: mergeFnWithPromiseFn) => <>
    <div className='withEditHoc'>
        <Button className='size' style={btnStyle} onClick={keepPicking} ghost>
            继续挑选
        </Button>
        &nbsp;
        <Button className='size' style={btnStyle} onClick={clickEditHandler} ghost>
            <FormOutlined />
            编辑
        </Button>
    </div>
</>

export const cancelDeletion = (deletetion?: mergeFnWithPromiseFn, cancel?: mergeFnWithPromiseFn) => <>
    <div>
        <Button className='size' style={btnStyle} onClick={cancel} ghost>
            取消
        </Button>
        &nbsp;
        <Button className='size' onClick={deletetion} type='primary' danger>
            <DeleteOutlined />
            删除
        </Button>
    </div>
</>

export const tried = (onTriedLogic?: mergeFnWithPromiseFn) => {
    return <Button
        style={{
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
        }}
        onClick={onTriedLogic}
        className='selectHocButton'
        ghost>
        <div className='selectHoc'>
            <LikeOutlined /> &nbsp; 已试
        </div>
    </Button>
}

export const unSelectedButton = (onUnSelectedButtonClick?: mergeFnWithPromiseFn) => (
    <Button
        style={{
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
        }}
        onClick={onUnSelectedButtonClick}
        className='selectHocButton'
        ghost>
        <div className='selectHoc'>
            <HeartOutlined />
            &nbsp; 已选
        </div>
    </Button>
)

export const triedStatus = (keepExe?: mergeFnWithPromiseFn) => {
    return <>
        <Button
            style={{
                color: '#f1f1f1',
                borderColor: '#f1f1f1',
            }}
            className='selectHocButton'
            onClick={keepExe}
            ghost>
            <div className='selectHoc'>继续挑选</div>
        </Button>
        &nbsp;
        <Button
            style={{
                color: '#f1f1f1',
                borderColor: '#f1f1f1',
            }}
            className='selectHocButton'
            disabled
            ghost>
            <div className='selectHoc'>
                <LikeOutlined /> &nbsp; 已试
            </div>
        </Button>
    </>
}

export const selected = (keepExe?: mergeFnWithPromiseFn) => {
    return <>
        <Button
            style={{
                color: '#f1f1f1',
                borderColor: '#f1f1f1',
            }}
            className='selectHocButton'
            onClick={keepExe}
            ghost>
            <div className='selectHoc'>继续挑选</div>
        </Button>
        &nbsp;
        <Button
            style={{
                color: '#f1f1f1',
                borderColor: '#f1f1f1',
            }}
            className='selectHocButton'
            disabled
            ghost>
            <div className='selectHoc'>
                <HeartOutlined />
                &nbsp; 已选
            </div>
        </Button>
    </>

}