import { pageImageResponse } from '@components/bigPcture/hooks/usePageImages'
import { styleListItem } from '@reducers/modules/yarnSelectionListStatus'
import { goodsStyleResponse, perselectionYetResponse, tryonYetResponse } from '@service/api/modules/preselectedYarnList'
import { requestCommonTypes } from '@type/api/common'
import { withImgTagListFn } from '@utils/tag'
export interface historySearch {
  classification: string
  page: string
  goodsId: string
  preview: string
  token?: string
}
/**
 * @description 格式化商品列表
 */
export function formatProductList(data?: requestCommonTypes<goodsStyleResponse>): styleListItem[] {
  return (
    data?.result?.rows?.map(rowsItem => {
      return {
        ...rowsItem,
        id: rowsItem.id,
        categoryId: rowsItem.categoryId,
        src: rowsItem.imageMain,
        like: false,
        serial: rowsItem.serial,
        checkbox: false,
        showMask: true,
        withImgTagList: withImgTagListFn(rowsItem.likeCount, rowsItem.tryonCount, rowsItem.orderCount),
        tagList: []
        // tagWithBright([
        //   ...rowsItem?.tags?.map(tagItem => {
        //     return { name: tagItem.title || '' }
        //   }),
        //   { name: rowsItem.gradeTitle },
        // ]) || [{ name: rowsItem.gradeTitle }]
        ,
        nameCn: rowsItem.nameCn || rowsItem.serial,
      } as styleListItem
    }) || []
  )
}
/**
 * @description 格式化已选纱数据类型到商品数据类型
 */
export function formatTheSelectedYarnToTheProduct(data?: requestCommonTypes<perselectionYetResponse>): styleListItem[] {
  return (
    data?.result?.rows?.map(rowsItem => {
      return {
        id: rowsItem.id,
        categoryId: rowsItem.categoryId,
        src: rowsItem.imageMain,
        like: true,
        serial: rowsItem.serial,
        checkbox: false,
        showMask: true,
        withImgTagList: withImgTagListFn(rowsItem.likeCount, rowsItem.tryonCount, rowsItem.orderCount),
        // TODO: 已选纱的tag字段缺失
        tagList: tagWithBright([
          ...(rowsItem?.tags?.map(tagItem => {
            return { name: tagItem.title } as { name: string }
          }) as { name: string }[]),
          { name: rowsItem.gradeTitle },
        ]) || [{ name: rowsItem.gradeTitle }],
        nameCn: rowsItem.nameCn || rowsItem.serial,
      } as styleListItem
    }) || []
  )
}
/**
 * @description 已试
 * @param data
 * @returns
 */
export function formatTryonListToProduct(data?: requestCommonTypes<tryonYetResponse>): styleListItem[] {
  return (
    data?.result.rows.map(goodsItem => {
      return {
        id: goodsItem.id as unknown as number,
        categoryId: 0,
        src: goodsItem.imageMain,
        like: goodsItem.like === 0 ? false : true,
        serial: goodsItem.serial,
        checkbox: false,
        showMask: true,
        withImgTagList: [],
        tagList: [],
        nameCn: goodsItem.nameCn || '暂无款式名称',
        triedList: goodsItem.tryonPhotos,
      } as styleListItem
    }) || []
  )
}
/**
 * @description 定义试纱的大图urlList
 * @param data
 * @returns
 */
export function formatTriedList(data: styleListItem['triedList']) {
  return (
    data?.map(item => {
      return {
        src: item.photoPath,
        id: item.id,
      }
    }) || []
  )
}
export function formatPreviewYList(data?: requestCommonTypes<pageImageResponse>) {
  return (
    data?.result?.map(rowsItem => {
      return {
        id: rowsItem.id,
        src: rowsItem.path,
        like: false,
        serial: '',
        categoryId: 0,
        goodsId: rowsItem.goodsId,
      } as styleListItem
    }) || []
  )
}
export function getQueryValue(queryName: keyof historySearch, URL: string) {
  const reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)', 'i')
  const r = URL.substr(1).match(reg)
  if (r != null) {
    return decodeURI(r[2])
  } else {
    return null
  }
}
export function matchSearch(url: string) {
  // const classificationMatch = /(\?|&)(classification)=.+?&$/g
  // const pageMatch = /(\?|&)(page)=.+/g
  // const goodsIdMatch = /(\?|&)(goodsId)=.+/g
  // const previewMatch = /(\?|&)(preview)=.+/g
  // const r = /(\?|&)(classification|page|goodsId|preview)=/g
  // console.log(window.location.search)
  return {
    classification: getQueryValue('classification', url),
    page: getQueryValue('page', url),
    goodsId: getQueryValue('goodsId', url),
    preview: getQueryValue('preview', url),
  } as historySearch
}

export function tagWithBright(list: { name: string }[]) {
  return list.filter(nameItem => {
    return nameItem.name === '璀璨'
  })
}
