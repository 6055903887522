/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-04 11:29:18
 * @LastEditTime : 2021-08-06 16:51:43
 * @FilePath     : /preselectedyarn/src/actions/yarnSelectionListStatus.ts
 * @Description  :
 */
import { Action } from 'redux'
import { defaultAction } from '@utils/action'
import {
  yarnSelectionListStatusActionTypes,
  LIKE_RESET,
  STYLE_LIST_INIT,
  INVERSION_OF_MASK,
  MODIFY_CURRENT_STATUS,
  DELETETION,
  MARK_DELETETION,
  SELECT_IF_THE_ID_ISEQUAL,
  INIT_SELECTED_LIST,
  INITIALIZATION_TRIED,
} from '@constants/yarnSelectionListStatus'
import { styleListItem } from '@reducers/modules/yarnSelectionListStatus'

export function initializationTried(list: yarnSelectionListStatusActionTypes['initStyleList']) {
  return defaultAction<yarnSelectionListStatusActionTypes>(INITIALIZATION_TRIED, {
    initStyleList: list,
  })
}

export function likeResetAction(index: number): Action & yarnSelectionListStatusActionTypes {
  return defaultAction(LIKE_RESET, { index })
}

export function styleListAction(list: yarnSelectionListStatusActionTypes['initStyleList']): Action & yarnSelectionListStatusActionTypes {
  return defaultAction(STYLE_LIST_INIT, { initStyleList: list })
}

export function inversionOfMaskAction(index: number): Action & yarnSelectionListStatusActionTypes {
  return defaultAction(INVERSION_OF_MASK, { index })
}

export function modifyCurrentStatus(key: yarnSelectionListStatusActionTypes['curState']) {
  return defaultAction<yarnSelectionListStatusActionTypes>(MODIFY_CURRENT_STATUS, { curState: key })
}

export function deletetion() {
  return defaultAction<yarnSelectionListStatusActionTypes>(DELETETION)
}

export function markDeletetion(id: number) {
  return defaultAction<yarnSelectionListStatusActionTypes>(MARK_DELETETION, { id })
}

export function equalToSelect(id: number) {
  return defaultAction<yarnSelectionListStatusActionTypes>(SELECT_IF_THE_ID_ISEQUAL, { id })
}

export function initSelectedList(list: styleListItem[]) {
  return defaultAction<yarnSelectionListStatusActionTypes>(INIT_SELECTED_LIST, {
    initStyleList: list,
  })
}
