import { ComponentType, Component } from "react";
import { SynchronizationAwaitError } from 'synchronizationawaiterror'
import api from '@service/index'
import { requestCommonTypes } from "@type/api/common";
import { message } from "antd";
import { getMakeupArtistList } from '@superBridal/service'

export interface getDresserListRequest {
    edate: string
    name: string
    phone: string
    sdate: string
    title: string
}

export type getDresserListResponse = getMakeupArtistList.response

export interface injectProps {
    // /system/store/tablet/business/dresser/list
    // 化妆师列表
    getDresserList: (req: Partial<getDresserListRequest>) => Promise<getDresserListResponse>
    // /system/store/tablet/business/dresser/words
    // 化妆师作品
    getDresserWords: any
    // /system/store/tablet/business/dresser/config
    // 化妆师配置
    getDresserConfig: () => Promise<getDresserConfigResponse>
}

export interface getDresserConfigResponse {
    dresserImage: null | string
    dresserTabletEnableConfig: number
}

export const makeup = {
    classificationOfDressers: 'makeup'
}

/**
 * @desc 化妆师相关请求
 */
export const makeupArtistRelatedHttp = <T extends injectProps>(C: ComponentType<any>) => {

    class MakeupArtistRelatedHttp extends Component {
        async getDresserWords() {
            const [err, res] = await SynchronizationAwaitError<requestCommonTypes<any>, requestCommonTypes<any>>(api['preselectedYarnList|dresserWords']())
            if (err) {
                message.destroy()
                message.warn('获取化妆师作品失败，请稍后再试')
                return
            }
            if (res?.code !== 200) {
                message.destroy()
                message.warn('获取化妆师作品失败，请稍后再试')
                return
            }
            return res.result
        }

        async getDresserList(req: getDresserListRequest) {
            const [err, res] = await SynchronizationAwaitError<requestCommonTypes<any>, requestCommonTypes<any>>(api['preselectedYarnList|dresserList'](req))
            if (err) {
                message.destroy()
                message.warn('获取化妆师列表失败，请稍后再试')
                return
            }
            if (res?.code !== 200) {
                message.destroy()
                message.warn('获取化妆师列表失败，请稍后再试')
                return
            }
            return res.result
        }

        async getDresserConfig() {
            const [err, res] = await SynchronizationAwaitError<requestCommonTypes<any>, requestCommonTypes<any>>(api['preselectedYarnList|dresserConfig']())
            if (err) {
                message.destroy()
                message.warn('获取化妆师配置失败，请稍后再试')
                return
            }
            if (res?.code !== 200) {
                message.destroy()
                message.warn('获取化妆师配置失败，请稍后再试')
                return
            }
            return res.result
        }

        render() {
            const { ...resetProps } = this.props
            return <C {...(resetProps as any)} getDresserConfig={this.getDresserConfig}
                getDresserList={this.getDresserList} getDresserWords={this.getDresserWords} />
        }
    }

    return MakeupArtistRelatedHttp

}



