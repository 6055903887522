import { Container } from 'inversify';
import 'reflect-metadata';
export class BuildContainer {
    constructor(getTopicTypes) {
        this.container = new Container();
        this.getTopicTypes = {};
        this.getTopicTypes = getTopicTypes;
    }
    setCreate(constructor, cb) {
        if (this.getTopicTypes.createCreate)
            cb ? cb === null || cb === void 0 ? void 0 : cb(this.getBuildContainer().bind(this.getTopicTypes.createCreate).to(constructor)) : this.getBuildContainer().bind(this.getTopicTypes.createCreate).to(constructor);
        return this;
    }
    setUpdate(constructor, cb) {
        if (this.getTopicTypes.createUpdate)
            cb ? cb === null || cb === void 0 ? void 0 : cb(this.getBuildContainer().bind(this.getTopicTypes.createUpdate).to(constructor)) : this.getBuildContainer().bind(this.getTopicTypes.createUpdate).to(constructor);
        return this;
    }
    setRead(constructor, cb) {
        if (this.getTopicTypes.createRead)
            cb ? cb === null || cb === void 0 ? void 0 : cb(this.getBuildContainer().bind(this.getTopicTypes.createRead).to(constructor)) : this.getBuildContainer().bind(this.getTopicTypes.createRead).to(constructor);
        return this;
    }
    setDelete(constructor, cb) {
        if (this.getTopicTypes.createDelete)
            cb ? cb === null || cb === void 0 ? void 0 : cb(this.getBuildContainer().bind(this.getTopicTypes.createDelete).to(constructor)) : this.getBuildContainer().bind(this.getTopicTypes.createDelete).to(constructor);
        return this;
    }
    // setDispatchingCore<T>(constructor: new (...args: never[]) => T, cb?: mergeFnWithPromiseFn<void, [ReturnType<interfaces.BindingToSyntax<T>['to']>]>) {
    //   if (this.getTopicTypes.createUpdate) cb ? cb?.(this.getBuildContainer().bind<T>(this.getTopicTypes.createUpdate).to(constructor)) : this.getBuildContainer().bind(this.getTopicTypes.createUpdate).to(constructor)
    //   return this
    // }
    getBuildContainer() {
        return this.container;
    }
}
