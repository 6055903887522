/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 10:59:11
 * @LastEditTime: 2022-11-07 14:16:23
 * @FilePath: /preselectedweddingdress/src/index.tsx
 * @Description  :
 */

import '@assets/scss/main.scss'
import 'antd/dist/antd.less'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
// import 'swiper/swiper.scss'
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/components/navigation/navigation.scss'
import '@assets/scss/animation.scss'
import { store } from '@reducers/index'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'
import { HashRouter } from 'react-router-dom'
// import 'reveal/index.css'
import swiperCore, { EffectCoverflow, Keyboard, Navigation, Pagination, Virtual, Zoom } from 'swiper'
// import 'swiper/swiper-bundle.css'
import { connectionPlug } from '@superBridal/service'
import { Auth } from '@utils/servicePlugin/auth'
import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
import App from './App'

// @ts-ignore
connectionPlug([new Auth()])
moment.locale('zh-CN')

swiperCore.use([Pagination, Virtual, Keyboard, Zoom, EffectCoverflow, Navigation])

render(
  <HashRouter>
    <Provider store={store}>
      <ConfigProvider input={{ autoComplete: 'off' }} locale={zhCN}>
        <App />
      </ConfigProvider>
    </Provider>
  </HashRouter>
  ,
  document.getElementById('root')
)
