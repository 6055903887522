import { SynchronizationAwaitError } from 'synchronizationawaiterror'
import { message } from 'antd'
import { formDataOutput } from '@layout/header/components/switchCustomers/utils'
import { requestCommonTypes } from '@type/api/common'
import api from '@service/index'
export function useSubmit() {
  async function submit(params: formDataOutput): Promise<requestCommonTypes<any>> {
    const [err, res] = await SynchronizationAwaitError(api['common|visit'](params || {}))
    if (err) {
      message.destroy()
      message.warn('提交失败，请重试')
      throw new Error('提交失败，请重试')
    }
    return res as requestCommonTypes<any>
  }
  return [submit]
}
