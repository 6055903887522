/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 15:41:32
 * @LastEditTime: 2022-11-10 17:41:00
 * @FilePath: /preselectedweddingdress/src/layout/header/components/headerGoods/index.tsx
 * @Description  : 顶部款式
 */
import { useHeaderGoods } from '@hooks/useHeaderGoods'
import { useYarnTest } from '@hooks/useYarnTest'
import { State } from '@reducers/modules/headerGoods'
import {
  message, Tabs
} from 'antd'
import { FC, useCallback, useMemo } from 'react'
import './index.scss'


const { TabPane } = Tabs

type Props = {
  goodsList: State['goodsList']
  activeKey: string
  onProxyClick: (index: number | string) => void
  className?: string
}

const HeaderGoods: FC<Props> = ({ className, goodsList, onProxyClick, activeKey }) => {
  const { curStateWithYarnTest, dispatchWithYarnTest } = useYarnTest()

  const { curStateWithHeaderGoods } = useHeaderGoods()

  const click = useCallback((key: string) => {
    if (curStateWithYarnTest.loading) {
      message.destroy()
      message.warn("loading...")
      return
    }
    dispatchWithYarnTest.updateTagActiveReset({})
    onProxyClick(key)
  }, [curStateWithYarnTest.loading, dispatchWithYarnTest, onProxyClick])

  const list = useMemo(() => {
    if (curStateWithHeaderGoods.showGoodsList) return goodsList
    return []
  }, [curStateWithHeaderGoods.showGoodsList, goodsList])

  return (
    <Tabs className={className} tabPosition='top' onTabClick={key => click(key)} centered tabBarStyle={{ color: 'rgba(86,86,86,1)', margin: '0' }} type='line' activeKey={activeKey}>
      {list.map((goodsItem, index) => {
        return <TabPane forceRender tab={goodsItem.title} key={goodsItem.title}></TabPane>
      })}
    </Tabs>
  )
}

export default HeaderGoods
