/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 11:15:33
 * @LastEditTime: 2022-11-02 16:51:20
 * @FilePath: /preselectedweddingdress/src/config/index.ts
 * @Description  :
 */
import { chain } from 'youwilllike'
// 还有一些方便开发的配置
export const CONSOLE_REQUEST_ENABLE = false // 开启请求参数打印
export const CONSOLE_RESPONSE_ENABLE = false // 开启响应参数打印
export const CONSOLE_MONITOR_ENABLE = false // 监控记录打印

export const baseConfig = {
    homeURL: '/pages/index/index',
}
// http://192.168.199.123:8083
export const BASE_AJAX_URL = process.env.NODE_ENV === 'production' ? 'https://pre.bridal.work/api' : 'https://pre.bridal.work/api'
// export const BASE_AJAX_URL ='http://192.168.199.8:8083'

export const AXIOS_DEFAULT_CONFIG = {
    baseURL: BASE_AJAX_URL,
    timeout: 20000,
    maxContentLength: 2000,
    headers: {},
}
export const localstoreUtils = new chain.JLocalstorage()

// 缓存配置名称表
export const StoreConfigNameCollect = {
    register: 'register',
    hasPhone: 'hasPhone',
    phone: 'phone',
    userInfo: 'userInfo',
    userType: 'userType',
    token: 'token',
    auth_type: 'Bearer',
    loginSuccess: 'ls', // 是否登录成功 base64转一下 true | false
    tag: 'tag', // 门店登录tag
    title: 'title', // 门店title
    listLayout:'listLayout',
}
