/**
 * @description 返回本月天数
 * @param { number } year 年
 * @param { number } mouth 月
 * @returns { number }
 */
export function getMonthDays(year: number, mouth: number): number {
  return new Date(year, mouth, 0).getDate()
}
export function getNextDate(date:any, day:any) {
  const dd = new Date(date);
  dd.setDate(dd.getDate() + day);
  const y = dd.getFullYear();
  const m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;
  const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
  return y + '-' + m + '-' + d;
}
export function getNextMonth() {
  const time = new Date()
  const curMonth = time.getMonth() + 1
  if (curMonth + 1 === 13) {
    return 1
  }
  return curMonth + 1
}
export function getNextMonthWithCurday() {
  const time = new Date()
  // 获取下个月的总天数
  const nextMonthDays = getMonthDays(time.getFullYear(), time.getMonth() + 2)
  const curY = time.getFullYear()
  const curM = time.getMonth() + 1
  const nextM = getNextMonth()
  // 获取当前日期
  const curDate = time.getDate()
  // 默认下个月日期于当前对应
  let nextDate = curDate
  // 如果下个月的总天数比当前天数少 nextMonthDays: 23 curMonthDays: 31
  if (curDate > nextMonthDays) {
    nextDate -= curDate - nextMonthDays
    // nextDate -= 1;
  }
  return [`${curY}-${curM}-${curDate}`, `${curY}-${nextM}-${nextDate}`]
}
