/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-03 15:14:08
 * @LastEditTime: 2023-02-16 10:00:03
 * @FilePath: /preselectedweddingdress/src/components/superImage/index.tsx
 * @Description  :
 */

import defaultLodingImg from '@assets/img/common/defaultLoding.jpg'
import { useCommonDispatch } from '@hooks/commonDispatch'
import { State } from '@reducers/modules/common'
import { cloneElement, CSSProperties, FC, forwardRef, ReactElement, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import './index.scss'
import { whetherToBbbreviate } from './utils'

export type Props = {
  src: string
  h?: number
  w?: number
  style?: CSSProperties
  className?: string
  showMaskLayoutComponent?: boolean
  maskLayoutComponent?: ReactElement
  onProxyClick?: () => void
  onProxyMouseOut?: () => void
  onProxyMouseOver?: () => void
  openMagnifier?: boolean
  disableAbbreviation?: boolean // 默认false
  imgWithMask?: boolean
  rtCpm?: ReactElement
  ltCpm?: ReactElement
  ltCpmStyle?: CSSProperties
  onImgChange?: (forwardRef: SuperImageRef) => void
  isWrapperImgDiv?: boolean
  isWrapperImgDivInnerCpm?: ReactElement
  origin?: boolean
  useH?: boolean
}
export type SuperImageRef = {
  img: { h: number | null; w: number | null }
  box: { h: number | null; w: number | null }
}
const SuperImage = forwardRef<SuperImageRef, Props>(
  (
    { useH, origin, isWrapperImgDivInnerCpm, isWrapperImgDiv, ltCpmStyle, ltCpm, imgWithMask, disableAbbreviation, openMagnifier, onImgChange, h, w, className, src, showMaskLayoutComponent, maskLayoutComponent, style, onProxyClick, onProxyMouseOver, onProxyMouseOut, rtCpm },
    ref
  ) => {
    // origin=true
    const originalImg= localStorage.getItem('originalImg')
    if(originalImg&&originalImg=='1'){
      origin=true
    }
    const imgInstance = useRef<HTMLImageElement | null>()
    const container = useRef<null | HTMLDivElement>()

    useImperativeHandle(ref, () => forwardRef())
    const forwardRef = () => {
      return {
        img: { h: imgInstance.current?.scrollHeight || 0, w: imgInstance.current?.scrollWidth || 0 },
        box: { h: container.current?.scrollHeight || 0, w: container.current?.scrollWidth || 0 },
      }
    }

    const [_, commonDispatch] = useCommonDispatch()
    const [testImg, setTestImage] = useState<null | string>(null)
    const localhost = useSelector((state: { Common: State }) => {
      return state.Common.localhost
    })
    const MaskComponentStyle: CSSProperties = !showMaskLayoutComponent ? { transform: 'scale(0)' } : { transform: 'scale(1)' }
    const MaskComponent = maskLayoutComponent && cloneElement(maskLayoutComponent, { style: MaskComponentStyle })

    useEffect(() => {
      whetherToBbbreviate({
        src,
        localhost,
        h,
        w,
        useH,
        success(URL) {
          setTestImage(URL)
          onImgChange?.(forwardRef())
        },
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, localhost, h, w, commonDispatch])
    useEffect(() => {
      return () => {
        // setTestImage(defaultLodingImg)
      }
    }, [])

    return (
      <div
        ref={e => (container.current = e)}
        className={`superImage ${className}`}
        style={{ ...style }}
        onMouseOut={e => {
          onProxyMouseOut?.()
        }}
        onMouseOver={e => {
          onProxyMouseOver?.()
        }}
        onClick={e => {
          e.stopPropagation()
          onProxyClick?.()
        }}>
        <div style={ltCpmStyle} className='leftTop'>
          {ltCpm}
        </div>
        <div className='rightTop'>{rtCpm}</div>
        {/* {openMagnifier ? (
        <Magnifier mgWidth={300} mgHeight={300} src={testImg || defaultLodingImg} width={'100%'} zoomImgSrc={disableAbbreviation ? src : testImg || defaultLodingImg} />
      ) : ( */}
        {
          isWrapperImgDiv ? <>
            <div className='isWrapperImgDiv' style={{ position: 'relative' }}>
              {isWrapperImgDivInnerCpm}
              <img ref={ref => (imgInstance.current = ref)} className='superImageimg' src={origin ? src||defaultLodingImg : testImg || defaultLodingImg} alt={'superImage'} />
            </div>
          </> : <>
            <img ref={ref => (imgInstance.current = ref)} className='superImageimg' src={origin ? src||defaultLodingImg : testImg || defaultLodingImg} alt={'superImage'} />
          </>
        }

        {MaskComponent}
      </div>
    )
  }
)

export default SuperImage
