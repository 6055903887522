/*
 * @Author: 邱狮杰
 * @Date: 2022-10-09 09:50:30
 * @LastEditTime: 2022-11-14 15:58:37
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/utils/connectionPlugin.ts
 */
const connectionPluginList = [];
export function getConnectionPluginList() {
    return connectionPluginList;
}
export function connectionPlug(interceptorItem) {
    connectionPluginList.push(...interceptorItem);
    console.log('connectionPlug connectin...', connectionPluginList);
}
