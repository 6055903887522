/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-30 10:30:20
 * @LastEditTime : 2021-07-30 10:31:01
 * @FilePath     : /preselectedyarn/src/utils/action.ts
 * @Description  :
 */
import type { Action } from './createReducer'

export function defaultAction<T extends object>(
	action: Action["type"],
	params?: T
): (Action & T) | Action {
	return {
		type: action,
		...params,
	};
}

export function createAction<T extends object>(
	action: Action["type"],
	fn: (
		actionFn: (action: Action["type"], params?: T) => (Action & T) | Action
	) => (action: Action["type"]) => (Action & T) | Action
) {
	return fn(defaultAction)(action);
}