import { FC } from 'react'
import {
  // Switch, message,
  Button,
} from 'antd'
import '../index.scss'
// import { useSearchDispatch } from '@hooks/searchDispatch'
// import { useCustomer } from '@hooks/customerDispatch'
interface Props {
  onReset: () => void
  onOk: () => void
  onSwitchChange?: (shield: number) => void
}

export const ModalFooter: FC<Props> = ({ onOk, onReset, onSwitchChange }) => {
  // const [search, searchDispatch] = useSearchDispatch()
  // const [customer] = useCustomer()
  return (
    <div className='modalFooter'>
      <div>
        {/* <Switch
          checkedChildren='有档期'
          unCheckedChildren='全部'
          checked={search.Search.shield === 0 ? false : true}
          onClick={() => {
            message.destroy()
            if (!customer.HeaderCustomer.curUser.caseId) {
              message.warn('请选择一个客户才可使用')
              return
            }
            searchDispatch.reverseSchedule()
          }}
          onChange={() => onSwitchChange?.(search.Search.shield)}
        /> */}
      </div>
      <div>
        <Button
          type='link'
          style={{
            color: '#f1f1f1',
          }}
          onClick={onReset}>
          重置
        </Button>
        &nbsp;
        <Button
          type='link'
          style={{
            color: '#f1f1f1',
          }}
          onClick={onOk}>
          确定
        </Button>
      </div>
    </div>
  )
}
