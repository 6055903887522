/*
 * @Author: 邱狮杰
 * @Date: 2021-10-21 09:44:49
 * @LastEditTime: 2023-02-16 09:58:54
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/components/superImage/utils.ts
 */
import { Common } from '@reducers/modules/common'
import { addQuery, aliThumbnailSize, fileExtension, replaceSuffix, thumbnailSize } from '@utils/fileExtension'
import { tryReadIsImage } from '@utils/readImages'

/**
 * @description 判断是否替换src地址(缩略图)
 *
 */
interface whetherToBbbreviateProps {
  src: string
  localhost?: string | null
  h?: number
  w?: number
  success?: (URL: string) => void
  useH?: boolean
}
export function whetherToBbbreviate({ useH, src, localhost, h, w, success }: whetherToBbbreviateProps) {
  requestAnimationFrame(async () => {
    if (!src) return
    const common = Common.getCurState()
    // 1920
    const addCDNSrc = src?.replace('super-bridal.oss-cn-beijing.aliyuncs.com', localhost as string)
    // const addCDNSrc = src?.replace('super-bridal.oss-cn-beijing.aliyuncs.com', localhost || 'super-bridal.oss-cn-beijing.aliyuncs.com')
    const getSuffix = fileExtension(src)
    // 本地缩略图
    const localThumbnail = replaceSuffix(addCDNSrc, getSuffix, thumbnailSize(getSuffix, h || 100, w || 100)).replace('https', 'http')
    // 阿里缩略图
    const aliSrc = src.replace('super-bridal.oss-cn-beijing.aliyuncs.com', 'gtimg.bridal.work').replace('https', 'http')
    const aliThumbnail = addQuery(aliSrc, aliThumbnailSize(h || 100, useH))
    
    const realImg = localhost ? localThumbnail : aliThumbnail
    const [URL, J] = await tryReadIsImage(realImg, aliThumbnail, common.localhostImg)
    if (J) {
      // realImg加载失败
      common.localhostImg !== true && Common.dispatcher('editLocalhostImgStatus', { localhostImg: true })
    } else {
      // localhost有
      if (localhost) {
        common.localhostImg !== false && Common.dispatcher('editLocalhostImgStatus', { localhostImg: false })
      }
      if (!localhost) {
        common.localhostImg !== true && Common.dispatcher('editLocalhostImgStatus', { localhostImg: true })
      }
    }
    success?.(URL)
  })
}
