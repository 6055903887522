/*
 * @Author: 邱狮杰
 * @Date: 2022-09-28 11:30:40
 * @LastEditTime: 2022-09-28 11:34:18
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/pay/inversify.config.ts
 */
/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:45:39
 * @LastEditTime: 2022-09-28 00:02:44
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/Pay/inversify.config.ts
 */
import { BuildContainer } from '../../utils/buildContainer';
import { PayCreate } from './create';
import { PayDelete } from './delete';
import { Pay, PayConstant } from './pay';
import { PayRead } from './read';
import { PayUpdate } from './update';
const PayContainer = new BuildContainer(PayConstant).setCreate(PayCreate).setDelete(PayDelete).setRead(PayRead).setUpdate(PayUpdate).getBuildContainer();
PayContainer.bind(PayConstant.createPay).to(Pay);
const getPay = PayContainer.get(PayConstant.createPay);
export { getPay, PayContainer };
