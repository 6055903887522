/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 10:59:11
 * @LastEditTime: 2023-02-09 11:24:01
 * @FilePath: /preselectedweddingdress/src/App.tsx
 * @Description  :
 */

import { AnimatedSwitchComponent } from '@components/index'
import { injectProps as withCommonDispatchInjectProps, withCommonDispatch } from '@hoc/commonDispatchHoc'
import { injectProps, withInitialCollection } from '@hoc/commonInit'
import { injectProps as withFormInitDispatchInjectProps, withFormInitDispatch } from '@hoc/formInitDispatchHoc'
import { injectProps as withHeaderCustomerDispatchInjectProps, withHeaderCustomerDispatch } from '@hoc/headerCustomerDispatch'
import { check } from '@hooks/verificationToken'
import { Header } from '@layout/index'
import { hoc } from 'high-order-react'
import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
// import welcomeBkgLogo from '@assets/img/welcome/welcomeLogo.png'
// import { SynchronizationAwaitError } from "synchronizationawaiterror";
// import api from "@service/index";
import { message } from 'antd'
import './index.scss'

const excludePath = ['/welcome', '/login']

type Props = {} & withFormInitDispatchInjectProps & injectProps & withHeaderCustomerDispatchInjectProps & withCommonDispatchInjectProps & RouteComponentProps

interface State { }
@hoc(withHeaderCustomerDispatch)
@hoc(withFormInitDispatch)
@hoc(withInitialCollection)
@hoc(withCommonDispatch)
class App extends Component<Props, State> {
  async componentDidMount() {
    // 初始化客户列表
    const { userListInitialization, actions, initializeFormInformation, withFormInitDispatchActions } = this.props
    check(
      async () => {
        await userListInitialization?.(
          response => {
            
            actions?.initCustomers(response.result)
          },
          () => message.warning('初始化客户列表出错，请重试', 2.5)
        )

        await initializeFormInformation?.(
          formInit => {
            withFormInitDispatchActions?.Init(formInit?.result)
          },
          () => message.warning('初始化表单选项出错,请重试', 2.5)
        )
      },
      () => { }
    )
  }

  render() {

    const { history } = this.props

    return (
      <>
        <div className='app'>
          <Header style={excludePath.includes(history.location.pathname) ? { height: '0' } : { height: '48px', minHeight: '48px' }} />
          <AnimatedSwitchComponent.Routes />
        </div>
      </>

    )
  }
}

export default withRouter(App)
