import { ApiUnitConfig } from '@type/axios'

export interface goodsCategoryRequestParams {
  appointmentId?: number | null
  caseId?: number | null
  visitId?: number | null
}

export interface goodsCategoryResponse {
  categoryId: number
  categoryImage: string
  title: string
  introduce: string
}
export default [
  {
    method: 'GET',
    name: 'goodsCategory',
    path: '/system/store/tablet/business/goods/category',
    desc: '获取分类',
    params: {
      appointmentId: '',
      caseId: '',
      visitId: '',
    },
  },
] as ApiUnitConfig[]
