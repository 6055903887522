var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:44:02
 * @LastEditTime: 2022-10-25 16:54:58
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/toStore/read.ts
 */
import { provide } from 'inversify-binding-decorators';
import { http } from '../../config';
import { ToStopConstant } from './toStore';
let ToStopRead = class ToStopRead {
    /**
     * @description 获取当前门店接待列表
     *
     */
    readReceptionAccordingToDate(req) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                url: '/system/store/staff/workbench/all/reception',
                params: Object.assign({}, req),
            });
        });
    }
    /**
     * @description 获取当前个人接待列表
     *
     */
    readReceptionAccordingToDateWithMySelf(req) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                url: '/system/store/staff/workbench/myself/reception',
                params: Object.assign({}, req),
            });
        });
    }
    /**
     * @description       0: '未到店',
        1: '已爽约',
        2: '已改约',
        3: '已到店',
        4: '新订单',
        5: '未成交',
        6: '未试妆',
        7: '试妆成功',
        8: '试妆失败',
        9: '诚意金', 10: '试纱费', 11: '二销', 12: '尾款'
     * @param item
     * @returns
     */
    fmtReadReceptionAccordingToDate(item) {
        const h = {
            0: '未到店',
            1: '已爽约',
            2: '已改约',
            3: '已到店',
            4: '新订单',
            5: '未成交',
            6: '未试妆',
            7: '试妆成功',
            8: '试妆失败',
            9: '诚意金', 10: '试纱费', 11: '二销', 12: '尾款'
        };
        return h[item];
    }
    /**
     * @description 根据日期获取跟进列表
     * @returns
     */
    getListAccordingDate(req) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                url: '/system/store/staff/workbench/myself/followUp',
                params: Object.assign({}, req),
            });
        });
    }
    /**
     * @description 到店列表
     * @param req
     * @returns
     */
    getListOfStores(req) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                url: '/system/store/client/business/visit/card',
                params: Object.assign({}, req),
            });
        });
    }
};
ToStopRead = __decorate([
    provide(ToStopConstant.createRead)
], ToStopRead);
export { ToStopRead };
