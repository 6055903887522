/*
 * @Author: 邱狮杰
 * @Date: 2022-10-12 11:45:33
 * @LastEditTime: 2022-10-12 11:47:03
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/other/inversify.config.ts
 */
import { BuildContainer } from '../../utils/buildContainer';
import { OtherCreate } from './create';
import { OtherDelete } from './delete';
import { Other, otherConstant } from './other';
import { OtherRead } from './read';
import { OtherUpdate } from './update';
const otherContainer = new BuildContainer(otherConstant)
    .setCreate(OtherCreate)
    .setDelete(OtherDelete)
    .setRead(OtherRead)
    .setUpdate(OtherUpdate)
    .getBuildContainer();
otherContainer.bind(otherConstant.createOther).to(Other);
const getOther = otherContainer.get(otherConstant.createOther);
export { getOther, otherContainer };
