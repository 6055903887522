/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-03 11:00:36
 * @LastEditTime : 2021-08-06 16:57:44
 * @FilePath     : /preselectedyarn/src/actions/headerCustomer.ts
 * @Description  :
 */

import { defaultAction } from '@utils/action'
import { userListResponse } from '@type/api/module/common'
import { RESET_CUSTOMERS, INIT_CUSTOMERS, NEW_CUSTOMERS, SWITCH_CUSTOMERS, headerCustomer, CLEAR_CUSTOMERS } from '@constants/headerCustomer'

export function clearCustomers() {
  return defaultAction<headerCustomer>(CLEAR_CUSTOMERS, {})
}
export function addCustomers(params: headerCustomer) {
  return defaultAction<headerCustomer>(NEW_CUSTOMERS, params)
}

export function initCustomers(init: userListResponse[]) {
  return defaultAction<headerCustomer>(INIT_CUSTOMERS, {
    init: init,
  })
}

export function switchCustomers(userName: string) {
  return defaultAction<headerCustomer>(SWITCH_CUSTOMERS, {
    switchClientUser: userName,
  })
}
export function resetCustomers() {
  return defaultAction<headerCustomer>(RESET_CUSTOMERS, {})
}
