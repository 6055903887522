import { useState } from 'react'

export function useCustomerType() {
  return useState<{ title: string; id: number }[]>([
    { title: '婚礼', id: 1 },
    { title: '生日', id: 2 },
    { title: '年会', id: 3 },
    { title: '表演', id: 4 },
    { title: '其他', id: 5 },
  ])
}
export function useIntentionLevel() {
  return useState<{ title: string; id: number }[]>([
    { title: '弱', id: 0 },
    { title: '一般', id: 1 },
    { title: '较强', id: 2 },
    { title: '强', id: 3 },
  ])
}
