var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:43:53
 * @LastEditTime: 2022-10-11 15:51:53
 * @Description:
 * @FilePath: /superbridal-repo/packages/service/src/api/makeUp/create.ts
 */
// import { injectable } from 'inversify'
import { provide } from 'inversify-binding-decorators';
import { http } from '../../config';
import { makeUpConstant } from './makeUp';
// @injectable()
let MakeUpCreate = class MakeUpCreate {
    getDresserPeopleList() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'GET',
                url: "/system/store/dresser/enable/list",
            });
        });
    }
    getDresserList(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                method: 'GET',
                url: "/system/store/client/business/manage/dresser/schedule",
                params: params
            });
        });
    }
};
MakeUpCreate = __decorate([
    provide(makeUpConstant.createCreate)
], MakeUpCreate);
export { MakeUpCreate };
