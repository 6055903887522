/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-04 11:23:36
 * @LastEditTime: 2022-11-02 11:46:53
 * @FilePath: /preselectedweddingdress/src/reducers/modules/yarnSelectionListStatus.ts
 * @Description  : 选纱列表的所有状态 已选 编辑...
 */
import {
  DELETETION, INITIALIZATION_TRIED, INIT_SELECTED_LIST, INVERSION_OF_MASK, LIKE_RESET, MARK_DELETETION, MODIFY_CURRENT_STATUS, SELECT_IF_THE_ID_ISEQUAL, STYLE_LIST_INIT, yarnSelectionListStatusActionTypes
} from '@constants/yarnSelectionListStatus'
import { CreateReducer } from '@zealforchange/conciseredux'

import { Action } from 'redux'

export interface styleListItem {
  id: number // id
  src: string // 款式图片
  like: boolean // 是否喜欢 true
  serial: string // 序列号
  categoryId: number
  withImgTagList?: { img: string; content: string }[] // TODO: 没有这个字段 带图标的tag
  tagList?: { name: string }[] // 文字tag
  showMask?: boolean // 是否开始遮罩
  checkbox?: boolean // 只有在deletetion状态下才会操作该状态
  nameCn?: string // server返回的参数 app不需要保存 只读
  triedList?: { id: number; photoAt: null | string; photoName: null | string; photoPath: string; tryonId: number }[] // 已试 list 只有在已试 状态下才会有值
  goodsId?: number // goodsId 只会在预览图Y轴情况下才会用到
  scanCode?: string
}

export type curStateTypes = 'Unselected' | 'selected' | 'deletetion' | 'triedList'

export interface State {
  styleList: styleListItem[] // 款式列表
  selectedYarn: styleListItem[] // 已选纱列表
  tried: styleListItem[] // 已试列表
  curState: curStateTypes // 当前状态 参照action
}

function reverse(key: keyof styleListItem, index: number, itemList: State['styleList']): State['styleList'] {
  const newItemList = itemList.map((item, itemIndex) => {
    if (itemIndex === index && typeof Reflect.get(item, key) === 'boolean') {
      // @ts-ignore
      item[key] = !item[key]
      return item
    }
    return item
  })
  return newItemList
}
type DispatchTypes =
  | typeof INITIALIZATION_TRIED
  | typeof STYLE_LIST_INIT
  | typeof LIKE_RESET
  | typeof INVERSION_OF_MASK
  | typeof MODIFY_CURRENT_STATUS
  | typeof DELETETION
  | typeof MARK_DELETETION
  | typeof INIT_SELECTED_LIST
  | typeof SELECT_IF_THE_ID_ISEQUAL
const YarnSelectionListStatus = new CreateReducer<State, Action & yarnSelectionListStatusActionTypes, DispatchTypes>({
  styleList: [],
  curState: 'Unselected',
  selectedYarn: [],
  tried: [],
})
  .addAction(INITIALIZATION_TRIED, (state, action) => {
    return { ...state, tried: action.initStyleList || [] }
  })
  .addAction(STYLE_LIST_INIT, (state, action) => {
    return { ...state, styleList: action.initStyleList || [] }
  })
  .addAction(LIKE_RESET, (state, action) => {
    const newList = reverse('like', action.index || 0, state.styleList)
    return { ...state, styleList: newList }
  })
  .addAction(INVERSION_OF_MASK, (state, action) => {
    const newList = reverse('showMask', action.index || 0, state.styleList)
    return { ...state, styleList: newList }
  })
  .addAction(MODIFY_CURRENT_STATUS, (state, action) => {
    return { ...state, curState: action.curState || 'Unselected' }
  })
  .addAction(DELETETION, state => {
    const selectYarnResult = state.selectedYarn.filter(listItem => !listItem.checkbox)
    const removeId = state.selectedYarn.filter(item => item.checkbox).map(ids => ids.id)
    const newStyleList = state.styleList.map(item => {
      if (removeId.includes(item.id)) {
        item.like = false
      }
      return item
    })
    return {
      ...state,
      styleList: newStyleList,
      selectedYarn: selectYarnResult,
    }
  })
  .addAction(MARK_DELETETION, (state, action) => {
    return {
      ...state,
      selectedYarn: state.selectedYarn.map(listItem => {
        if (listItem.id === action.id) {
          if (listItem.checkbox) {
            listItem.checkbox = false
          } else {
            listItem.checkbox = true
          }
        }
        return listItem
      }),
    }
  })
  .addAction(INIT_SELECTED_LIST, (state, action) => {
    // 初始化已选纱列表
    return { ...state, selectedYarn: action.initStyleList || [] }
  })
  .addAction(SELECT_IF_THE_ID_ISEQUAL, (state, action) => {
    const curStyleItem = state.styleList.find(item => item.id === action.id)
    if (curStyleItem) curStyleItem.like = true
    return { ...state }
  })

export { YarnSelectionListStatus }
