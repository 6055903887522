/*
 * @Author: 邱狮杰
 * @Date: 2021-08-15 10:56:31
 * @LastEditTime: 2022-11-04 23:01:10
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/hoc/headerGoodsDispatchHoc.tsx
 */

import * as actions from '@actions/headerGoods'
import { HeaderGoods } from '@constants/headerGoods'
import type { State } from '@reducers/modules/headerGoods'
import { Action } from '@utils/createReducer'
import { createReducerProcessingHoc } from '@zealforchange/redux-encapsulation'
import { ComponentType } from 'react'
import { bindActionCreators, Dispatch } from 'redux'

// 需要向组件注入的props 必须是可选的
export interface injectProps {
  headerGoodsList?: State['goodsList']
  withHeaderGoodsDispatchActions?: typeof actions
}

/**
 *
 * @param state 是你每一个reducer的默认值，对象类型 key 是 combineReducers 的 key , value 是 key对应reducer的默认值 , 每一个reducer都必须要要有默认值
 * @see @reducers/index.ts combineReducers函数在这个路径
 *
 */
function mapStateToProps(state: { HeaderGoodsReducer: State }) {
  return {
    headerGoodsList: state.HeaderGoodsReducer.goodsList,
  }
}

/**
 *
 * @param dispatch 触发更新函数
 * bindActionCreators 传入action自动绑定dispatch
 */
function mapDispatchToProps(dispatch: Dispatch<Action & HeaderGoods>) {
  return {
    withHeaderGoodsDispatchActions: bindActionCreators(actions, dispatch),
  }
}

export const withHeaderGoodsDispatch = <BaseProps extends injectProps>(BaseComponent: ComponentType<BaseProps>) => {
  return createReducerProcessingHoc('withHeaderGoodsDispatch', BaseComponent, mapStateToProps, mapDispatchToProps)
}
