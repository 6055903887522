// 缩略图

// 主页图
export const home = { h: 1500, w: 2550 }

// 分类图
export const classification = { h: 830, w: 1688 }

// 商品图
export const goods = { h: 620, w: 410 }

// 预览大图
// export const previewBigPicture = { h: 750, w: 800 }
export const previewBigPicture = { h: 1024, w: 1024 }
