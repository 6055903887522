/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { search } from 'youwilllike'
import { message, Modal } from 'antd'
import { useGoodsStyleDispatch } from '@hooks/goodsStyleDispatch'
import { useTag, useQueryStyle, useGetStyle, useGradeList, useQueryGradeList, tagParameterAdjustment } from './hooks'
import { Search } from '@utils/search'
import { useCustomer } from '@hooks/customerDispatch'
import { StyleListContext, ModalFooter } from './components'
import './index.scss'
import { styleListItem } from '@reducers/modules/yarnSelectionListStatus'
import { Search as SearchState, State } from '@reducers/modules/search'
import { useSearchDispatch } from '@hooks/searchDispatch'

export interface searchTypes {
  style: string
  categoryId: number
  endPrice: number
  startPrice: number
  tags: any // 提交时转字符串
  gradeIds: number[]
  sort: 'DESC' | 'ASC'
  num: number // options number
  edate: string
  sdate: string
  shield: number
}

type Props = {
  visible: boolean
  onClose: () => void
  onOK: (res: searchTypes, weddingList: styleListItem[], total: number) => void
  onStartSearch: (res: searchTypes) => void
  onFailSearch: (res: searchTypes) => void
  onActiveCount?: (count: number) => void
  onReset: () => void
}
export type PropsRef = {
  search: () => void
  unlimitedSearch: () => () => void
}
export const searchContext = new search.SearchHandler<searchTypes>()
let SearchContent = forwardRef<PropsRef, Props>(({ onReset, onActiveCount, visible, onClose, onOK, onStartSearch, onFailSearch }, ref) => {
  useImperativeHandle(ref, () => {
    return {
      search() {
        Ok()
      },
      unlimitedSearch(): () => void {
        return searchHandler
      },
    }
  })
  const [customer] = useCustomer()
  const [search, searchDispatch] = useSearchDispatch()
  const [gradeList, setGradeList, resetGradeList] = useGradeList()
  const [list, setList] = useGetStyle()
  const [queryHandler, tagList, setTagList, _tagListActiveNum, resetTagList] = useQueryStyle(searchContext, setList)
  const [tagHandler, addToParameterList] = useTag(searchContext, setTagList)
  const [addGradeLParamsHandler, addToParameterListWithQueryGradeList] = useQueryGradeList(searchContext, setGradeList)
  const [goodsList, goodsListDispatch] = useGoodsStyleDispatch()
  const searchWhenClosed = useRef<boolean>(false)

  useEffect(() => {
    queryHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const context = searchContext.returnParams<searchTypes>()
    let num = 0
    // let gradeListActive = gradeList?.list?.filter(gradeItem => gradeItem.active) || []
    const gradeListActive = context.gradeIds || []
    const tagListActiveNum = context.tags?.length || 0
    num += gradeListActive.length
    num += tagListActiveNum
    // if (search.Search.shield === 1) num += 1
    // have a or more options
    if (num) searchWhenClosed.current = true
    else searchWhenClosed.current = false
    // if (searchContext.returnParams<{ edate: string | undefined }>().edate) num += 1
    searchDispatch.setCount(num)
    onActiveCount?.(num)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, gradeList, search.Search.shield, tagList, searchContext.returnParams<{ edate: string | undefined }>()])

  async function Ok() {
    addToParameterList()
    addToParameterListWithQueryGradeList()
    if (noPreference()) {
      onClose()
      return
    }
    await searchHandler()
  }

  async function searchHandler() {
    const curActiveGoodsStyle = goodsList.HeaderGoodsReducer.goodsList.filter(item => item.active)
    const searchHttp = new Search()
    const context = searchContext.returnParams<searchTypes>()
    onStartSearch(context)
    const search = SearchState.getCurState() as State
    const result = await searchHttp.send({
      ...context,
      caseId: customer?.HeaderCustomer?.curUser.caseId || null,
      tags: tagParameterAdjustment(context?.tags) || null,
      gradeIds: context?.gradeIds || null,
      pageNum: 1,
      pageSize: 10,
      shield: search.sdate && search.edate ? 1 : 0,
      categoryId: curActiveGoodsStyle?.[0].id || 0,
      edate: context.edate,
      sdate: context.sdate,
    })
    if (result?.code !== 200) {
      message.destroy()
      message.warn(result?.msg)
      onFailSearch(context)
      return
    }
    onOK(context, searchHttp.formatToWeddingList(result.result), result.result.total)
    goodsListDispatch.choseGoods(curActiveGoodsStyle?.[0].id || 0)
    close()
  }
  /**
   * @description 选项是否都为空
   * @returns
   */
  function noPreference() {
    const context = searchContext.returnParams<searchTypes>()
    const result = !context?.gradeIds && !context?.gradeIds?.length && !context?.tags && !context?.tags?.length && !search.Search.count && !context.edate && !context.sdate
    return result
  }

  function close() {
    onClose()
  }

  function reset() {
    // 没有选择
    if (noPreference()) {
      close()
      return
    }
    resetGradeList()
    resetTagList()
    searchContext.resetField()
    searchDispatch.reset()
    close()
    onReset()
  }

  async function cannel() {
    // 没有选项
    if (noPreference()) {
      close()
      return
    }
    // await searchHandler()
    // 有选项，进行搜索
    close()
  }

  return (
    <Modal
      onCancel={cannel}
      style={{ position: 'absolute', left: '0.694vw', top: 'auto', bottom: '1.6rem' }}
      maskClosable
      title='筛选'
      visible={visible}
      onOk={Ok}
      cancelText='重置'
      footer={<ModalFooter.ModalFooter onOk={Ok} onReset={reset} />}
      cancelButtonProps={{ style: {}, type: 'primary' }}
    >
      <StyleListContext.StyleList multiple={true} list={gradeList} onClick={addGradeLParamsHandler} />
      {tagList.map((item, itemIndex) => {
        return <StyleListContext.StyleList key={itemIndex} multiple={true} onClick={tagHandler} list={item} />
      })}
    </Modal>
  )
})
export { SearchContent }
