import { Dispatch, SetStateAction } from 'react'
import { StyleListContext } from '../components'
import { useIdDeduplication } from '@hooks/useIdDeduplication'
import { search } from 'youwilllike'
import { searchTypes } from '../searchContent'
import { Search, State } from '@reducers/modules/search'
import { useSearchDispatch } from '@hooks/searchDispatch'
import { tagsType } from '@hooks/useIdDeduplication'

export function tagParameterAdjustment(ids: tagsType[] | undefined) {
  const searchState = Search.getCurState() as State
  const filterMap = new Map<number, number[]>()
  searchState.tagList
    ?.map(listItem => {
      return listItem.list
        .filter(item => {
          if (!ids) return false
          const findParent = ids.find(findParentId => findParentId.parentId === item.parentId)
          if (!findParent) return false
          const findId = ids.find(findItem => findItem.id === item.id)
          if (!findId) return false
          return true
        })
        .map(item => {
          return { filterId: listItem.id, tags: item.id }
        })
    })
    .filter(nextItem => {
      return nextItem.length
    })
    .forEach(item => {
      item.forEach(i => {
        if (filterMap.has(i.filterId as number)) {
          const tags = filterMap.get(i.filterId as number) as number[]
          tags.push(i.tags)
          filterMap.set(i.filterId as number, tags)
        } else filterMap.set(i.filterId as number, [i.tags])
      })
    })
  const result: { filterId: number; tags: number[] }[] = []
  filterMap.forEach((mapItem, key) => {
    result.push({
      filterId: key,
      tags: mapItem,
    })
  })
  return result
}
export function useTag(
  searchContext: search.SearchHandler<searchTypes>,
  dispatch: Dispatch<SetStateAction<StyleListContext.List[]>>
): [(item: StyleListContext.ListItem, list: StyleListContext.ListItem[], title: string) => void, () => void] {
  const [idList, setIdList] = useIdDeduplication((Search.getCurState() as State).tags)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchContent, searchDispatch] = useSearchDispatch()
  const tagHander = (item: StyleListContext.ListItem, list: StyleListContext.ListItem[], title: string) => {
    dispatch(List => {
      const newList = List.map(item => {
        if (item.title === title) return { ...item, list }
        return item
      })
      return [...newList]
    })
    setIdList({ id: item.id, parentId: item.parentId || 0 })
    // const context = searchContext.returnParams<searchTypes>()
    // if (!searchContext.hasField('tags')) {
    //   searchContext.addNewField('tags', [item.id])
    // } else {
    //   if (!context.tags?.includes(item.id)) {
    //     searchContext.rewriteField('tags', [...context.tags, item.id])
    //     searchDispatch.setOption(context)
    //     return
    //   }
    //   searchContext.rewriteField('tags', [...context.tags.filter(tagItem => tagItem !== item.id)])
    // }
    // searchDispatch.setOption(context)
  }

  function addToParameterList() {
    const context = searchContext.returnParams<searchTypes>()
    if (!searchContext.hasField('tags')) searchContext.addNewField('tags', idList.current)
    else searchContext.rewriteField('tags', idList.current)
    searchDispatch.setOption(context)
  }

  return [tagHander, addToParameterList]
}
