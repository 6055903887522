import { BuildContainer } from '../../utils/buildContainer';
import { StaffCreate } from './create';
import { StaffDelete } from './delete';
import { Staff, StaffConstant } from './staff';
import { StaffRead } from './read';
import { StaffUpdate } from './update';
const StaffContainer = new BuildContainer(StaffConstant).setCreate(StaffCreate).setDelete(StaffDelete).setRead(StaffRead).setUpdate(StaffUpdate).getBuildContainer();
StaffContainer.bind(StaffConstant.createStaff).to(Staff);
const getStaff = StaffContainer.get(StaffConstant.createStaff);
export { getStaff, StaffContainer };
