import { defaultAction } from '@utils/action'

import { FORM_INIT, FORM_INIT_CUSTOMER_CHANNEL } from '@constants/formInit'
import type { formInit } from '@constants/formInit'

export function Init(init: formInit['init']) {
  return defaultAction<formInit>(FORM_INIT, { init })
}

export function InitCustomerChannel(init: formInit['initCustomerChannel']) {
  return defaultAction<formInit>(FORM_INIT_CUSTOMER_CHANNEL, { initCustomerChannel: init })
}
