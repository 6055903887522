/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:44:36
 * @LastEditTime: 2022-09-30 11:35:29
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/toStore/toStore.ts
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject, injectable } from 'inversify';
import { CreateCrudVariable } from '../../utils/createCrudVariable';
export const ToStopConstant = new CreateCrudVariable().setUpThemes('ToStop').getTopic();
/**
 * 化妆 礼服师
 */
let ToStop = class ToStop {
    constructor(create, deletes, update, read) {
        this.create = create;
        this.update = update;
        this.read = read;
        this.delete = deletes;
    }
    getCreateTrigger() {
        return this.create;
    }
    getUpdateTrigger() {
        return this.update;
    }
    getDeleteTrigger() {
        return this.delete;
    }
    getReadTrigger() {
        return this.read;
    }
};
ToStop = __decorate([
    injectable(),
    __param(0, inject(ToStopConstant.createCreate)),
    __param(1, inject(ToStopConstant.createDelete)),
    __param(2, inject(ToStopConstant.createUpdate)),
    __param(3, inject(ToStopConstant.createRead)),
    __metadata("design:paramtypes", [Object, Object, Object, Object])
], ToStop);
export { ToStop };
