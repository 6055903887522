/* eslint-disable import/no-anonymous-default-export */
/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 11:31:59
 * @LastEditTime : 2021-08-13 11:42:21
 * @FilePath     : /preselectedyarn/src/service/api/index.ts
 * @Description  :
 */


// @ts-ignore
const files = require.context('./modules', false, /\.ts$/)
const modules = {}
// @ts-ignore
files.keys().forEach(key => {
	// @ts-ignore
	// eslint-disable-next-line
	modules[key.replace(/(\.\/|\.ts)/g, '')] = files(key).default
})

export default {
	...modules
}
