import { Action } from 'redux'
import { CreateReducer } from '@zealforchange/conciseredux'
import { CommonTypes, EDIT_LOCALHOST, EDIT_LOCALHOSTIMG, EDIT_WELCOMEINFO } from '@constants/common'
import HeaderLogo from '@assets/img/common/headerLogo.png'
import { EDIT_LOGO } from '@constants/common'
import { welcomeInitTypes } from '@type/api/module/welcome'



export interface State {
  logo?: string
  localhost?: string | null
  localhostImg?: boolean | null // 是否使用阿里缩略图 true === 阿里 false === localhost
  welcomeinfo?: welcomeInitTypes
}
type DispatchTypes = typeof EDIT_LOGO | typeof EDIT_LOCALHOST | typeof EDIT_LOCALHOSTIMG | typeof EDIT_WELCOMEINFO
export const Common = new CreateReducer<State, Action & CommonTypes, DispatchTypes>({
  logo: '',
  localhost: null,
  localhostImg: false,
  // @ts-ignore
  welcomeinfo: {},
})
  .addAction(EDIT_LOGO, (state, action) => {
    return { ...state, logo: action.logo || HeaderLogo }
  })
  .addAction(EDIT_LOCALHOST, (state, actions) => {
    return { ...state, localhost: actions.localhost }
  })
  .addAction(EDIT_LOCALHOSTIMG, (state, action) => {
    return { ...state, localhostImg: action.localhostImg }
  })
  .addAction(EDIT_WELCOMEINFO, (state, action) => {
    return { ...state, welcomeinfo: action.welcomeInfo }
  })
  .setReducerKey('Common')
