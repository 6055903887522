var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConnectionPluginList } from '../../utils/connectionPlugin';
export class JoinPlugin {
    requestFailInterceptor(err) {
        return;
    }
    responseFailInterceptor(err) {
        return;
    }
    requestSuccessInterceptor(config) {
        let o = Object.assign({}, config);
        getConnectionPluginList().map((n) => __awaiter(this, void 0, void 0, function* () {
            const r = yield n.requestSuccessInterceptor(o);
            o = Object.assign({}, o, r);
        }));
        return o;
    }
    responseSuccessInterceptor(response) {
        let o = Object.assign({}, response);
        getConnectionPluginList().map((n) => __awaiter(this, void 0, void 0, function* () {
            const r = yield n.responseSuccessInterceptor(o);
            o = Object.assign({}, o, r);
        }));
        return o;
    }
}
