import { ComponentType } from 'react'
import * as actions from '@actions/headerCustomer'
import { headerCustomer } from '@constants/headerCustomer'
import { createReducerProcessingHoc } from '@zealforchange/redux-encapsulation'
import { Action, Dispatch, bindActionCreators } from 'redux'
import { State } from '@reducers/modules/headerCustomer'

export interface injectProps {
  HeaderCustomer?: State
  whetherToChooseACustomer?: boolean
  selectedCustomer?: <T>(success?: () => T | null, fail?: () => T | null) => T
  actions?: typeof actions
}

function mapStateToProps(state: { HeaderCustomer: State }) {
  return {
    HeaderCustomer: state.HeaderCustomer,
    whetherToChooseACustomer: state.HeaderCustomer.curUser.caseId !== null, // 不等于null则是选中客户
    selectedCustomer: function <T>(success?: () => T | null, fail?: () => T | null): T {
      if (state.HeaderCustomer.curUser.caseId !== null) return success?.() as T
      return fail?.() as T
    },
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action & headerCustomer>) {
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export const withHeaderCustomerDispatch = <T extends object>(C: ComponentType<T>) => {
  return createReducerProcessingHoc('withHeaderCustomerDispatch', C, mapStateToProps, mapDispatchToProps)
}
