var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/*
 * @Author: 邱狮杰
 * @Date: 2022-10-12 11:41:05
 * @LastEditTime: 2022-10-12 11:43:01
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/other/create.ts
 */
import { provide } from 'inversify-binding-decorators';
import { otherConstant } from './other';
let OtherCreate = class OtherCreate {
    init() {
        console.log('init');
    }
};
OtherCreate = __decorate([
    provide(otherConstant.createCreate)
], OtherCreate);
export { OtherCreate };
