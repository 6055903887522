/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 17:23:16
 * @LastEditTime : 2021-08-09 17:42:09
 * @FilePath     : /preselectedyarn/src/reducers/modules/headerButton.tsx
 * @Description  :
 */
import { HEADER_RIGHT_BUTTON_OPERATION_EDIT, HEADER_RIGHT_BUTTON_OPERATION_HIDDEN, REPLACE_COMPONENT_STATUS, CURRENT_DISPLAY, HeaderAction } from '@constants/headerButton'
import { Action } from 'redux'
import { CreateReducer } from '@zealforchange/conciseredux'
// import {CreateReducer} from '@utils/createReducer'
import { ReactElement } from 'react'

export interface State {
  component: ReactElement // 当前显示的组件
  unSelected: ReactElement //  未选
  selected: ReactElement // 已选 === 编辑状态
  deletetion: ReactElement // 删除状态
  loginOut: ReactElement // 推出登录按钮
}
type DispatchTypes = typeof HEADER_RIGHT_BUTTON_OPERATION_EDIT | typeof HEADER_RIGHT_BUTTON_OPERATION_HIDDEN | typeof REPLACE_COMPONENT_STATUS | typeof CURRENT_DISPLAY
const HeaderButtonReducer = new CreateReducer<State, HeaderAction & Action, DispatchTypes>({
  component: <></>,
  unSelected: <></>,
  selected: <></>,
  deletetion: <></>,
  loginOut: <></>,
})
  .addAction(HEADER_RIGHT_BUTTON_OPERATION_EDIT, (state, action) => {
    return { ...state, component: action.component || <></> }
  })
  .addAction(HEADER_RIGHT_BUTTON_OPERATION_HIDDEN, (state, action) => {
    return { ...state, component: action.component || <></> }
  })
  .addAction(REPLACE_COMPONENT_STATUS, (state, action) => {
    state[action.replace as keyof State] = action.component || <></>
    return { ...state }
  })
  .addAction(CURRENT_DISPLAY, (state, action) => {
    return {
      ...state,
      component: state[action.curShowComponent as keyof State] || <></>,
    }
  })
// function HeaderButtonReducer(state: State = {
//     component: <></>, unSelected: <></>, selected: <></>, deletetion: <></>
// }, action: Action & HeaderAction) {
//     switch (action.type) {
//         case HEADER_RIGHT_BUTTON_OPERATION_EDIT:
//             return {...state, component: action.component }
//         case HEADER_RIGHT_BUTTON_OPERATION_HIDDEN:
//             return {...state, component: action.component }
//         case REPLACE_COMPONENT_STATUS:
//             state[action.replace as keyof State] = action.component || <></>
//             return {...state}
//         case CURRENT_DISPLAY:
//             return {...state, component: state[action.curShowComponent as keyof State] }
//         default:
//             return state
//     }
// }
export { HeaderButtonReducer }
