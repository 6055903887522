import { message } from 'antd'

export function tipsWarn(text: string) {
  message.destroy()
  message.warn(text, 2.5)
}
export function tipsSuccess(text: string) {
  message.destroy()
  message.success(text, 2.5)
}

export function executionError(err: unknown, text: string) {
  if (err) {
    tipsWarn(text)
    throw new Error(text)
  }
}

export function errorCode(obj: { code?: number; msg?: string } | any) {
  if (!obj?.code) return
  if (obj?.code !== 200) {
    tipsWarn(obj?.msg || '')
    throw new Error(obj.msg)
  }
}
