import { formTreeInitResponse } from '@service/api/modules/common'

export const keySuffix = 'keySuffix'

/**
 * @description 初始化树结构
 * @param info
 * @returns
 */
export function treeData(info: formTreeInitResponse) {
  return (
    info.map(infoItem => {
      return {
        title: infoItem.title,
        value: infoItem.id,
        children: infoItem.channelDtos.map(item => {
          return {
            title: item.title,
            value: `${infoItem.id}-${item.id}`,
            children:
              item.channelPersonDtos.map(i => {
                // value如有重复则渲染的tree会出bug , 所以必须加上后面的字符串
                return { title: i.title, value: `${infoItem.id}-${item.id}-${i.id}` }
              }) || [],
          }
        }),
      }
    }) || []
  )
}
export function existenceIsExecution(judge: unknown, fn: Function, failCb?: Function) {
  if (!judge) {
    failCb?.()
    return
  }
  fn()
}
