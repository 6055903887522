/*
 * @Author: 邱狮杰
 * @Date: 2022-09-27 11:58:04
 * @LastEditTime: 2022-10-21 17:46:33
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/customer/inversify.config.ts
 */
import { BuildContainer } from '../../utils/buildContainer';
import { CustomerCreate } from './create';
import { Customer, customerConstant } from './customer';
import { CustomerDelete } from './delete';
import { CustomerRead } from './read';
import { CustomerUpdate } from './update';
const CustomerContainer = new BuildContainer(customerConstant)
    .setCreate(CustomerCreate)
    .setDelete(CustomerDelete)
    .setRead(CustomerRead)
    .setUpdate(CustomerUpdate)
    .getBuildContainer();
CustomerContainer.bind(customerConstant.createCustomer).to(Customer);
const getCustomer = CustomerContainer.get(customerConstant.createCustomer);
export { getCustomer, CustomerContainer };
