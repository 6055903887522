/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-03 12:58:27
 * @LastEditTime: 2022-11-10 15:56:39
 * @FilePath: /preselectedweddingdress/src/components/form/index.tsx
 * @Description  :
 */

import { injectProps, withFormInitDispatch } from '@hoc/formInitDispatchHoc'
import { ComponentClass, FC, useEffect, useRef, useState } from 'react'
import {
  useCustomerType
} from './hooks/data'
import { usePhoneNumberLookup } from './hooks/phoneNumberLookup'
// import { debounce } from 'lodash'
import { phoneNumberLookupResponse } from '@service/api/modules/common'
import debounce from 'lodash.debounce'
import { existenceIsExecution } from './utils'

import { AutoComplete, Button, DatePicker, Divider, Form, FormInstance, Input, Modal, Select } from 'antd'
import Moment from 'moment'
import './index.scss'
const { Option } = Select
const { TextArea } = Input
export type Props = {
  open: boolean
  customerInfo:any
  onClose?: () => void
  onFinish?: (val: any, requestParameters: requestParameters, form: FormInstance) => void
} & injectProps
type LayoutType = Parameters<typeof Form>[0]['layout']
export type requestParameters = {
  caseId: number | null
  appointmentId: number | null
  client: number | null
}
const FormLayout: FC<Props> = ({ open,customerInfo, onClose, onFinish, FormInit }) => {
  const [form] = Form.useForm()
  const [addRequestParameters, setAddRequestParameters] = useState<{ caseId: number | null; appointmentId: number | null; client: number | null }>({ caseId: null, appointmentId: null, client: null })
  const [formLayout, setFormLayout] = useState<LayoutType>('horizontal')
  const [customerType] = useCustomerType() // 客户类型
  const selected = useRef(false)
  // const [intentionLevel] = useIntentionLevel() // 意愿
  const nowTime = new Date().getTime()
  const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
    setFormLayout(layout)
  }
  useEffect(() => {
    if (!open) return
    form.setFieldsValue({
      arrivalTime: Moment(nowTime),
    })
    if(customerInfo.caseId){
      setCustomer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const setCustomer=async()=>{
    const autoOptionsResult = await loopUp({ clientPhone: customerInfo.clientPhone })
    setAutoOptions(autoOptionsResult.result || [])
    const cur = autoOptionsResult.result?.find(autoOptionsItem => autoOptionsItem.clientPhone === customerInfo.clientPhone)
    selected.current = true
    setAddRequestParameters({
      caseId: cur?.id || 0,
      appointmentId: null, // 不管
      client: cur?.clientDtos?.[0]?.id || null,
    })
    form.setFieldsValue({
      userName: cur?.clientName,
      customerType: cur?.caseType,
    })
    existenceIsExecution(cur?.clientPhone, () => {
      form.setFieldsValue({
        userPhone: cur?.clientPhone,
      })
    })
    existenceIsExecution(cur?.caseDate, () => {
      form.setFieldsValue({
        holdingTime: Moment(cur?.caseDate),
      })
    })
    existenceIsExecution(cur?.caseAddress, () =>
      form.setFieldsValue({
        venue: cur?.caseAddress,
      })
    )
    existenceIsExecution(cur?.remarks, () =>
      form.setFieldsValue({
        backup: cur?.remarks,
      })
    )
    existenceIsExecution(cur?.responsibleStaff, () =>
      form.setFieldsValue({
        receptionStaff: cur?.responsibleStaff,
      })
    )
    
  }
  const [autoOptions, setAutoOptions] = useState<phoneNumberLookupResponse>()
  const [loopUp] = usePhoneNumberLookup()
  async function phoneChange(val: string) {
    if (val.length < 4) {
      setAutoOptions([])
      return
    }
    const autoOptionsResult = await loopUp({ clientPhone: val })
    setAutoOptions(autoOptionsResult.result || [])

    // select时候会再出发一次change 这时候的change不修改caseId = null
    if (selected.current) {
      selected.current = false
    } else {
      setAddRequestParameters({
        caseId: null,
        appointmentId: null, // 不管
        client: null,
      })
    }
  }
  function autoSelect(val: string) {
    const cur = autoOptions?.find(autoOptionsItem => autoOptionsItem.clientPhone === val)
    selected.current = true
    setAddRequestParameters({
      caseId: cur?.id || 0,
      appointmentId: null, // 不管
      client: cur?.clientDtos?.[0]?.id || null,
    })
    form.setFieldsValue({
      userName: cur?.clientName,
      clientPhone: cur?.clientPhone,
      customerType: cur?.caseType,
    })
    existenceIsExecution(cur?.caseDate, () => {
      form.setFieldsValue({
        holdingTime: Moment(cur?.caseDate),
      })
    })
    existenceIsExecution(cur?.caseAddress, () =>
      form.setFieldsValue({
        venue: cur?.caseAddress,
      })
    )
    existenceIsExecution(cur?.remarks, () =>
      form.setFieldsValue({
        backup: cur?.remarks,
      })
    )
    existenceIsExecution(cur?.responsibleStaff, () =>
      form.setFieldsValue({
        receptionStaff: cur?.responsibleStaff,
      })
    )
  }

  const formItemLayout =
    formLayout === 'horizontal'
      ? {
        labelCol: { span: 4.5 },
        wrapperCol: { span: 14 },
      }
      : null
  return (
    <Modal
      title={'新增到店'}
      width={'auto'}
      footer={null}
      onCancel={() => {
        form.resetFields()
        onClose?.()
      }}
      keyboard
      mask
      maskClosable
      centered
      visible={open}>
      <Form
        name='form'
        {...formItemLayout}
        layout={formLayout}
        form={form}
        // initialValues={{ layout: formLayout }}
        onValuesChange={onFormLayoutChange}
        onFinish={val => onFinish?.(val, addRequestParameters, form)}>
        <div className='form'>
          <div className='left'>
            <Form.Item className='userRequred' label='客户电话' name='userPhone' rules={[{ required: true, message: '电话不能为空' }]}>
              <AutoComplete placeholder='请输入客户电话' onSelect={autoSelect} onChange={debounce(phoneChange, 800)} style={{ width: '220px' }}>
                {autoOptions?.map((autoItem, autoIndex) => {
                  return (
                    <AutoComplete.Option key={autoIndex} value={autoItem.clientPhone}>
                      {autoItem.clientName} {autoItem.clientPhone}
                    </AutoComplete.Option>
                  )
                })}
              </AutoComplete>
            </Form.Item>
            <Form.Item label='客户姓名' className='userRequred' rules={[{ required: true, message: '请输入客户姓名' }]} name='userName'>
              <Input type='text' placeholder='请输入客户姓名' style={{ width: '220px' }} />
            </Form.Item>
            <Form.Item label='客户类型' name='customerType'>
              <Select placeholder='请输入客户类型' style={{ width: 220 }}>
                {customerType.map((typeItem, typeIndex) => {
                  return (
                    <Option key={typeIndex} value={typeItem.id}>
                      {typeItem.title}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item label='举办时间' name='holdingTime'>
              <DatePicker inputReadOnly format='YYYY-MM-DD' placeholder='请输入举办时间' style={{ width: '220px' }} />
            </Form.Item>
            <Form.Item label='举办地点' name='venue'>
              <Input placeholder='请输入举办地点' style={{ width: '220px' }} />
            </Form.Item>
          </div>
          {/* <Divider type='vertical' style={{ height: 'auto' }} /> */}
          <div className='right'>
            <Form.Item label='到店时间' className='userRequred' name='arrivalTime' rules={[{ required: true, message: '到店时间不能为空' }]} initialValue={Moment(nowTime)}>
              <DatePicker picker='date' inputReadOnly showTime format='YYYY-MM-DD HH:mm:ss' placeholder='请输入到店时间' style={{ width: '220px' }} />
            </Form.Item>
            <Form.Item label='接待员工' name='receptionStaff'>
              <Select placeholder='请输入接待员工' style={{ width: 220 }}>
                {FormInit?.companyStaffs.map((staff, staffIndex) => {
                  return (
                    <Option key={staffIndex} value={staff.id}>
                      {staff.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item className='backup' label='备注内容' name='backup'>
              <TextArea style={{ height: '100%', resize: 'none' }} placeholder='输入备注' />
            </Form.Item>
          </div>
        </div>
        <div className='footer'>
          <div className='buttonOperation'>
            <Button onClick={onClose} htmlType='reset' ghost>
              取消
            </Button>
            &nbsp; &nbsp;
            <Button style={{ background: '#f1f1f1', color: 'black' }} htmlType='submit'>
              确定
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default withFormInitDispatch(FormLayout) as unknown as ComponentClass<Props, {}>
