/*
 * @Author: 邱狮杰
 * @Date: 2022-09-27 11:59:32
 * @LastEditTime: 2022-09-27 23:47:02
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/utils/createCrudVariable.ts
 */
const createCurlThemes = {
    create: 'createCreate',
    update: 'createUpdate',
    read: 'createRead',
    delete: 'createDelete',
};
export class CreateCrudVariable {
    constructor() {
        this.setUpThemesVariable = '';
    }
    setUpThemes(variable) {
        this.setUpThemesVariable = variable;
        return this;
    }
    joinThemes(themes) {
        return `${themes}${this.setUpThemesVariable}`;
    }
    getTopic() {
        return {
            [createCurlThemes.create]: Symbol.for(createCurlThemes.create),
            [createCurlThemes.update]: Symbol.for(createCurlThemes.update),
            [createCurlThemes.read]: Symbol.for(createCurlThemes.read),
            [createCurlThemes.delete]: Symbol.for(createCurlThemes.delete),
            [this.joinThemes('create')]: Symbol.for(this.joinThemes('create')),
        };
    }
}
