import { userListResponse } from '@type/api/module/common'
export interface headerCustomer {
  userInfo?: userListResponse // 新增客户
  init?: userListResponse[] // 初始化客户
  switchClientUser?: string // 切换客户
}

export const NEW_CUSTOMERS = 'newCustomers' //  新增客户

export const SWITCH_CUSTOMERS = 'switchCustomers' // 切换客户

export const INIT_CUSTOMERS = 'initCustomers' // 初始化客户

export const RESET_CUSTOMERS = 'resetUser' // 重置用户

export const CLEAR_CUSTOMERS = 'clearCustomers' // 清空
