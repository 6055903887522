import { formInitResponse, formTreeInitResponse } from '@service/api/modules/common'

export interface formInit {
  init?: formInitResponse
  initCustomerChannel?: formTreeInitResponse
}

export const FORM_INIT = 'formInit' // 初始化form

export const FORM_INIT_CUSTOMER_CHANNEL = 'initCustomerChannel' // 初始化渠道
