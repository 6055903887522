/*
 * @Author: 邱狮杰
 * @Date: 2022-11-06 10:12:29
 * @LastEditTime: 2022-11-06 10:24:17
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/utils/servicePlugin/auth.ts
 */
import { interceptor } from '@superBridal/service'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { BASE_AJAX_URL } from '@config/index'

export class Auth implements interceptor {
    requestFailInterceptor(err: unknown): unknown {
        console.log(err, 'erq')
        return
    }
    responseFailInterceptor(err: unknown): unknown {
        console.log(err, 'ress')
        return

    }
    // @ts-ignore
    responseSuccessInterceptor(response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> | void | Promise<void> {
    };

    // @ts-ignore
    requestSuccessInterceptor(config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> | void | Promise<void> {
        config.headers['authorization'] = 'Bearer ' + localStorage.getItem('token')
        config.baseURL = BASE_AJAX_URL
        return config
    }
}
