import { search } from 'youwilllike'
import { Dispatch, SetStateAction } from 'react'
import { StyleListContext } from '../components'
import { searchTypes } from '../searchContent'
import { Search, State } from '@reducers/modules/search'
import { useSearchDispatch } from '@hooks/searchDispatch'
import { useIdDeduplicationWithGradeIds } from '@hooks/useIdDeduplication'
export function useQueryGradeList(
  searchContext: search.SearchHandler<searchTypes>,
  dispatch: Dispatch<SetStateAction<StyleListContext.List>>
): [(item: StyleListContext.ListItem, list: StyleListContext.ListItem[]) => void, () => void] {
  const [idList, setIdList] = useIdDeduplicationWithGradeIds((Search.getCurState() as State).gradeIds)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, searchDispatch] = useSearchDispatch()
  const addParams = (item: StyleListContext.ListItem, list: StyleListContext.ListItem[]) => {
    dispatch(List => {
      return { ...List, list }
    })
    setIdList(item.id)
    // if (!searchContext.hasField('gradeIds')) {
    //   searchContext.addNewField('gradeIds', [item.id])
    // } else {
    //   if (!context.gradeIds.includes(item.id)) {
    //     searchContext.rewriteField('gradeIds', [...context.gradeIds, item.id])
    //     searchDispatch.setOption(context)
    //     return
    //   }
    //   searchContext.rewriteField(
    //     'gradeIds',
    //     context.gradeIds.filter(idItem => {
    //       return idItem !== item.id
    //     })
    //   )
    // }
    // searchDispatch.setOption(context)
  }
  function addToParameterList() {
    const context = searchContext.returnParams<searchTypes>()
    if (!searchContext.hasField('gradeIds')) searchContext.addNewField('gradeIds', idList.current)
    else searchContext.rewriteField('gradeIds', idList.current)
    searchDispatch.setOption(context)
  }
  return [addParams, addToParameterList]
}
