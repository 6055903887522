import ClickLike from '@assets/img/common/clickLike.png'
// import { HeartOutlined } from '@ant-design/icons'
import CloseBigPctrue from '@assets/img/common/closeBigPcture.png'
import noClickLike from '@assets/img/common/notClickedLike.png'
import { RouteComponentProps } from 'react-router-dom'
// import { debounce } from 'lodash'
import debounce from 'lodash.debounce'
import { FC, useEffect, useRef, useState } from 'react'
import { previewBigPicture } from '@constants/thumbnail'
import { curStateTypes, styleListItem } from '@reducers/modules/yarnSelectionListStatus'
import { SuperImage } from '@components/index'
import { Swiper as swiperInstance } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Utils } from 'youwilllike'
import { TagLength } from './tag'
import {
  // useGestureOperation,
  useCurTag,
  useHitwall,
  usePageImages,
  useLeftKeys,
  useRightKeys,
  useEscKeys,
  useTop,
  useBottomKeys,
  useSpaceKeys,
  useItemLength,
} from './hooks'
import { useKeysEvent } from './events'
import './index.scss'
import { message } from 'antd'
import { State } from '@page/preselectedYarnList/index'
import { useResize } from '@hooks/useResize'
import { useSwitchPreview } from '@hooks/useBigPcture'
import { useHeaderWithSwitchGoods } from '@hooks/useHeaderWithBus'
/* eslint-disable react/jsx-pascal-case */
// import welcomeBkg from '@assets/img/welcome/welcomeBkg.png'

export type goosListType = { src: string; id: number; like?: boolean }

type Props = {
  show?: boolean
  goodsList?: goosListType[]
  onClose?: () => void
  onLike?: (index: number, id: number, like: boolean) => void
  onNextPage?: () => void
  onPrevPage?: () => void
  chose?: number
  listCurState?: curStateTypes
  previewXList?: styleListItem[] // x 轴列表
  previewTriedXList?: goosListType[] // 已试状态下才会渲染 已试List
  history: RouteComponentProps['history']
  curParentState: State
}
/**
 * @deprecated 被弃用的打开modael
 * @param param0 
 * @returns 
 */
const BigPcture: FC<Props> = ({ previewXList, show, onClose, onLike, onNextPage, chose, onPrevPage, previewTriedXList, listCurState }) => {
  const [activeCount, setActiveCount] = useState(0) // X
  const [activeCountY, setActiveCountY] = useState(0) // Y
  const [XY, setXY] = useState<'horizontal' | 'vertical'>('horizontal')
  const [fromTop, setFromTop] = useState(0)
  const [curTag, curTagWithTried] = useCurTag(previewXList || [], activeCount, {
    curState: listCurState,
    show,
  })

  const onceEditFromTop = useRef(false)
  const { isAndroid, isiOS } = Utils.Os.getOs()
  // 第一个swiperSlide
  const startSlide = useRef(false)
  // 最后一个swiperSlide
  const lastSlide = useRef(false)
  /**
   * @description 触壁
   */
  const [runOpen, runClose] = useSwitchPreview()
  useHeaderWithSwitchGoods(() => {
    onceEditFromTop.current = false
  })
  useEffect(() => {
    if (show) {
      runOpen()
      return
    }
    return runClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])
  const maskLike = (like: boolean, index: number, id: number) => {
    if (listCurState === 'triedList') return <></>
    if (XY === 'vertical') return <> </>
    return (
      <>
        <img
          className='maskBigPctureImg'
          src={like ? ClickLike : noClickLike}
          alt={'like'}
          onClick={e => {
            e.stopPropagation()
            onLike?.call(null, index, id, like)
          }}
          style={{ top: `${fromTop}px` }}
        />
      </>
    )
  }
  const swiperContainer = useRef<undefined | swiperInstance>()

  useResize(() => {
    if (!swiperContainer.current) return
    swiperContainer.current.updateSize()
  })
  const curYAxisData = useRef<styleListItem[]>([])

  const [spaceHandler] = useSpaceKeys({ XY, swiperContainer, show, prew })
  const [topHandler] = useTop({ setXY, XY, swiperContainer, listCurState: listCurState || 'selected' })

  const [leftKeysHandler] = useLeftKeys({
    curYAxisData,
    activeCount,
    XY,
    setXY,
    onPrevPage,
    show,
    swiperContainer,
    setActiveCount,
    listCurState,
    previewXList,
  })
  const [rightKeysHandler] = useRightKeys({
    curYAxisData,
    activeCount,
    XY,
    setXY,
    onNextPage,
    setActiveCount,
    show,
    swiperContainer,
    previewXList,
    listCurState,
    previewTriedXList,
  })
  const [bottomKeysHandler] = useBottomKeys({ setXY, XY, swiperContainer, curYAxisData, listCurState: listCurState || 'selected' })
  const [escHandler] = useEscKeys({ onClose, XY, setXY, activeCount, swiperContainer })
  const [touchLeftHitTheWall, touchRightHitTheWall, leaveToTouchTheWall] = useHitwall({
    XY,
    startSlide,
    lastSlide,
    onNextPage,
    onPrevPage,
    listCurState,
    swiperContainer,
    previewXList,
  })
  useKeysEvent(leftKeysHandler, rightKeysHandler, escHandler, topHandler, bottomKeysHandler, spaceHandler, [previewXList, show, XY])
  const [previewYList] = usePageImages({ previewXList, listCurState, show })
  const [inStyleItem] = useItemLength({
    previewYList,
    previewXList,
  })
  useEffect(() => {
    return () => {
      onceEditFromTop.current = false
    }
  }, [])
  function readyClose() {
    if (XY === 'vertical') {
      setXY('horizontal')
      return
    }
    onClose?.()
  }
  function sizeWithFromTop(context: SuperImage.SuperImageRef) {
    if (onceEditFromTop.current) return
    if (!context) return
    const boxH = context.box.h
    const imgH = context.img.h
    if (!boxH) return
    if (!imgH) return
    // 减去图片剩余高
    const subtractRemainingHeightPicture = boxH - imgH
    // 上半部分剩余高度
    const remainingHeightUpperHalf = subtractRemainingHeightPicture / 2
    setFromTop(remainingHeightUpperHalf)
  }
  useEffect(() => {
    if (swiperContainer.current && chose !== undefined) {
      if (show) {
        setActiveCount(chose)
        swiperContainer.current.slideTo(chose, 800)
      } else {
        lastSlide.current = false
        setXY('horizontal')
      }
    }
  }, [chose, show])
  /**
   * @description List change 判断触壁的参数就会被reset
   */
  useEffect(() => {
    leaveToTouchTheWall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewXList])

  useEffect(() => {
    XY === 'horizontal' && swiperContainer.current?.slideTo(activeCount, 800)
    if (XY === 'vertical') swiperContainer.current?.slideTo(0, 800)
  }, [XY, activeCount])

  function slideChange(swiper: swiperInstance) {
    if (swiper.activeIndex === (previewXList || []).length - 1) return touchRightHitTheWall()
    if (swiper.activeIndex === 0) return touchLeftHitTheWall()
    leaveToTouchTheWall()
  }

  function renderContext() {
    if (listCurState === 'triedList') return previewTriedXList || []
    if (XY === 'vertical') {
      const list = previewYList?.get(previewXList?.[activeCount].id || 0)
      if (!list) {
        message.destroy()
        message.warn('没有该款式可查看的列表')
        setXY('horizontal')
        return previewXList || []
      }
      curYAxisData.current = list
      return list
    }
    return previewXList || []
  }

  function prew(e?: any) {
    e?.stopPropagation()
    if (!onceEditFromTop.current) onceEditFromTop.current = true
    setTimeout(() => {
      if (XY === 'horizontal') setXY('vertical')
      else setXY('horizontal')
    }, 0)
  }
  function doubleCLick(e: Event) {
    prew(e)
  }
  function itemTagCpm(inItem: styleListItem[]) {
    if (!inItem) return <></>
    if (inItem.length === 1) return <></>
    if (!inItem.length) return <></>
    if (XY === 'vertical') return <></>
    return <TagLength text={`${inItem.length}`} />
  }
  // const debounceEditFromTop = debounce(sizeWithFromTop, 300)
  return (
    <div className={`bigPcture ${(isAndroid || isiOS) && 'ipad'}`} style={show ? { transform: 'scale(1)' } : { transform: 'scale(0)' }}>
      <div
        className='realBox'
        onClick={e => {
          e.stopPropagation()
          readyClose()
        }}>
        <div className='tag' style={{ zIndex: 100 }}>
          {(listCurState === 'triedList' ? curTagWithTried : curTag).map((tag, tagIndex) => {
            if (!tag.content) return <span key={tagIndex}></span>
            return (
              <div className='tagRow' key={tagIndex}>
                <span>{tag.name}:</span>
                <span>{tag.content}</span>
                {/* {tag.name === '中文' && (
                  <>
                    {itemTagCpm(inStyleItem(tag?.meta?.id || 0))}
                    &nbsp; &nbsp;
                    {maskLike(tag.meta?.like || false, tag.meta?.index || 0, tag.meta?.id || 0)}
                  </>
                )} */}
              </div>
            )
          })}
          {/* <div className='ownStyleLikeOperaction'>
            <span className='label'>喜欢:</span>
            <Button className='ownStyleLike'>
              <span className='like'>
                <HeartOutlined style={{ color: 'red' }} />
              </span>
            </Button>
          </div> */}
        </div>
        <img
          onClick={() => {
            readyClose()
          }}
          style={{ zIndex: 1000 }}
          className='closeButton'
          src={CloseBigPctrue}
          alt='close'
        />
        <Swiper
          threshold={30}
          className={`showContent ${XY === 'vertical' && 'withSwiperWrapper'}`}
          style={XY === 'horizontal' ? { overflow: 'visible' } : { overflow: 'hidden' }}
          direction={XY}
          effect='coverflow'
          lazy
          longSwipes={false}
          longSwipesRatio={0.5}
          longSwipesMs={400}
          slidesPerView={XY === 'horizontal' ? 'auto' : 1}
          centeredSlides={true}
          loop={XY === 'vertical'}
          observer
          observeParents
          updateOnImagesReady={true}
          onSliderMove={debounce(slideChange, 200)}
          onSlideChangeTransitionStart={swiper => {
            XY === 'horizontal' ? setActiveCount(swiper.activeIndex) : setActiveCountY(swiper.activeIndex)
          }}
          slideToClickedSlide
          preventClicksPropagation={false}
          onSwiper={swiper => (swiperContainer.current = swiper)}
          coverflowEffect={{
            rotate: 30,
            stretch: 10,
            depth: 60,
            modifier: 2,
            slideShadows: true,
          }}
          autoplay={{
            stopOnLastSlide: true,
          }}
          onDoubleClick={(swiper, Event) => doubleCLick.call(null, Event)}>
          {/* {listCurState === 'triedList' ? previewTriedXList : previewXList} */}
          {renderContext()?.map((listItem, listItemIndex) => {
            return (
              <SwiperSlide key={listItemIndex} className={`slidePadding`}>
                <SuperImage.default
                  style={{ height: '100%', maxHeight: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  h={previewBigPicture.h}
                  w={previewBigPicture.w}
                  src={listItem.src}
                  ref={sizeWithFromTop}
                  className={`slidePaddingItem ${XY === 'vertical' ? 'swiperY_img' : ''}`}
                  showMaskLayoutComponent={false}
                  maskLayoutComponent={maskLike(listItem?.like || false, listItemIndex, listItem.id)}
                  openMagnifier={XY === 'horizontal' ? activeCount === listItemIndex : activeCountY === listItemIndex}
                  disableAbbreviation
                  imgWithMask
                  ltCpm={itemTagCpm(inStyleItem(listItem.id))}
                  onImgChange={sizeWithFromTop}
                  ltCpmStyle={{ top: `${fromTop}px` }}
                />
                {/* </div> */}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default BigPcture
