/*
 * @Author: 邱狮杰
 * @Date: 2021-09-28 17:21:45
 * @LastEditTime: 2022-11-03 10:54:33
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/utils/tag.ts
 */
import likeCount from '@assets/img/common/likeNum.png'
import orderNum from '@assets/img/common/orderNum.png'
import tryNum from '@assets/img/common/tryNum.png'

export function withImgTagListFn(like: number | string, trys: number | string, order: number | string) {
  return [
    {
      img: likeCount,
      content: like || 0,
    },
    // {
    //   img: tryNum,
    //   content: trys || 0,
    // },
    { img: orderNum, content: order || 0 },
  ]
}
