import { CreateReducer } from '@zealforchange/conciseredux'
import { Action } from 'redux'
import type { formInit } from '@constants/formInit'
import { FORM_INIT, FORM_INIT_CUSTOMER_CHANNEL } from '@constants/formInit'
import { formInitResponse, formTreeInitResponse } from '@service/api/modules/common'

export type State = {
  customerChannel: formTreeInitResponse
} & formInitResponse

type DispatchTypes = typeof FORM_INIT | typeof FORM_INIT_CUSTOMER_CHANNEL

export const FormOptionsInit = new CreateReducer<State, Action & formInit, DispatchTypes>({
  companyStaffs: [],
  goodsCategories: [],
  goodsGrades: [],
  goodsListDtos: [],
  goodsPackages: [],
  payPatterns: [],
  customerChannel: [],
})
  .addAction(FORM_INIT, (state, actions) => {
    return { ...state, ...(actions?.init || state) }
  })
  .addAction(FORM_INIT_CUSTOMER_CHANNEL, (state, actions) => {
    return { ...state, customerChannel: actions.initCustomerChannel || [] }
  })
