var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 邱狮杰
 * @Date: 2022-11-06 10:41:00
 * @LastEditTime: 2022-11-06 10:47:25
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/utils/cancelableResponse.ts
 */
import { TerminationResult } from '@mxnet/service';
import { http } from '../config/index';
export class CancelableResponse {
    constructor() {
        this.t = new TerminationResult();
    }
    returnHandle(req) {
        const t = this.t.ConfigurationParameters(req);
        return {
            trigger() {
                return __awaiter(this, void 0, void 0, function* () {
                    return yield http(t.getConfiguration());
                });
            },
            cancelTrigger() {
                t.terminateTrigger();
            }
        };
    }
}
