/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 14:13:48
 * @LastEditTime: 2022-11-12 16:33:01
 * @FilePath: /preselectedweddingdress/src/layout/header/index.tsx
 * @Description  : header
 */

import { header } from '@event/index'
import { injectProps as withCommonDispatchInjectProps, withCommonDispatch } from '@hoc/commonDispatchHoc'
import { injectProps as headerButtonDispatchHocInjectProps, withHeaderButtonDispatch } from '@hoc/headerButtonDispatchHoc'
import { injectProps as withHeaderCustomerDispatchInjectProps, withHeaderCustomerDispatch } from '@hoc/headerCustomerDispatch'
import { injectProps, withHeaderGoodsDispatch } from '@hoc/headerGoodsDispatchHoc'
import { injectProps as withYarnSelectionListStatusDispatchHocInjectProps, withYarnSelectionListStatusDispatchHoc } from '@hoc/yarnSelectionListStatusDispatch'
import { withYarnTestingOperationButtonHoc, withYarnTestingOperationButtonStateTypesInjectProps } from '@hoc/yarnTestingOperationButtonHoc'
import bus from '@utils/eventBus'
import { bindThis, hoc, initialState } from 'high-order-react'
import { Component, createRef, CSSProperties, RefObject } from 'react'
import { HeaderGoods, SwitchCustomers } from './components'

import './index.scss'

interface Props {
  style?: CSSProperties
}

interface State {
  boxInstance: RefObject<HTMLDivElement>
  switchBox: RefObject<HTMLDivElement>
  tagWidth: number
}
@hoc(withHeaderCustomerDispatch)
@hoc(withHeaderGoodsDispatch)
@hoc(withHeaderButtonDispatch)
@hoc(withYarnSelectionListStatusDispatchHoc)
@hoc(withCommonDispatch)
@hoc(withYarnTestingOperationButtonHoc)
@initialState<State>({
  boxInstance: createRef(),
  switchBox: createRef(),
  tagWidth: 0,
})
class Header extends Component<
  Props & withYarnTestingOperationButtonStateTypesInjectProps & withHeaderCustomerDispatchInjectProps & injectProps & headerButtonDispatchHocInjectProps & withYarnSelectionListStatusDispatchHocInjectProps & withCommonDispatchInjectProps,
  State
> {
  componentDidMount() {
    this.calculateToRemoveContentButton()
  }

  componentDidUpdate() {
    const { headerGoodsList } = this.props
    headerGoodsList?.find(activeItem => activeItem.active)
  }
  /**
   * @description 计算除去右边button操作区和switch的宽度
   */
  calculateToRemoveContentButton() {
    if (!this.state) {
      return
    }
    const { boxInstance, switchBox } = this.state
    if (!boxInstance.current) return
    if (!switchBox.current) return
    this.setState({
      tagWidth: boxInstance.current?.scrollWidth - switchBox.current?.scrollWidth - 10,
    })
  }

  @bindThis
  switchHeaderGoods(index: number | string) {
    const { withHeaderGoodsDispatchActions } = this.props
    withHeaderGoodsDispatchActions?.choseGoods(index)
    bus.emit<header.switchGoods>(header.SWITCH_GOODS, {})
  }

  render() {
    const { curEle, headerGoodsList, YarnSelectionListStatus, commonState, style, HeaderCustomer } = this.props
    const { boxInstance, switchBox, tagWidth } = this.state

    const activeKey = headerGoodsList?.find(activeItem => activeItem.active)

    return (
      <div className='header' ref={boxInstance} style={{ ...style }}>

        <div className='switchCustomers' ref={switchBox}>
          <SwitchCustomers />
        </div>

        <div className={`headerGoods ${HeaderCustomer?.curUser.caseId ? 'limitWidth' : ''}`} style={HeaderCustomer?.curUser.caseId ? {} : { width: `${tagWidth}px` }} key='headerGoods'>
          {YarnSelectionListStatus?.curState === 'Unselected' ? <HeaderGoods goodsList={headerGoodsList || []} activeKey={activeKey?.title || ''} onProxyClick={this.switchHeaderGoods} /> : ''}
        </div>

        <div className='buttonOperation' key='buttonOperation'>
          {curEle ? curEle : ''}
        </div>

        {commonState?.localhostImg !== null && <span style={commonState?.localhostImg ? { background: 'greenyellow' } : { background: 'red' }} className='activeLight'></span>}

      </div>
    )
  }
}

export default Header
