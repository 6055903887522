/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-13 15:41:30
 * @LastEditTime: 2021-08-16 17:56:48
 * @FilePath     : /preselectedyarn/src/service/api/modules/welcome.ts
 * @Description  : welcome
 */

import { ApiUnitConfig } from "@type/axios";
export default [
  {
    name: "welcomeInit",
    path: "/system/store/tablet/business/login/init",
    method: "get",
  },
] as ApiUnitConfig[];
