/*
 * @Author: 邱狮杰
 * @Date: 2021-09-28 12:59:03
 * @LastEditTime: 2022-11-12 16:06:41
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/page/preselectedYarnList/components/search/hooks/useGetGradeList.ts
 */
import { Dispatch, SetStateAction, useState } from 'react'
// import { clone } from 'lodash'
import { useFormDispatch } from '@hooks/formDispatch'
import { mergeFnWithPromiseFn } from '@mxnet/types/dts'
import { Search, State } from '@reducers/modules/search'
import clone from 'lodash.clone'
import { StyleListContext } from '../components'
// import { useSearchDispatch } from '@hooks/searchDispatch'
export function useGradeList(): [StyleListContext.List, Dispatch<SetStateAction<StyleListContext.List>>, () => void, mergeFnWithPromiseFn<void, [number]>] {
  const [form] = useFormDispatch()
  // const [search] = useSearchDispatch()
  const [gradeList, setGradeList] = useState<StyleListContext.List>({
    title: '范围',
    list: clone(
      form.FormOptionsInit.goodsGrades.map(item => {
        const search = Search.getCurState() as State
        return { name: item.title, active: search.gradeIds?.includes(item.id) ? true : false, id: item.id }
      })
    ),
  })

  function resetGradeList() {
    setGradeList({
      title: '范围',
      list: clone(
        form.FormOptionsInit.goodsGrades.map(item => {
          return { name: item.title, active: false, id: item.id }
        })
      ),
    })
  }


  function setGradeListHandler(id: number) {

    const NewList = gradeList.list.map(n => {
      return { ...n, active: n.id === id ? !n.active : n.active }
    })

    setGradeList({
      title: gradeList.title,
      list: NewList
    })

  }

  return [gradeList, setGradeList, resetGradeList, setGradeListHandler]

}
