/*
 * @Author: 邱狮杰
 * @Date: 2022-09-27 11:57:49
 * @LastEditTime: 2022-11-25 11:42:45
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/customer/read.ts
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// import { injectable } from 'inversify'
import { provide } from 'inversify-binding-decorators';
import { http } from '../../config';
import { customerConstant } from './customer';
import { CancelableResponse } from '../../utils/cancelableResponse';
// @injectable()
let CustomerRead = class CustomerRead {
    // 获取详情
    readDetails(req) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield http({
                url: `/system/store/client/business/caseinfo/${req.caseId}`,
            });
            return result;
        });
    }
    readWxCustomerList(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new CancelableResponse().returnHandle({
                url: '/system/store/wx/business/case/list', method: 'GET',
                params
            });
        });
    }
    readWxOrderList(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new CancelableResponse().returnHandle({
                url: '/system/store/wx/business/case/order/list', method: 'GET',
                params
            });
        });
    }
    readWxMyCustomerList(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return new CancelableResponse().returnHandle({
                url: '/system/store/wx/business/case/my/list', method: 'GET',
                params
            });
        });
    }
    readCustomerDetail(caseId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield http({
                url: `/system/store/client/business/caseinfo/${caseId}`,
            });
            return result;
        });
    }
    readCustomerRecord(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield http({
                url: `/system/store/wx/business/relation/record`,
                params
            });
            return result;
        });
    }
    readCustomerVisit(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield http({
                url: `/system/store/client/business/appoinment/record/list`,
                params
            });
            return result;
        });
    }
    readCustomerLike(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield http({
                url: `/system/store/wx/business/like/goods/list`,
                params
            });
            return result;
        });
    }
    readCaseAllotConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield http({
                url: `/system/store/wx/business/caseAllotConfig`,
            });
            return result;
        });
    }
};
CustomerRead = __decorate([
    provide(customerConstant.createRead)
], CustomerRead);
export { CustomerRead };
