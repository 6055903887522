import { Moment } from 'moment'
// import { hasKeySuffixwithRemove } from '@components/form/utils'
import { requestParameters } from '@components/form/index'

export type formDataInput = {
  arrivalTime: Moment
  backup: string
  // customerChannel: string
  customerType: number
  holdingTime: Moment
  receptionStaff: number
  userName: string
  userPhone: string
  venue: string
} & requestParameters
export interface formDataOutput {
  appointmentId: number | null // 预约id
  caseAddress: string
  caseDate: string
  caseId: number | null // caseId 事件id
  caseType: number
  clientId: number | null // 客户id
  clientName: string
  clientPhone: string
  hotelName: string
  remarks: string
  responsibleStaff: number
  // value: string
  visitAt: string
}
function momentToString(time: Moment): string {
  return time?.format('YYYY-MM-DD HH:mm:d') || ''
}
export function formatFormData(params: formDataInput): formDataOutput {
  if (!params.userName.trim().length) {
    throw new Error('客户姓名不能为空')
  }
  if (!params.userPhone.trim().length) {
    throw new Error('客户电话不能为空')
  }
  return {
    appointmentId: params.appointmentId,
    caseId: params.caseId,
    caseAddress: params.venue,
    caseDate: momentToString(params.holdingTime),
    caseType: params.customerType,
    clientId: params.client,
    clientName: params.userName,
    clientPhone: params.userPhone,
    // value: params.customerChannel,
    hotelName: '',
    remarks: params.backup,
    responsibleStaff: params.receptionStaff,
    visitAt: momentToString(params.arrivalTime),
  }
}
