import { useState, Dispatch, SetStateAction } from 'react'
// import { clone } from 'lodash'
import clone from 'lodash.clone'
import { useGoodsStyleDispatch } from '@hooks/goodsStyleDispatch'
import { StyleListContext } from '../components/index'
import { useSearchDispatch } from '@hooks/searchDispatch'

export function useGetStyle(): [StyleListContext.List, Dispatch<SetStateAction<StyleListContext.List>>, () => void] {
  const [styleList] = useGoodsStyleDispatch()
  const [search] = useSearchDispatch()

  const [list, setList] = useState<StyleListContext.List>({
    title: '分类',
    list: clone(
      styleList.HeaderGoodsReducer.goodsList.map(goodsItem => {
        return { ...goodsItem, active: search.Search.categoryId === goodsItem.id ? true : false, name: goodsItem.title }
      })
    ),
  })
  function resetList() {
    setList({
      title: '分类',
      list: clone(
        styleList.HeaderGoodsReducer.goodsList.map(goodsItem => {
          return { ...goodsItem, active: false, name: goodsItem.title }
        })
      ),
    })
  }
  return [list, setList, resetList]
}
