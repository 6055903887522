/*
 * @Author: 邱狮杰
 * @Date: 2021-10-10 16:27:22
 * @LastEditTime: 2022-11-11 09:30:44
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/reducers/modules/likeAndDeleteIdCollection.ts
 */
import type { LikeAndDeleteIdCollectionTypes } from '@constants/likeAndDelete'
import { EDIT_DELETE, EDIT_LikeID, REMOVE_LIKEID } from '@constants/likeAndDelete'
import { CreateReducer } from '@zealforchange/conciseredux'
import { Action } from 'redux'

export interface State {
  likeids: string // ids 接口需要的ids
  deleteIds: string // 剔除被删除的id后保留的id
  backupIds: string // ids 备份
}

type DispatchTypes = typeof EDIT_LikeID | typeof REMOVE_LIKEID | typeof EDIT_DELETE


const LikeAndDeleteIdCollection = new CreateReducer<State, Action & LikeAndDeleteIdCollectionTypes, DispatchTypes>({ likeids: '', backupIds: '', deleteIds: '' })
  .addAction(EDIT_LikeID, (state, actions) => {
    // 去重 add like id
    const allId = actions.ids?.concat(state.likeids)
    const newLikes = [...new Set(allId?.split(','))].join(',') || ''
    return { ...state, likeids: newLikes }
  })
  .addAction(REMOVE_LIKEID, (state, actions) => {
    const likeIdsList = state.likeids.split(',')
    const index = likeIdsList.findIndex(item => item === actions.ids)
    likeIdsList.splice(index, 1)
    const likeResult = likeIdsList.join(',')
    return { ...state, likeids: likeResult }
  })
  .addAction(EDIT_DELETE, (state, actions) => {
    // 去重
    const allId = actions.ids?.concat(state.deleteIds)
    const newDeleteIds = [...new Set(allId?.split(','))].join(',') || ''
    return { ...state, deleteIds: newDeleteIds }
  })

export { LikeAndDeleteIdCollection }
