import { ApiUnitConfig } from '@type/axios'
import { httpPagination } from '@type/api/common'

export type LikeListYetRequest = {
    caseId: number
}
export type LikeAndDeleteYetRequest = {
    caseId: number
    goodsId: number
}
export type DeleteIds = {
    caseId: number
    goodsIds: number[]
}
export type goodsStyleRequest = {
    caseId?: number
    categoryId?: number
    orderBy?: 'desc' | 'asc'
} & goodsSearchRequest

export interface goodsStyleResponseRowsItem {
    categoryId: number
    id: number
    scanCode: string
    images: string[]
    imageMain: string
    nameCn: string
    index?: number
    serial: string
    likeCount: number | string
    orderCount: number
    tryonCount: number
    gradeTitle: string
    tags: { title: string }[]
    //   在组装了数据后才可能存在
    like?: boolean
}

export interface goodsStyleResponse {
    rows: goodsStyleResponseRowsItem[]
    total: number
}

export type preselectionYetResponse = {
    createAt: string
    updateAt: string
    updateStaff: number
    inventoryCount: number
    atStore: number
    tryOnCount: number
    sacnCode: string
    id: number
    nameCn: string
    customizedPeriod: number
    nameEn: string
    imageMain: string
    serial: string
    isCustomized: number
    pricePurchase: number
    priceSale: number
    priceRent: number
    categoryId: number
    manufacturerId: number
    brandId: number
    gradeId: number
    status: number
    enable: number
    shopId: number
    companyId: number
}[]

export interface imgListRequest {
    goodsId: number
    imageType: 0 | 1 | 2 | 3 //0模特图，1试纱图，2现场图，3其他
    status: 0 | 1
}

export interface imageListResponse {
    rows: {
        companyId: number
        createAt: string
        deleted: number
        goodsId: number
        id: number
        imageType: number
        path: string
        shopId: number
        status: number
        tenantId: string
        updateAt: string
    }[]
    total: number
}

export interface perselectionYetRequest {
    caseId: number
    pageNum: number
    pageSize: number
}

export interface perselectionYetResponse {
    rows: {
        atStore: null | string
        brandId: number
        categoryId: number
        companyId: number
        createAt: string
        customizedPeriod: null | number
        enable: number
        gradeId: number
        id: number
        imageMain: string
        inventoryCount: null | number
        isCustomized: number
        manufacturerId: number
        nameCn: string
        nameEn: string
        pricePurchase: number
        priceRent: number
        priceSale: number
        sacnCode: null
        serial: string
        shopId: number
        status: number
        tryon_id: null | number
        updateAt: string
        tags: { title: string }[]
        updateStaff: number
        tryonCount: number | string
        orderCount: number | string
        likeCount: number | string
        gradeTitle: string
    }[]
    total: number
}

export interface tryonyetRequest {
    caseId: number
    pageNum?: number
    pageSize?: number
}

export type tryonYetResponse = {
    rows: {
        goodsId: number
        id: string
        imageMain: string
        like: number
        nameCn: string
        rating: number
        serial: string
        scanCode: string
        tryonPhotos: {
            id: number
            photoAt: null | string
            photoName: null | string
            photoPath: string
            tryonId: number
        }[]
    }[]
    total: number
}
export type byCategoryIdResponse = {
    categoryId: number
    displayOrder: number
    id: number
    tags: { tagTitle: string; id: number }[]
    title: string
}[]

export interface goodsSearchRequest {
    caseId: number | null | undefined
    categoryId?: number
    endPrice?: number
    orderBy?: string
    searchKey?: string
    tags?: any
    gradeIds?: any
    startPrice?: number
    /**
     * @deprecated 废弃参数
     */
    pageNum: number
    /**
     * @deprecated 废弃参数
     */
    pageSize: number
    shield: number
    edate?: string
    sdate?: string
}

export interface godosSearchResponse {
    total: number
    rows: {}[]
}

export default [
    {
        method: 'POST',
        name: 'goodsStyle',
        path: '/system/store/tablet/business/goods',
        desc: '获取商品款式',
        params: {
            pageNum: '',
            pageSize: '',
            caseId: '',
            categoryId: '',
            endPrice: '',
            orderBy: '',
            searchKey: '',
            searchTags: '',
            startPrice: '',
            sdate: '',
            edate: '',
            shield: '',
            gradeIds: '',
            tags: '',
        },
    },
    {
        method: 'GET',
        name: 'preselectionYet',
        path: '/system/store/tablet/business/perselection/all/yet',
        desc: '已选纱',
        params: {
            pageNum: '',
            pageSize: '',
            caseId: '',
            orderBy: '',
        },
    },
    {
        method: 'PUT',
        name: 'preselectionYetPut',
        path: '/system/store/tablet/business/perselection/yet',
        desc: '修改线下预选纱', // 废弃
        params: {
            caseId: '',
            goodsIds: '',
        },
    },
    {
        method: 'GET',
        name: 'tryonYet',
        path: '/system/store/tablet/business/tryon/yet',
        desc: '已试纱',
        params: {
            pageNum: '',
            pageSize: '',
        },
    },
    {
        method: 'GET',
        name: 'likeYetList',
        path:  '/system/store/tablet/business/perselection/yet',
        desc: '已选纱',
        params: {
            caseId:''
        },
    },
    {
        method: 'GET',
        path: '/system/store/bussiness/goods/images/list',
        name: 'imgList',
        desc: '大图',
        params: {
            goodsId: '',
            imageType: '',
            status: '',
        },
    },
    {
        method: 'post',
        path: '/system/store/tablet/business/perselection/yet',
        name: 'likeyet',
        desc: '新增喜欢款式',
        params: {
            caseId: '',
            goodsId: '',
        },
    },
    {
        method: 'DELETE',
        path: '/system/store/tablet/business/perselection/yet',
        name: 'deleteYet',
        desc: '删除喜欢款式',
        params: {
            caseId: '',
            goodsId: '',
        },
        isFormData: false,
    },
    {
        name: 'deleteList',
        method: 'DELETE',
        path: '/system/store/tablet/business/perselection/yet/deleteds',
        desc: '批量删除',
        params: {
            caseId: '',
            goodsIds: '',
        },
    },
    {
        name: 'perselectionYet',
        method: 'GET',
        path: '/system/store/tablet/business/perselection/yet',
        desc: '分页已选纱',
        params: {
            pageNum: '',
            pageSize: '',
            caseId: '',
        },
    },
    {
        name: 'tryonyet',
        path: '/system/store/tablet/business/tryon/yet',
        method: 'GET',
        desc: '已试',
        params: {
            caseId: '',
            pageNum: '',
            pageSize: '',
        },
    },
    {
        name: 'byCategoryId',
        path: '/system/store/bussiness/goods/tag/byCategoryId',
        method: 'GET',
        desc: '根据分类查询标签',
        params: { categoryId: '' }
    },
    {
        name: 'goodsSearch',
        path: '/system/store/tablet/business/goods',
        method: 'POST',
        desc: '过滤',
        params: {
            caseId: '',
            categoryId: '',
            endPrice: '',
            orderBy: '',
            searchKey: '',
            searchTags: '',
            startPrice: '',
            tags: '',
            shield: '',
            gradeIds: '',
            edate: '',
            sdate: '',
        },
    },
    {
        name: 'pageImage',
        path: '/system/store/tablet/business/goods/pageImages',
        method: 'GET',
        desc: '获取该页面所有款式预览List',
        params: {
            goodsIds: '',
        },
    },
    {
        name: 'dresserConfig',
        path: '/system/store/tablet/business/dresser/config',
        method: 'GET',
        desc: '化妆师配置',
        params: {},
        needToken: true
    },
    {
        name: 'dresserList',
        path: '/system/store/tablet/business/dresser/list',
        method: 'GET',
        desc: '化妆师列表',
        params: {
            edate: '',
            name: '',
            phone: '',
            sdate: '',
            title: '',
        },
        needToken: true
    },
    {
        name: 'dresserWords',
        path: '/system/store/tablet/business/dresser/words',
        method: 'GET',
        desc: '化妆师作品',
        params: {
            dresserIds: ""
        },
        needToken: true
    },
] as ApiUnitConfig[]
