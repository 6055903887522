import { ComponentType } from 'react'
import * as actions from '@actions/formInit'
import { Action, Dispatch, bindActionCreators } from 'redux'
import { formInit } from '@constants/formInit'
import { State } from '@reducers/modules/formInit'
import { createReducerProcessingHoc } from '@zealforchange/redux-encapsulation'

export interface injectProps {
  FormInit?: State
  withFormInitDispatchActions?: typeof actions
}
function mapStateToProps(state: { FormOptionsInit: State }) {
  return {
    FormInit: state.FormOptionsInit,
  }
}
function mapDispatchToProps(dispatch: Dispatch<Action & formInit>) {
  return {
    withFormInitDispatchActions: bindActionCreators(actions, dispatch),
  }
}

export const withFormInitDispatch = <T extends injectProps>(C: ComponentType<T>) => {
  return createReducerProcessingHoc('withFormInitDispatch', C, mapStateToProps, mapDispatchToProps)
}
