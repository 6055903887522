/**
 * @description 手机号查找
 */
import api from '@service/index'
import { phoneNumberLookupRequest, phoneNumberLookupResponse } from '@service/api/modules/common'
import { requestCommonTypes } from '@type/api/common'
import { SynchronizationAwaitError } from 'synchronizationawaiterror'
import { message } from 'antd'

export function usePhoneNumberLookup() {
  async function lookup(params: Partial<phoneNumberLookupRequest>): Promise<requestCommonTypes<phoneNumberLookupResponse>> {
    const [err, res] = await SynchronizationAwaitError(api['common|phoneNumberLookup'](params || {}))
    if (err) {
      message.destroy()
      message.warn('查找失败请稍后再试')
      throw new Error('查找失败请稍后再试')
    }
    return res as requestCommonTypes<phoneNumberLookupResponse>
  }
  return [lookup]
}
