/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-04 12:06:37
 * @LastEditTime: 2022-11-02 14:12:59
 * @FilePath: /preselectedweddingdress/src/hoc/yarnSelectionListStatusDispatch.tsx
 * @Description  : yarnSelectionListStatus
 */
import * as actions from '@actions/yarnSelectionListStatus'
import { yarnSelectionListStatusActionTypes } from '@constants/yarnSelectionListStatus'
import { State } from '@reducers/modules/yarnSelectionListStatus'
import { createReducerProcessingHoc } from '@zealforchange/redux-encapsulation'
import { ComponentType } from 'react'
import { Action, bindActionCreators, Dispatch } from 'redux'

export interface injectProps {
  YarnSelectionListStatus?: State
  withYarnSelectionListStatusDispatchHoc?: typeof actions
}

function mapStateToProps(state: { YarnSelectionListStatus: State }) {
  return {
    YarnSelectionListStatus: state.YarnSelectionListStatus,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action & yarnSelectionListStatusActionTypes>) {
  return {
    withYarnSelectionListStatusDispatchHoc: bindActionCreators(actions, dispatch),
  }
}

export function withYarnSelectionListStatusDispatchHoc<T extends injectProps>(C: ComponentType<T>) {
  return createReducerProcessingHoc('withYarnSelectionListStatus', C, mapStateToProps, mapDispatchToProps)
}
