/*
 * @Author: 邱狮杰
 * @Date: 2021-08-15 10:56:31
 * @LastEditTime: 2022-11-04 11:08:33
 * @Description:
 * @FilePath: /preselectedweddingdress/src/hoc/headerButtonDispatchHoc.tsx
 */
import * as actions from '@actions/headerButton'
import { HeaderAction } from '@constants/headerButton'
import { State } from '@reducers/modules/headerButton'
import { createReducerProcessingHoc } from '@zealforchange/redux-encapsulation'
import { ComponentType, ReactElement } from 'react'
import { Action, bindActionCreators, Dispatch } from 'redux'

export interface injectProps {
  component?: ReactElement
  withHeaderButtonDispatchActions?: typeof actions
}

function mapStateToProps(state: { HeaderButtonReducer: State }) {
  return {
    component: state.HeaderButtonReducer.component,
  }
}

function mapDispatchToProps(dispatch: Dispatch<Action & HeaderAction>) {
  return {
    withHeaderButtonDispatchActions: bindActionCreators(actions, dispatch),
  }
}

export const withHeaderButtonDispatch = <T extends injectProps>(C: ComponentType<T>) => {
  return createReducerProcessingHoc('withHeaderButtonDispatch', C, mapStateToProps, mapDispatchToProps)
}
