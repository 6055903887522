/*
 * @Author: 邱狮杰
 * @Date: 2022-10-25 16:51:58
 * @LastEditTime: 2022-10-25 16:55:53
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/weddingDress/inversify.config.ts
 */
import { BuildContainer } from '../../utils/buildContainer';
import { weddingDressCreate } from './create';
import { weddingDressDelete } from './delete';
import { weddingDressRead } from './read';
import { weddingDressUpdate } from './update';
import { weddingDress, weddingDressConstant } from './weddingDress';
const weddingDressContainer = new BuildContainer(weddingDressConstant).setCreate(weddingDressCreate).setDelete(weddingDressDelete).setRead(weddingDressRead).setUpdate(weddingDressUpdate).getBuildContainer();
weddingDressContainer.bind(weddingDressConstant.createweddingDress).to(weddingDress);
const getWeddingDress = weddingDressContainer.get(weddingDressConstant.createweddingDress);
export { getWeddingDress, weddingDressContainer };
