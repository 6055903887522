import { welcomeInitTypes } from '@type/api/module/welcome'

export interface CommonTypes {
  logo?: string
  localhost?: string
  localhostImg?: boolean
  welcomeInfo?: welcomeInitTypes
}

export const EDIT_LOGO = 'editLogo' // 修改logo
export const EDIT_LOCALHOST = 'editLocalhost' // 修改图片localhost
export const EDIT_LOCALHOSTIMG = 'editLocalhostImgStatus' // 修改本地图片标识
export const EDIT_WELCOMEINFO = 'editWelcomeInfo' // 修改welcome信息
