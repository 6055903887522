import { searchTypes } from '@page/preselectedYarnList/components/search/searchContent'
import { CreateReducer } from '@zealforchange/conciseredux'
import { SETTAGLIST, RESET_OPTION_DATE, SETSDATE, SETEDATE, SEARCH_OPTION, RESET_COUNT, searchAction, RESET_OPTION, DELETE_OPTION, SHIELD, REVERSE_SCHEDULE, SELF_INCREASING, DECREMENT } from '@constants/search'
import { Action } from 'redux'
import { List } from '@page/preselectedYarnList/components/search/components/styleList'

type DispatchTypes =
  | typeof SEARCH_OPTION
  | typeof RESET_COUNT
  | typeof RESET_OPTION
  | typeof DELETE_OPTION
  | typeof SHIELD
  | typeof REVERSE_SCHEDULE
  | typeof SELF_INCREASING
  | typeof DECREMENT
  | typeof SETSDATE
  | typeof SETEDATE
  | typeof RESET_OPTION_DATE
  | typeof SETTAGLIST

export type State = searchTypes & { count: number; tagList: List[] }
export const Search = new CreateReducer<State | {}, Action & searchAction, DispatchTypes>({
  count: 0,
  shield: 0,
})
  .addAction(SETTAGLIST, (state, action) => {
    return { ...state, tagList: action.list }
  })
  .addAction(RESET_OPTION_DATE, state => {
    return { ...state, sdate: '', edate: '' }
  })
  .addAction(SETEDATE, (state, action) => {
    return { ...state, edate: action.time }
  })
  .addAction(SETSDATE, (state, action) => {
    return { ...state, sdate: action.time }
  })
  .addAction(SEARCH_OPTION, (state, action) => {
    return { ...state, ...action.searchOption }
  })

  .addAction(RESET_COUNT, (state, action) => {
    return { ...state, count: action.count || 0 }
  })
  .addAction(RESET_OPTION, state => {
    const backupState = state as State
    return { ...state, shield: 0, edate: backupState.edate, sdate: backupState.sdate, gradeIds: [], tags: [] }
  })
  .addAction(DELETE_OPTION, (state, action) => {
    // @ts-ignore
    state[action.del] = ''
    const newState = state
    return { ...newState }
  })
  .addAction(SHIELD, (state, action) => {
    return { ...state, shield: action.shield || 0 }
  })
  .addAction(REVERSE_SCHEDULE, (state, action) => {
    return { ...state, shield: (state as State).shield === 0 ? 1 : 0 }
  })
  .addAction(SELF_INCREASING, state => {
    return { ...state, count: (state as State).count + 1 }
  })
  .addAction(DECREMENT, state => {
    return { ...state, count: (state as State).count - 1 }
  })
  .setReducerKey('Search')
