/*
 * @Author: 邱狮杰
 * @Date: 2022-11-03 11:14:28
 * @LastEditTime: 2022-11-07 11:25:32
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/reducers/modules/yarnTestingOperationButton.tsx
 */
import { CreateReducer, getAllValsWithActionCollectionHelper } from '@zealforchange/conciseredux'
import { } from './operationBtn'
import React from 'react'

export type yarnTestingOperationButtonAction = {
  INITCOMPONENT: 'initComponent',
  SWITCHSTATE: 'switchState'
}


export type yarnTestingOperationButtonActionTypes = getAllValsWithActionCollectionHelper<yarnTestingOperationButtonAction>


export type yarnTestingOperationButtonStateTypes = {
  curEle?: React.ReactElement

}

export type yarnTestingOperationButtonActionPayloadTypes = {
  curEleHandler?: React.ReactElement
}

export const yarnTestingOperationButton = new CreateReducer<yarnTestingOperationButtonStateTypes, yarnTestingOperationButtonActionPayloadTypes, yarnTestingOperationButtonActionTypes>({
  curEle: <></>
})
  .addAction('initComponent', (state, action) => {
    return { ...state, curEle: <></> }
  }).addAction('switchState', (state, action) => {
    return { ...state, curEle: action.curEleHandler ?? <></> }
  })
  .setReducerKey('yarnTestingOperationButton')

