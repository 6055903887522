/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:45:39
 * @LastEditTime: 2022-10-14 09:39:15
 * @Description:
 * @FilePath: /service/src/api/toStore/inversify.config.ts
 */
import { BuildContainer } from '../../utils/buildContainer';
import { ToStopCreate } from './create';
import { ToStopDelete } from './delete';
import { ToStopRead } from './read';
import { ToStop, ToStopConstant } from './toStore';
import { ToStopUpdate } from './update';
const ToStopContainer = new BuildContainer(ToStopConstant)
    .setCreate(ToStopCreate)
    .setDelete(ToStopDelete)
    .setRead(ToStopRead)
    .setUpdate(ToStopUpdate)
    .getBuildContainer();
ToStopContainer.bind(ToStopConstant.createToStop).to(ToStop);
const getToStop = ToStopContainer.get(ToStopConstant.createToStop);
export { getToStop, ToStopContainer };
