import { List } from '@page/preselectedYarnList/components/search/components/styleList'
import { searchTypes } from '@page/preselectedYarnList/components/search/searchContent'

export interface searchAction {
  count?: number
  searchOption?: searchTypes | {}
  del?: keyof searchTypes
  shield?: number // 是否屏蔽没有排期的款式 0不屏蔽，1屏蔽
  time?: string
  list?: List[]
}

export const SEARCH_OPTION = 'setOptions'
export const RESET_COUNT = 'resetCount'
export const RESET_OPTION = 'resetOption'
export const RESET_OPTION_DATE = 'resetOptionDate'
export const DELETE_OPTION = 'deleteOption'
export const SHIELD = 'setShield'
export const REVERSE_SCHEDULE = 'reverseSchedule'
export const SELF_INCREASING = 'selfIncreasing'
export const DECREMENT = 'decrement'
export const SETSDATE = 'setSdate'
export const SETEDATE = 'setEdate'
export const SETTAGLIST = 'setTagList'
