/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-30 10:41:00
 * @LastEditTime: 2022-11-07 17:17:45
 * @FilePath: /preselectedweddingdress/src/reducers/modules/headerGoods.ts
 * @Description  :
 */

import { CHOSE_GOODS, HeaderGoods, HIDE_GOODS, INIT_GOODS } from '@constants/headerGoods'
import { CreateReducer } from '@zealforchange/conciseredux'

export type goodsListItemTypes = {
  title: string
  active: boolean
  id: number // | string
}

export interface State {
  goodsList: goodsListItemTypes[]
  showGoodsList: boolean
}

export type DispatchTypes = typeof INIT_GOODS | typeof CHOSE_GOODS | typeof HIDE_GOODS

const HeaderGoodsReducer = new CreateReducer<State, HeaderGoods, DispatchTypes>({
  goodsList: [],
  showGoodsList: true
})
  .addAction(INIT_GOODS, (state, action) => {
    return { ...state, goodsList: action?.goodsList || [] }
  })
  .addAction(CHOSE_GOODS, (state, action) => {
    const actionIsString = typeof action.actionIndex === 'string'
    const newGoodsList = state.goodsList.filter((goodsItem, goodsIndex) => {
      if (actionIsString) {
        if (goodsItem.title === action.actionIndex) {
          goodsItem.active = true
          return goodsItem
        }
      } else if (goodsItem.id === action.actionIndex) {
        goodsItem.active = true
        return goodsItem
      }
      goodsItem.active = false
      return goodsItem
    })
    return { ...state, goodsList: newGoodsList }
  })
  .addAction(HIDE_GOODS, (state, action) => {
    return { ...state, showGoodsList: action.showGoods ?? state.showGoodsList }

  })
// function HeaderGoodsReducer(state: State = { goodsList: [] }, action: Action & HeaderGoods) {
//     switch (action.type) {
//         case INIT_GOODS:
//             return { ...state, goodsList: action?.goodsList || [] }
//         case CHOSE_GOODS:
//             const newGoodsList = state.goodsList.filter((goodsItem, goodsIndex) => {
//                 if (goodsIndex === action.actionIndex) {
//                     goodsItem.active = true
//                     return goodsItem
//                 }
//                 goodsItem.active = false
//                 return goodsItem
//             })
//             return { ...state, goodsList: newGoodsList }
//         default:
//             return state
//     }
// }
export { HeaderGoodsReducer }
