/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 11:29:10
 * @LastEditTime: 2022-11-02 16:51:17
 * @FilePath: /preselectedweddingdress/src/service/axios.ts
 * @Description  :
 */

import { AXIOS_DEFAULT_CONFIG } from "@config/index";
import {
    requestFailFunc, requestSuccessFunc, responseFailFunc, responseSuccessFunc
} from "@config/interceptors";
import axios from "axios";

const axiosInstance = axios.create(AXIOS_DEFAULT_CONFIG);

// 注入请求拦截
axiosInstance.interceptors.request.use(requestSuccessFunc, requestFailFunc);
// 注入失败拦截
axiosInstance.interceptors.response.use(responseSuccessFunc, responseFailFunc);

export default axiosInstance;
