/* eslint-disable @typescript-eslint/no-unused-vars */
import bus from '@utils/eventBus'
// import HeaderLogo from '@assets/img/welcome/welcomeLogo.png'
import HeaderLogo from '@assets/img/welcome/logoWhite.png'
// import HeaderLogo from '@assets/img/welcome/logoWithFont.png'
import Menu from './menuItemHooks'
import { CloseCircleOutlined, DownOutlined } from '@ant-design/icons'
import { Dropdown, message } from 'antd'
import { FC, useEffect, useRef, useState, useMemo } from 'react'
import { searchContext } from '@page/preselectedYarnList/components/search/searchContent'
import { Form } from '@components/index'
import { formatFormData } from './utils'
import { header } from '@event/index'
import { injectProps, withHeaderCustomerDispatch } from '@hoc/headerCustomerDispatch'
import { injectProps as commonInjectProps, withCommonDispatch } from '@hoc/commonDispatchHoc'
import { injectProps as withInitialCollectionInjectProps, withInitialCollection } from '@hoc/commonInit'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useSearchDispatch } from '@hooks/searchDispatch'
import { userListResponse } from '@type/api/module/common'
import { useSubmit } from './hooks/submit'
import { HeaderCustomer as HeaderCustomerStore } from '@reducers/modules/headerCustomer'
import dayjs from 'dayjs'
import './index.scss'
import { useYarnTest } from '@hooks/useYarnTest'
import {getNextDate} from '@utils/date'
/* eslint-disable react/jsx-pascal-case */
/*
 * @Author       : 邱狮杰
 * @Date         : 2021-07-29 14:29:13
 * @LastEditTime: 2021-08-15 22:31:40
 * @FilePath     : /preselectedyarn/src/layout/header/components/switchCustomers/switchCustomers.tsx
 * @Description  : 切换客户
 */

type Props = {} & injectProps & RouteComponentProps & commonInjectProps & withInitialCollectionInjectProps

const SwitchCustomers: FC<Props> = ({ HeaderCustomer, actions, history, commonState, userListInitialization }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [submit] = useSubmit()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectCustomer,setSelectCustomer] =useState<Object>({})
  const pathName = useRef(history.location.pathname)
  const setTimeoutHandler = useRef<any>()
  const [_, searchDispatch] = useSearchDispatch()
  const { dispatchWithYarnTest } = useYarnTest()
  useEffect(() => {
    if (history.location.pathname !== pathName.current) {
      setOpenModal(false)
      pathName.current = history.location.pathname
    }
    return () => { }
  }, [history.location.pathname])
  // useEffect(() => {
  //   if (!visible) return
  //   setTimeoutHandler.current = setTimeout(() => {
  //     setVisible(false)
  //   }, 2000)
  //   return () => {
  //     clearTimeout(setTimeoutHandler.current)
  //   }
  // }, [visible])
  async function switchVisible() {
    message.destroy()
    if (history.location.pathname === '/login') {
      message.warn('请先登录')
      return
    }
    if (!visible)
      await userListInitialization?.(response => {
        actions?.initCustomers(response.result)
      })
    setVisible(!visible)
  }
  function addClick() {
    switchVisible()
    bus.emit<{}>(header.NEW_TEMPLATE_POP_UP, {})
    setOpenModal(true)
  }
  function backfillScreeningTime(sdate: string | null, edate: string | null) {
    edate && searchDispatch.setEdate(edate)
    sdate && searchDispatch.setSdate(sdate)
    if (searchContext.hasField('sdate')) searchContext.addNewField('sdate', sdate)
    else searchContext.rewriteField('sdate', sdate)
    if (searchContext.hasField('edate')) searchContext.addNewField('edate', edate)
    else searchContext.rewriteField('edate', edate)
  }
  function menuClick(item: userListResponse) {
    if(!item.visitId){
      setSelectCustomer(item)
      setOpenModal(true)
      return
    }
    setSelectCustomer({})
    switchVisible()
    let caseSdate=item.caseDate? getNextDate(item.caseDate,-1):''
    let caseEdate=item.caseDate?getNextDate(item.caseDate,1):''

    // 在这action
    backfillScreeningTime(caseSdate, caseEdate)

    dispatchWithYarnTest.updateSchdule({

      schedule: {

        start:caseSdate ? new Date(caseSdate || '') : undefined,

        end: caseEdate ? new Date(caseEdate || '') : undefined,

      }

    })
    actions?.switchCustomers(item?.clientName || '')
    if (history.location.pathname === '/classification') {
      bus.emit<{}>(header.JUST_RENDER_CLASS_PAGE, {})
      return
    }
    bus.emit<header.switchClient>(header.SWITCH_CLIENT, { curCustomer: item })
  }
  function goHome() {
    history.push({
      pathname: 'welcome',
    })
  }
  function resetCustomer() {
    dispatchWithYarnTest.updateSchdule({
      schedule: {
        start: null,
        end: null
      }

    })
    actions?.resetCustomers()
    if (history.location.pathname === '/classification') {
      bus.emit<{}>(header.RESET_CLIENT_IN_CLASS, {})
      return
    }
    bus.emit<header.swithReset>(header.RESET_CLIENT, {})
  }

  // function getLogo() {
  //   if (history.location.pathname === '/login') return HeaderLogo
  //   return commonState?.logo || HeaderLogo
  // }

  const getLogo = useMemo(() => {
    if (history.location.pathname === '/login') return HeaderLogo
    return commonState?.logo || HeaderLogo
  }, [history.location.pathname, commonState])

  return (
    <div className='switchCustomers'>
      {/* <SuperImage src={getLogo()} onProxyClick={goHome} /> */}
      <img onClick={goHome} src={getLogo} alt='logo' />
      <Dropdown overlay={<Menu onClose={() => {
        setVisible(false)
      }} onProxyAddClick={addClick} itemList={HeaderCustomer?.userInfoList || []} onProxyClick={menuClick} />} visible={visible}>
        <span style={{ whiteSpace: 'nowrap' }} onClick={switchVisible.bind(this)}>
          <DownOutlined /> &nbsp;{HeaderCustomer?.curUser.clientName} {HeaderCustomer?.curUser.caseDate ? dayjs(HeaderCustomer?.curUser.caseDate).format('YYYY-MM-DD') : ''}
        </span>
      </Dropdown>
      <Form.default
        customerInfo={selectCustomer}
        open={openModal}
        onFinish={async (res, params, form) => {
          try {
            const payload = formatFormData(Object.assign({}, res, params))
            const result = await submit(payload)
            message.destroy()
            if (result.code === 200) {
              setOpenModal(() => {
                return false
              })
              await userListInitialization?.(response => {
                if (response?.result?.length) {
                  actions?.initCustomers(response.result)
                }
              })
              message.success(result.msg)
              form.resetFields()
              if (!payload.clientName) return
              actions?.switchCustomers(payload.clientName)
              const cur = HeaderCustomerStore.getCurState().curUser
              let caseSdate=cur.caseDate?getNextDate(cur.caseDate,-1):''
              let caseEdate=cur.caseDate?getNextDate(cur.caseDate,1):''
              backfillScreeningTime(caseSdate, caseEdate)
              dispatchWithYarnTest.updateSchdule({

                schedule: {
          
                  start:caseSdate ? new Date(caseSdate || '') : undefined,
          
                  end: caseEdate ? new Date(caseEdate || '') : undefined,
          
                }
          
              })
              if (history.location.pathname === '/classification') {
                bus.emit<{}>(header.JUST_RENDER_CLASS_PAGE, {})
                return
              } else bus.emit<header.switchClient>(header.SWITCH_CLIENT, { curCustomer: HeaderCustomer!.curUser })
              // actions?.switchCustomers()
            } else {
              message.warn(result.msg)
              return
            }
          } catch (e) {
            message.destroy()
            message.warn((e as unknown as Error).message)
          }
        }}
        onClose={() => {
          setOpenModal(false)
          setSelectCustomer({})
        }}
      />
      {HeaderCustomer?.curUser.caseId && <CloseCircleOutlined onClick={resetCustomer} />}
    </div>
  )
}

export default withInitialCollection(withCommonDispatch(withRouter(withHeaderCustomerDispatch(SwitchCustomers))))
