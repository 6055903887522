/*
 * @Author       : 邱狮杰
 * @Date         : 2021-08-04 11:25:38
 * @LastEditTime : 2021-08-05 14:45:13
 * @FilePath     : /preselectedyarn/src/constants/yarnSelectionListStatus.ts
 * @Description  :
 */
import { State, curStateTypes } from '@reducers/modules/yarnSelectionListStatus'

export interface yarnSelectionListStatusActionTypes {
  id?: number // id
  index?: number // 用于 喜欢 or 蒙层 or 删除 的状态
  curState?: curStateTypes
  initStyleList?: State['styleList'] // 初始化款式列表
}

export const UN_SELECTED = 'Unselected' // 未选状态 按钮显示已选文字

export const SELECTED = 'selected' // 已选状态 出现 取消和删除按钮

export const DELETETION = 'deletetion' // 删除款式

export const STYLE_LIST_INIT = 'StyleListInit' // 初始化款式列表

export const INITIALIZATION_TRIED = 'InitializationTried' // 初始化已试列表

export const LIKE_RESET = 'likeReset' // 重置喜欢  ===  喜欢状态取反

export const INVERSION_OF_MASK = 'inversionOfMask' // 重置蒙层 === 蒙层状态取反

export const MODIFY_CURRENT_STATUS = 'modifyCurrentStatus' // 修改当前状态

export const MARK_DELETETION = 'markDeletetion' // 标记删除

export const SELECT_IF_THE_ID_ISEQUAL = 'selectIfTheIdIsEqual' // id相等即选中

export const INIT_SELECTED_LIST = 'initSelectedList' // 已选列表
