import { defaultAction } from '@utils/action'
import { SETTAGLIST, RESET_OPTION_DATE, SETEDATE, SETSDATE, DELETE_OPTION, searchAction, RESET_COUNT, SEARCH_OPTION, RESET_OPTION, REVERSE_SCHEDULE, SELF_INCREASING, DECREMENT } from '@constants/search'
import { searchTypes } from '@page/preselectedYarnList/components/search/searchContent'
import { List } from '@page/preselectedYarnList/components/search/components/styleList'
export function setSdate(time: string) {
  return defaultAction<searchAction>(SETSDATE, { time })
}
export function setEdate(time: string) {
  return defaultAction<searchAction>(SETEDATE, { time })
}
export function setCount(count: number) {
  return defaultAction<searchAction>(RESET_COUNT, { count })
}
export function setTagList(tagList: List[]) {
  return defaultAction<searchAction>(SETTAGLIST, {
    list: tagList,
  })
}
export function setOption(option: searchTypes | {}) {
  return defaultAction<searchAction>(SEARCH_OPTION, {
    searchOption: option,
  })
}

export function reset() {
  return defaultAction<searchAction>(RESET_OPTION)
}
export function resetWithDate() {
  return defaultAction<searchAction>(RESET_OPTION_DATE)
}
export function deleteOptions(key: keyof searchTypes) {
  return defaultAction<searchAction>(DELETE_OPTION, { del: key })
}
export function reverseSchedule() {
  return defaultAction<searchAction>(REVERSE_SCHEDULE)
}
/**
 * @description 自增1
 */
export function selfIncreasing() {
  return defaultAction<searchAction>(SELF_INCREASING)
}
/**
 * @description 自减1
 */
export function decrement() {
  return defaultAction<searchAction>(DECREMENT)
}
