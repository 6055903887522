/*
 * @Author: 邱狮杰
 * @Date: 2021-11-17 12:51:16
 * @LastEditTime: 2022-11-12 16:51:47
 * @Description: 
 * @FilePath: /preselectedweddingdress/src/hooks/useTagFromStorage.ts
 */
import { localstoreUtils, StoreConfigNameCollect } from '@config/index'
import api from '@service/index'
import { requestCommonTypes } from '@type/api/common'
import { message } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SynchronizationAwaitError } from 'synchronizationawaiterror'

export async function getTitleHandler(phone: string, cb?: (res: requestCommonTypes<any>) => void): Promise<string[] | undefined> {
    console.log('getTitle')
    const [err, res] = await SynchronizationAwaitError<requestCommonTypes<any>, requestCommonTypes<any>>(api['common|getTitle']({ phone }))
    if (err) {
        message.destroy()
        message.warn('获取门店标题失败，请重试')
        return
    }
    if (res?.code !== 200) {
        message.destroy()
        message.warn(res?.msg)
        return
    }
    if (res.msg) {
        localstoreUtils.setItem(StoreConfigNameCollect.title, res.msg)
        document.title = res.msg
        cb?.(res)
        return [res.msg]
    }
    return
}

export function useTagFromStorage(): [string[], (phone?: string, setCb?: (dis: Dispatch<SetStateAction<string[]>>, str: string[]) => void) => void, (item: string) => void, () => string | null] {
    const [tagList, setTagList] = useState<string[]>([])
    useEffect(() => {
        setTagList(getList())
    }, [])

    function getList(): string[] {
        const list = localstoreUtils.getItem(StoreConfigNameCollect.tag)
        if (!list) return []
        return JSON.parse(list)
    }

    function setList(list: string[]) {
        localstoreUtils.setItem(StoreConfigNameCollect.tag, JSON.stringify(list))
        setTagList(list)
    }

    function setPhone(phone: string) {
        localstoreUtils.setItem(StoreConfigNameCollect.phone, phone)
    }

    function getPhoneFromStorage(): string | null {
        const phone = localstoreUtils.getItem(StoreConfigNameCollect.phone)
        return phone
    }

    async function getTagListSetToStorage(phone?: string, setCb?: (dis: Dispatch<SetStateAction<string[]>>, str: string[]) => void) {
        if (!phone) {
            const phone = getPhoneFromStorage()
            if (!phone) throw new Error('phone未必填项')
            const result = await getTitle(phone)
            result && setCb?.(setTagList, result)
        } else {
            setPhone(phone)
            const result = await getTitle(phone)
            result && setCb?.(setTagList, result)
        }
    }

    async function getTitle(phone: string): Promise<string[] | undefined> {
        return getTitleHandler(phone, (res) => {
            const saveTag = [res.msg]
            setList(saveTag)
        })
    }

    function deleteItem(item: string) {
        const list = getList()
        const newList = list.filter(check => {
            return check !== item
        })
        localstoreUtils.removeItem(StoreConfigNameCollect.phone)
        setList(newList)
    }

    return [tagList, getTagListSetToStorage, deleteItem, getPhoneFromStorage]
}
