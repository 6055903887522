var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 邱狮杰
 * @Date: 2022-10-25 16:51:44
 * @LastEditTime: 2022-11-25 20:06:15
 * @Description:
 * @FilePath: /service/src/api/weddingDress/read.ts
 */
import { provide } from 'inversify-binding-decorators';
import { http } from '../../config';
import { CancelableResponse } from '../../utils/cancelableResponse';
import { weddingDressConstant } from './weddingDress';
let weddingDressRead = class weddingDressRead {
    /**
     * @description 按分类获取所有婚纱
     * @use pre 易选纱
     */
    obtainAllWeddingDressesAccordingToClassification(req) {
        return new CancelableResponse().returnHandle({
            url: '/system/store/tablet/business/goods', method: 'post',
            data: Object.assign({}, req)
        });
    }
    /**
     * @description 获取根据分类id获取婚纱tag
     * @use pre
     * @returns
     */
    getTagAccordingToClassificationID(req) {
        return new CancelableResponse().returnHandle({
            url: "/system/store/bussiness/goods/tag/byCategoryId",
            params: Object.assign({}, req)
        });
    }
    weddingDressDetails(req) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield http({
                url: '/system/store/bussiness/goods/info',
                params: Object.assign({}, req),
            });
        });
    }
};
weddingDressRead = __decorate([
    provide(weddingDressConstant.createRead)
], weddingDressRead);
export { weddingDressRead };
