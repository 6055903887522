/*
 * @Author: 邱狮杰
 * @Date: 2022-09-25 23:45:39
 * @LastEditTime: 2022-09-30 11:06:05
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/makeUp/inversify.config.ts
 */
import { BuildContainer } from '../../utils/buildContainer';
import { MakeUpCreate } from './create';
import { MakeUpDelete } from './delete';
import { MakeUp, makeUpConstant } from './makeUp';
import { MakeUpRead } from './read';
import { MakeUpUpdate } from './update';
const makeUpContainer = new BuildContainer(makeUpConstant)
    .setCreate(MakeUpCreate)
    .setDelete(MakeUpDelete)
    .setRead(MakeUpRead)
    .setUpdate(MakeUpUpdate)
    .getBuildContainer();
makeUpContainer.bind(makeUpConstant.createMakeUp).to(MakeUp);
const getMakeup = makeUpContainer.get(makeUpConstant.createMakeUp);
export { getMakeup, makeUpContainer };
