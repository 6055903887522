import { FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
interface Props {}
const Loading: FC<Props> = () => {
  const antdLoadingIcon = <LoadingOutlined style={{ fontSize: '10vw' }} />
  return <Spin indicator={antdLoadingIcon} />
}

export default Loading
