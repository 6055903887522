/*
 * @Author: 邱狮杰
 * @Date: 2022-10-25 16:52:08
 * @LastEditTime: 2022-10-25 16:52:08
 * @Description:
 * @FilePath: /superbridal_repo/packages/service/src/api/weddingDress/weddingDress.ts
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject } from 'inversify';
import { provide } from 'inversify-binding-decorators';
import { CreateCrudVariable } from '../../utils/createCrudVariable';
export const weddingDressConstant = new CreateCrudVariable().setUpThemes('weddingDress').getTopic();
/**
 * 化妆 礼服师
 */
let weddingDress = class weddingDress {
    constructor(create, deletes, update, read) {
        this.create = create;
        this.update = update;
        this.delete = deletes;
        this.read = read;
    }
    getCreateTrigger() {
        return this.create;
    }
    getUpdateTrigger() {
        return this.update;
    }
    getDeleteTrigger() {
        return this.delete;
    }
    getReadTrigger() {
        return this.read;
    }
};
weddingDress = __decorate([
    provide(weddingDressConstant.createweddingDress),
    __param(0, inject(weddingDressConstant.createCreate)),
    __param(1, inject(weddingDressConstant.createDelete)),
    __param(2, inject(weddingDressConstant.createUpdate)),
    __param(3, inject(weddingDressConstant.createRead)),
    __metadata("design:paramtypes", [Object, Object, Object, Object])
], weddingDress);
export { weddingDress };
