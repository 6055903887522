/* eslint-disable react-hooks/exhaustive-deps */
import { message } from 'antd'
import { localstoreUtils, StoreConfigNameCollect } from '@config/index'
import { useEffect } from 'react'
import { getTitleHandler } from '@hooks/useTagFromStorage'

function parseSearchParams<T extends object = {}>(searchParamsString: string): T {
  return searchParamsString
    .replace(/.+\?/g, '')
    .split('&')
    .reduce((searchParams, curKV) => {
      const [k, v] = curKV.split('=').map(decodeURIComponent)
      // @ts-ignore
      searchParams[k] = v
      return searchParams
    }, {}) as T
}

// import { getQueryValue } from '@page/preselectedYarnList/utils'
interface URLParams {
  token: string
  phone: string
}

export function useVerificationToken(cb?: () => void, failCb?: () => void) {
  useEffect(() => {
    check(cb, failCb)
  }, [])
}

export async function check(cb?: () => void, failCb?: () => void) {
  message.destroy()
  const { token, phone } = parseSearchParams<URLParams>(window.location.href)
  console.log(token, phone, 'token, phone')
  if (phone) {
    await getTitleHandler(phone, undefined)
  }
  if (!token) {
    // 没有token的话
    if (localstoreUtils.getItem(StoreConfigNameCollect.token)) {
      // 缓存中有token
      await cb?.()
      return
    } else {
      // 缓存中没有token
      localstoreUtils.setItem(StoreConfigNameCollect.token, '')
      localstoreUtils.setItem(StoreConfigNameCollect.loginSuccess, JSON.stringify(false))
      await failCb?.()
    }
  } else {
    // 有token
    const checkToken = decodeURIComponent(token as string)
    localstoreUtils.setItem(StoreConfigNameCollect.token, checkToken)
    await cb?.()
  }
}
